<ng-container>
    <section class="col-md-12 col-sm-12 dm-tile--no-pad">
        <section class="col-md-4 col-sm-4 section__wrapper dm-p-l-imp-0">
            <section class="input__label">
                <label class="input__label--text font-caption-alt" for="dm-invoice-projects-ddl"
                    [ngClass]="{'disabled-text': isProjectContext}">
                    Project
                </label>
                <section class="input__select--icon dm-tile--no-pad">
                    <select id="dm-invoice-projects-ddl" class="input__select input__select--big font-caption-alt"
                        [ngClass]="{'disabled-class': isProjectContext}" [(ngModel)]="selectedProjectId"
                        (change)="onProjectChange()" [disabled]="isProjectContext">
                        <option value>All my projects</option>
                        <option *ngFor="let project of projects" [value]="project.projectId">
                            {{project.projectName}}
                        </option>
                    </select>
                    <span class="icon icon-chevron-down pull-right" aria-hidden="true"></span>
                </section>
            </section>
        </section>
        <section class="col-md-4 col-sm-4 section__wrapper">
            <section class="input__label">
                <label class="input__label--text font-caption-alt" for="dm-invoice-services-ddl">
                    Status
                </label>
                <section class="input__select--icon dm-tile--no-pad">
                    <select id="dm-invoice-services-ddl" class="input__select input__select--big font-caption-alt"
                        (change)="onStatusChange()" [(ngModel)]="filteredStatus">
                        <option value="">All</option>
                        <option *ngFor="let status of statusList" [value]="status">
                            {{status}}
                        </option>
                    </select>
                    <span class="icon icon-chevron-down pull-right" aria-hidden="true"></span>
                </section>
            </section>
        </section>
        <section class="col-md-4 col-sm-4 section__wrapper no-pad-right">
            <label *ngIf="deviceFactory.isMobile()" class="input__label--text font-caption-alt" for="invoiceSearch">
                Search Invoice
                <span aria-hidden="true">#</span>
            </label>
            <label *ngIf="deviceFactory.isDesktop()" class="input__label--text font-caption-alt" for="invoiceSearch">
                Search Invoice
                <span aria-hidden="true">#</span>
            </label>
            <label *ngIf="deviceFactory.isTablet()" class="input__label--text font-caption-alt dm-tile--p-t-6"
                for="invoiceSearch">
                Search Invoice Number
            </label>
            <section class="input__invoicesearch dm-tile--no-pad">
                <div class="input-group">
                    <select id="dm-invoice-ddl" class="searchBox font-caption-alt input__text"
                    (change)="onInvoiceNumberChange()" [(ngModel)]="invoiceSearch">
                    <option value="" hidden selected>Type your invoice number</option>
                    <option *ngFor="let invoice of invoiceList" [value]="invoice.invoiceNumber"
                        [attr.aria-label]="invoice.invoiceNumber" role="listbox" aria-live="polite" aria-relevant="all"
                        aria-atomic="true">
                        {{invoice.invoiceNumber}}
                    </option>                                 
                </select> 
                <button *ngIf="invoiceSearch && invoiceSearch.length > 0" class="button-container icon-wrapper-close"
                    id="invoices-clear" type="button" (click)="clearInvoiceSearch();" aria-label="clear search"
                    [ngClass]="{'right0':invoiceSearch.length}">
                    <span class="icon icon-close"></span>
                </button>    
                </div>
            </section>
        </section>
    </section>
    <section class="col-sm-12 col-xs-12 dm-filters pad-t-24" *ngIf="deviceFactory.isMobile()" filters-scroll
        fab-filter="#dminvoices_filter" fab-button="#invoices-fabButton">
        <section class="tile-header dm-filters--pad0-mobile">
            <section class="dm-filters--w--83 dm-filters--pad0-mobile pull-left">
                <div class="current-view font-label">Currently viewing</div>
                <div class="ddl-text dm-filters--m-b-12 font-label">
                    <p>
                        <span>{{selectedProjectId? selectedProjectId : 'All my projects,'}}</span>
                    </p>
                    <p>
                        <span>{{selectedStatus? selectedStatus : 'All Statuses,'}}</span>
                    </p>
                    <p>
                        <span>{{selectedSearch? selectedSearch : 'All Invoice Numbers'}}</span>
                    </p>
                </div>
            </section>
            <section class="dm-filters--w-83 pull-right">
                <div>
                    <!-- (click)="selectFilter(selectedProject, selectedStatus, selectedSearch, invoiceItems, navigationType)" -->
                    <button class="edit-filter font-label" id="dminvoices_filter">
                        <span class="icon icon-filter"></span>
                        Edit Filters
                    </button>
                </div>
            </section>
        </section>
        <!-- (click)="selectFilter(selectedProject, selectedStatus, selectedSearch, invoiceItems, navigationType)" -->
        <button id="invoices-fabButton" class="fabButton" aria-label="edit filter">
            <div class="innerdiv">
                <span class="icon icon-filter"></span>
            </div>
        </button>
    </section>
</ng-container>