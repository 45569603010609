<dm-modal-v2 [modalHeaderContent]="modalContent" [modalMinHeight]="'320px'"
    [isLoading]="isLoadingInternalEngagements" [showFooter]="false">
    <dm-loading loader [loadingText]="'Associated Internal Engagements'" [isCustomText]="true" [showLoading]="true"
        *ngIf="isLoadingInternalEngagements"></dm-loading>
    <div *ngIf="!isLoadingInternalEngagements && associatedInternalEngagements?.length > 0">
        <caption class="sr-only">Associated Internal Engagements</caption>
        <div role="rowgroup" class="dm-grid-view-table">
            <div role="row" class="dm-grid-view-table__row">
                <div role="columnheader"
                    class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 text-left dm-p-x-12-y-8">
                    Name
                </div>
                <div role="columnheader"
                    class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 text-left dm-p-x-12-y-8">
                    Actual Cost & <br /> Hours
                </div>
                <div role="columnheader"
                    class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 text-left dm-p-x-12-y-8">
                    Planned Cost & <br /> Hours
                </div>
                <div role="columnheader"
                    class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 text-left dm-p-x-12-y-8">
                    Start Date
                </div>
                <div role="columnheader"
                    class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 text-left dm-p-x-12-y-8">
                    End Date
                </div>
                <div role="columnheader"
                    class="dm-grid-view-table__headerCell dm-grid-view-table__headerCell-v2 text-left dm-p-x-12-y-8">
                    EBS State
                    <dm-tooltip role="tooltip" class="dm-tooltipinline dm-m-r-4" [dmPlacement]="'bottom-right'"
                        [ngbTooltipContent]="wbsTooltipText" [dmIcon]="'tile__icon--info icon icon-info'"
                        ariaLabelToolTip="EBS State Information">
                    </dm-tooltip>
                </div>
            </div>
        </div>
        <div role="rowgroup">
            <div class="dm-grid-view-table">
                <div class="dm-grid-view-table__body">
                    <div role="row" class="dm-grid-view-table__row"
                        *ngFor="let internalEngagement of associatedInternalEngagements">
                        <div role="gridcell"
                            class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-x-12-y-8">
                            <div class="display-grid name-section">
                                <a *ngIf="internalEngagement.engagementName" class="dm-m-r-4 name"
                                    [uiSref]="RouteName.InternalEngagementSummary"
                                    [uiParams]="{engagementId: internalEngagement.engagementId}"
                                    (click)="logEngagementClick(); closeModal()"
                                    id="{{ 'dm-grid-internal-associated-engagement-' + internalEngagement.engagementId }}">
                                    Internal:
                                    {{internalEngagement.engagementName | dmDisplayDashIfEmptyOrNull}}
                                </a>
                                <p>
                                    Type: {{internalEngagement.engagementType | dmDisplayDashIfEmptyOrNull}}
                                </p>
                                <p class="font-caption-alt dm-project-data__title--tooltip">
                                    EBS: {{internalEngagement.engagementId}}
                                </p>
                                <p>
                                    Project Manager / T&E Approver:
                                    <dm-user-name *ngIf="internalEngagement.engagementPjm"
                                        [name]="internalEngagement.engagementPjm"
                                        [alias]="internalEngagement.engagementPjmAlias" [role]="'Project Manager'"
                                        [showPersonCard]="true" (onNameClick)="logPJMClick()">
                                    </dm-user-name>
                                </p>
                            </div>
                        </div>
                        <div role="gridcell"
                            class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-x-12-y-8">
                            <div class="text-left">
                                {{internalEngagement.actualHours ? (internalEngagement.actualHours | number: '1.0-2' ) : '-'}}
                                Hours
                            </div>
                            <div class="text-left">
                                {{internalEngagement.actualCost | dmDisplayCurrencyOrDash}} {{currency}}
                            </div>
                        </div>
                        <div role="gridcell"
                            class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-x-12-y-8">
                            <div class="text-left">
                                {{internalEngagement.plannedHours ? (internalEngagement.plannedHours | number: '1.0-2' ) : '-'}}
                                Hours
                            </div>
                            <div class="text-left">
                                {{internalEngagement.plannedCost | dmDisplayCurrencyOrDash}} {{currency}}
                            </div>
                        </div>
                        <div role="gridcell"
                            class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-x-12-y-8">
                            {{internalEngagement.engagementStartDate | dmDisplayDateOrDashPipe}}
                            <span *ngIf="!internalEngagement.engagementStartDate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell"
                            class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-x-12-y-8">
                            {{internalEngagement.engagementEndDate | dmDisplayDateOrDashPipe}}
                            <span *ngIf="!internalEngagement.engagementEndDate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell"
                            class="dm-grid-view-table__bodyCell dm-grid-view-table__bodyCell-v2 dm-p-x-12-y-8">
                            {{internalEngagement.engagementEbsState | dmDisplayDashIfEmptyOrNull}}
                            <span *ngIf="!internalEngagement.engagementEbsState" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section class="col-md-12 col-sm-12 col-xs-12" *ngIf="!isLoadingInternalEngagements && (!associatedInternalEngagements || associatedInternalEngagements?.length === 0)">
        <dm-no-data [noDataText]="noAssociatedEngagements" [showImage]="true"></dm-no-data>
    </section>
</dm-modal-v2>