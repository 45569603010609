<dm-modal-v2 [modalHeaderContent]="modalContent" [modalMinHeight]="'430px'" [hasNonOverflowContent]="true"
    [isLoading]="isUpdatingTeamStructure">
    <dm-loading loader [loadingText]="loadingText" [isCustomText]="true" [showLoading]="true"
        *ngIf="isUpdatingTeamStructure"></dm-loading>
    <div *ngIf="!isUpdatingTeamStructure" class="edit-team-structure-form">
        <!-- Form section -->
        <form [formGroup]="editTeamStructureForm">
            <div class="dm-entity__name">{{selectedEngagement?.name}}</div>
            <div class="dm-entity__id dm-p-b-10">{{selectedEngagement?.id}}</div>
            <div class="col-sm-10 col-lg-10 dm-p-l-imp-0 dm-p-b-10">
                <div class="dm-form-entity">
                    <label class="dm-form-entity__label" for="primaryPpjm">
                        Primary Project Manager
                    </label>
                    <dm-type-ahead [typeAheadId]="typeAheadId" [btnSearchAriaLabelText]="taSearchAriaLblTxt"
                        [btnCancelAriaLabelText]="taCancelAriaLblTxt" formControlName="ppjm" [typeAheadLabelText]="'Primary Project Manager'"
                        [typeAheadUserValidationRequiredMessage]="taRequiredMsg" [disabled]="disablePPJMUpdate"
                        [selectedUser]="selectedUser" [isRequired]="true" [isMultiselect]="false" [fteOnly]="true"
                        [modelValue]="selectedUser" [isMandatory]="true" [isTypeAheadV2]="true" [showTypeAheadLabel]="false"
                        (selectedUserUpdated)="selectedUserUpdated($event)" (onUserCleared)="onUserCleared($event)">
                    </dm-type-ahead>
                    <span role="alert" class="font-caption-alt inp-validation dm-p-b-5" aria-atomic="true"
                        aria-live="polite" *ngIf="showNonfteValidationMessage">
                        {{editEngagementErrorMessages?.OnlyFteCanFill}}
                    </span>
                </div>
            </div>
            <div class="dm-p-b-10 cascade-grid">
                <div>
                    Cascade primary project manager to projects
                </div>
                <ng-multiselect-dropdown tabindex="0" [placeholder]="'Select projects'" [data]="filterDataSource"
                    formControlName="pjmProjects" class="font-caption-alt" id="pjm-projects-multiselect-dropdown"
                    name="pjm-projects-multiselect-dropdown" [settings]="dropdownSettings"
                    (onDeSelect)="onPjmProjectDeSelect($event)" (onSelectAll)="onPjmProjectsSelectAll($event)"
                    (onDeSelectAll)="onPjmProjectsDeSelectAll()">
                </ng-multiselect-dropdown>
            </div>
            <div class="col-sm-10 col-lg-10 dm-p-l-imp-0 dm-p-b-10">
                <div class="dm-form-entity">
                    <label class="dm-form-entity__label" for="additionalPpjm">
                        Additional Project Managers
                    </label>
                    <dm-type-ahead (multiSelectedUsersUpdated)="onAdditionalManagersUpdate($event)"
                        (multiSelectedRemovedUser)="onAdditionalManagerRemoved($event)" [typeAheadLabelText]="'Additional Project Managers'"
                        (selectedUserUpdated)="onAdditionalManagerAdded($event)" [typeAheadId]="additionalTypeAheadId"
                        [btnSearchAriaLabelText]="additionalSearchAriaLblTxt" [isMandatory]="false" [showTypeAheadLabel]="false"
                        formControlName="adPPjm" [btnCancelAriaLabelText]="additionalCancelAriaLblTxt"
                        [disabled]="disableAPPJMUpdate" [selectedUsers]="selectedUsers" [isRequired]="false"
                        [isMultiselect]="true" [fteOnly]="false" [isTypeAheadV2]="true" [multiSelectedUsersWidth]="'600px'">
                    </dm-type-ahead>
                </div>
            </div>
            <div class="cascade-grid">
                <div>
                    Cascade additional primary project manager to selected projects
                </div>
                <ng-multiselect-dropdown tabindex="0" [placeholder]="'Select projects'" [data]="filterDataSource"
                    formControlName="adpjmProjects" class="font-caption-alt adPPjmProjectsMultiSelect"
                    id="adpjm-projects-multiselect-dropdown" name="adpjm-projects-multiselect-dropdown"
                    [settings]="dropdownSettings" (onDeSelect)="onAdpjmProjectDeSelect($event)"
                    (onSelectAll)="onAdPjmProjectsSelectAll($event)" (onDeSelectAll)="onAdPjmProjectsDeSelectAll()">
                </ng-multiselect-dropdown>
            </div>
            <div *ngIf="showUnitSubmittersUpdation && isComplexUnitsFeatureEnabled">
                <div class="col-sm-10 col-lg-10 dm-p-l-imp-0 dm-p-b-10">
                    <div class="dm-form-entity">
                        <label class="dm-form-entity__label" for="unitSubmitters">
                            Unit submitters
                        </label>
                        <dm-type-ahead (multiSelectedUsersUpdated)="onUnitSubmittersUpdate($event)"
                            (multiSelectedRemovedUser)="onUnitSubmitterRemoved($event)" [typeAheadLabelText]="'Unit Submitters'"
                            (selectedUserUpdated)="onUnitSubmitterAdded($event)" [typeAheadId]="unitSubmittersTypeAhead"
                            [btnSearchAriaLabelText]="unitSubmitterSearchAriaLblTxt" [isMandatory]="false"
                            [showTypeAheadLabel]="false" formControlName="uSubmitters"
                            [btnCancelAriaLabelText]="unitSubmitterCancelAriaLblTxt" [disabled]="false"
                            [selectedUsers]="selectedUnitSubmitters" [isRequired]="false" [isMultiselect]="true" [fteOnly]="false"
                            [isTypeAheadV2]="true" [multiSelectedUsersWidth]="'600px'">
                        </dm-type-ahead>
                    </div>
                </div>
                <div class="cascade-grid">
                    <div>
                        Add unit submitters to selected projects
                    </div>
                    <ng-multiselect-dropdown tabindex="0" [placeholder]="'Select unit projects'" [data]="unitBasedProjects"
                        formControlName="unitProjects" class="font-caption-alt adPPjmProjectsMultiSelect"
                        id="upjm-projects-multiselect-dropdown" name="upjm-projects-multiselect-dropdown"
                        [settings]="dropdownSettings" (onDeSelect)="onUnitProjectDeSelect($event)"
                        (onSelectAll)="onUnitProjectsSelectAll($event)" (onDeSelectAll)="onUnitProjectsDeSelectAll()">
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </form>
    </div>
    <div infoBar
        *ngIf="!isUpdatingTeamStructure && (!PPJMPristine || ppjmDeleted || !APPJMPristine || (pjmProjects && pjmProjects.value && pjmProjects.value.length && !isProjectManagerChanged) || (adpjmProjects && adpjmProjects.value && adpjmProjects.value.length && !isAdditonalProjectManagerChanged) || (addedUnitSubmitters && addedUnitSubmitters.length))">
        <div class="dm-modal-v2-change-information-section" *ngIf="!PPJMPristine || ppjmDeleted" role="alert" aria-live="polite" aria-atomic="true">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>Primary project manager has been changed from '{{selectedEngagement.pPjm.name}}'</span>
        </div>
        <div class="dm-modal-v2-change-information-section" role="alert" aria-live="polite" aria-atomic="true"
            *ngIf="pjmProjects && pjmProjects.value && pjmProjects.value.length && !isProjectManagerChanged">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>Selected Projects already have {{selectedEngagement.pPjm.name}} as project manager.</span>
        </div>
        <div class="dm-modal-v2-change-information-section" role="alert" aria-live="polite" aria-atomic="true"
            *ngIf="!APPJMPristine && deletedManagers && deletedManagers.length">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>
                <span *ngFor="let deletedManager of deletedManagers; last as isLast">
                    {{deletedManager?.name}}
                    <span *ngIf="!isLast">, </span>
                </span> are removed from Additional Project Managers.
            </span>
        </div>
        <div class="dm-modal-v2-change-information-section" role="alert" aria-live="polite" aria-atomic="true"
            *ngIf="!APPJMPristine && addedManagers && addedManagers.length">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>
                <span *ngFor="let addedManager of addedManagers; last as isLast">
                    {{addedManager?.name}}
                    <span *ngIf="!isLast">, </span>
                </span>are added as Additional Project Managers.
            </span>
        </div>
        <div class="dm-modal-v2-change-information-section" role="alert" aria-live="polite" aria-atomic="true"
            *ngIf="addedUnitSubmitters && addedUnitSubmitters.length && unitProjects.value && unitProjects.value.length">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>
                <span *ngFor="let addedManager of addedUnitSubmitters; last as isLast">
                    {{addedManager?.name}}
                    <span *ngIf="!isLast">, </span>
                </span>are added as Unit Submitters.
            </span>
        </div>
        <div class="dm-modal-v2-change-information-section" role="alert" aria-live="polite" aria-atomic="true"
            *ngIf="adpjmProjects && adpjmProjects.value && adpjmProjects.value.length && !isAdditonalProjectManagerChanged">
            <span class="icon icon-info dm-p-r-5"></span>
            <span>Selected Projects already have same set of managers as additional project managers.</span>
        </div>
    </div>
    <div additionalFooterButtons>
        <button type="button" id="submit" class="fxp-btn fxp-btn--primary dm-btn" *ngIf="!isUpdatingTeamStructure"
            [disabled]="saveButtonDisabled(!editTeamStructureForm.valid)" (click)="updateTeamDetails()">
            Submit
        </button>
    </div>
</dm-modal-v2>