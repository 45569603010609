<dm-modal-v2 [modalHeaderContent]="modalContent" [modalMinHeight]="'430px'" [hasNonOverflowContent]="true"
  [showFooter]="false">
  <div>
    <label class="sr-only">ECIF IO</label>
    <div>      
      <div class="row wbsDetail">        
        <section class="checkbox__section">
          <fieldset>
            <div class="controls__section">
              <input type="text" class="seach_box" placeholder="Filter IO/Project" (input)="onSearch($event.target.value)" />
              <div class="input__checkbox">
                <input type="checkbox" [checked]="isFilterCheckEnable" (change)="onFilterChange()"
                  class="input__checkbox--focus set-focus" value="None" id="viewIO" name="check"
                  aria-label="Only show IO expiring in the next 30 days" />
                <label for="viewIO"></label>
              </div>
              <label for="viewIO" class="font-caption-alt input__checkbox--text">
                Only show IO expiring in the next 30 days
              </label>
            </div>
          </fieldset>
        </section>
      </div>

      <span class="sr-only" id="ecifIOConsumedTable">ECIF IO Details</span>
      <div role="grid" aria-labelledby="ecifIOConsumedTable" class="io-details-grid">
        <div role="rowgroup" class="io-details-table">
          <div role="row" class="io-details-table__row">
            <div role="gridcell" class="io-details-table__headerCell text-align--left">
              IO Number
            </div>
            <div role="gridcell" class="io-details-table__headerCell text-align--right">
              <dm-tooltip role="tooltip" [dmPlacement]="'bottom-right'" id="dm-tooltip-totalAmount"
                [ngbTooltipContent]="'This data reflects the total IO amount. \n The same IO can be used across more than one Engagement or Project at one time.'"
                [dmIcon]="'tile__icon--info icon icon-info'" ariaLabelToolTip="ECIF total amount information">
                <span class="sr-only">press tab to navigate to tooltip button</span>
              </dm-tooltip>
              Total Amount
              <br />
              ({{currency}})
            </div>
            <div role="gridcell" class="io-details-table__headerCell text-align--right">
              <dm-tooltip role="tooltip" [dmPlacement]="'bottom-right'" id="dm-tooltip-totalConsumedAmount"
                [ngbTooltipContent]="'This data reflects the total consumed amount of the IO. \n The same IO can be used across more than one Engagement or Project at one time.'"
                [dmIcon]="'tile__icon--info icon icon-info'" ariaLabelToolTip="ECIF total consumed amount information">
                <span class="sr-only">press tab to navigate to tooltip button</span>
              </dm-tooltip>
              Total Consumed Amount
              <br />
              ({{currency}})
            </div>
            <div role="gridcell" class="io-details-table__headerCell text-align--right">
              IO End Date
            </div>
            <div role="gridcell" class="io-details-table__headerCell text-align--right">
              IO Expiration Date
            </div>
            <div role="gridcell" class="io-details-table__headerCell text-align--right">
              <dm-tooltip role="tooltip" [dmPlacement]="'bottom-right'" id="dm-tooltip-totalConsumedAmount"
                [ngbTooltipContent]="'This action will only notify the ADE to take action in \n submitting an IO extension request.'"
                [dmIcon]="'tile__icon--info icon icon-info'" ariaLabelToolTip="ECIF total consumed amount information">
                <span class="sr-only">press tab to navigate to tooltip button</span>
              </dm-tooltip>
              Action
            </div>
          </div>
          <div class="io-details-table__dataGrid" style="height: calc(100vh - 580px);">
            <div *ngFor="let entity of filteredIoList">
              <div role="row" class="io-details-table__row io-details-table__projectDetailsRow">
                <div role="gridcell">
                  <span class="dm-p-r-15 pointer">
                    <button id="{{'projectCollapse' + entity.ioNumber}}" type="button"
                      title="Collapse IO Details Section" aria-label="IO Details Section Expanded"
                      class="split-btn" (click)="toggleEcifIo(entity)" *ngIf="entity?.isExpanded">
                      <span class="icon icon-chevron-up"></span>
                    </button>
                    <button id="{{'projectExpand' + entity.ioNumber}}" type="button"
                      title="Expand Project Details Section" aria-label="Project Details Section Collapsed"
                      class="split-btn" (click)="toggleEcifIo(entity)" *ngIf="!entity?.isExpanded">
                      <span class="icon icon-chevron-down"></span>
                    </button>
                  </span>
                  IO: <span>{{entity?.ioNumber}}</span>
                </div>
              </div>
              <div *ngIf="entity?.isExpanded">
                <div class="io-details-table__body">
                  <ng-container *ngFor="let projDetail of entity?.projectDetails">
                    <div scope="row" role="row" class="io-details-table__row">
                      <div role="gridcell" class="io-details-table__bodyCell">
                        <span class="sr-only">Project</span>
                        {{projDetail.projectId}}
                      </div>
                      <div role="gridcell" class="io-details-table__bodyCell text-align--right">
                        <span class="sr-only">Total Amount</span>
                        {{projDetail.total}}
                      </div>
                      <div role="gridcell" class="io-details-table__bodyCell text-align--right">
                        <span class="sr-only">Consumed Amount</span>
                        {{projDetail.consumed}}
                      </div>
                      <div role="gridcell" class="io-details-table__bodyCell text-align--right">
                        <span class="sr-only">IO End Date</span>
                        {{projDetail.endDate | dmDisplayDateOrDashPipe}}
                      </div>
                      <div role="gridcell" class="io-details-table__bodyCell text-align--right"
                        [ngClass]="{'text-danger':isNearExpiry(projDetail.daysBeforeExpiry) && !isExpired(projDetail.daysBeforeExpiry), 'text-safe': !isNearExpiry(projDetail.daysBeforeExpiry)}">
                        <span class="sr-only">IO Expiration Status</span>
                        {{getAbsoluteDays(projDetail.daysBeforeExpiry)}} days {{isExpired(projDetail.daysBeforeExpiry)?
                        'past'
                        : 'to'}} expiration
                      </div>
                      <div role="gridcell" class="io-details-table__bodyCell text-align--center">
                        <span class="sr-only">Action</span>
                        <button type="button" id="submitExtension" class="extendbtn"
                          (click)="openIoExtensionModal(entity.ioNumber, projDetail.projectId)">
                          Notify
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</dm-modal-v2>