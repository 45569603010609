<dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="tileMinHeight" [tileHeight]="'100%'" [isV2Tile]="true"
    [hasNonOverflowContent]="true">
    <dm-loading loader *ngIf="isComponentLoading && !isServerError" [loadingText]="loadingText"
        [showLoading]="showLoading">
    </dm-loading>
    <ng-container *ngIf="!isComponentLoading && !isServerError">
        <div>
            <div>
                <div class="dm-entity__name dm-m-b-5"
                    [title]="isProjectContext ? selectedProjectData?.name : engagementData?.name">
                    <div class="dm-flex-align-center">
                        <span class="dm-entity__name-value" id="entityName">{{isProjectContext ?
                            selectedProjectData?.name :
                            engagementData?.name}}</span>
                        <button class="dm-transparent-btn dm-p-3 dm-entity__btn-copy-to-clipboard" tabindex="0"
                            (click)="copyEntityName()" (keyup.enter)="copyEntityName()" title="Copy to clipboard">
                            <i class="icon icon-full icon-copy cursor-pointer"></i>
                        </button>
                    </div>
                </div>
                <div class="dm-entity__id dm-flex-align-center dm-m-b-imp-4">
                    <span class="dm-m-r-12">{{isProjectContext ? selectedProjectData.projectId :
                        engagementData.id}}</span>
                    <span class="dm-m-r-12">|</span>
                    <span class="dm-m-r-12 dm-tile--pjm_ellipses" *ngIf="isProjectContext"
                        [title]="isProjectContext ? selectedProjectData.domain : engagementData.primaryDomain">{{isProjectContext
                        ? selectedProjectData?.domain :
                        engagementData?.primaryDomain}}</span>
                        <span class="dm-m-r-12 dm-tile--pjm_ellipses"
                        [title]=""></span>
                    <span class="icon-full icon-globe dm-m-r-12" title="Global Deal" *ngIf="isGlobalDeal"></span>
                    <span class="dm-badge dm-badge-v2 dm-contract-type display-inline-block-imp dm-m-r-12"
                        *ngIf="!isProjectContext"
                        [ngStyle]="engagementDetails.typeColorCode">{{engagementDetails?.typeOfContract}}</span>
                    <span *ngIf="isProjectContext"
                        class="dm-badge dm-badge-v2 dm-contract-type display-inline-block-imp dm-m-r-12"
                        [ngStyle]="selectedProjectData.typeColorCode">{{selectedProjectData?.contractType}}</span>
                    <div class="icon-holder">
                        <dm-tooltip role="tooltip" class="dm-tooltipinline dm-p-r-10 confidentialicon"
                            *ngIf="isProjectContext ? selectedProjectData?.isConfidentialDeal : engagementDetails?.isConfidentialDeal"
                            [dmPlacement]="'right'"
                            [ngbTooltipContent]="isProjectContext ? 'This project is marked as confidential.' : 'This engagement is marked as confidential.'"
                            [dmIcon]="'tile__icon--info confidentialkey'"
                            ariaLabelToolTip="confidentiality information">
                        </dm-tooltip>
                        <dm-tooltip role="tooltip" class="dm-tooltipinline pubsec dm-p-r-10"
                            *ngIf="isProjectContext ? isPubSecEnabled && !selectedProjectData?.isUsPubSec && selectedProjectData?.isPublicSector : isPubSecEnabled && !engagementDetails?.isUsPubSec && engagementDetails?.isPublicSector"
                            [dmPlacement]="'right'" [ngbTooltipContent]="'Pub Sec'" [dmIcon]="'pubsec_icon'"
                            ariaLabelToolTip="pub sec Information">
                        </dm-tooltip>
                        <span
                            *ngIf="isProjectContext ? selectedProjectData?.isUsPubSec : engagementDetails?.isUsPubSec"
                            class="uspubsec" title="US Pubsec"></span>
                        <span
                            *ngIf="isProjectContext ? selectedProjectData?.hasUnitBasedDemands : engagementDetails?.hasUnitBasedDemands"
                            class="units-icon dm-m-r-4 dm-m-t-4" title="Units"></span>
                        <span *ngIf="!isProjectContext && showRatableIcon && areMXDRChangesEnabled"
                            class="ratable-icon dm-m-r-4 dm-m-t-4" title="Ratable"></span>
                        <span *ngIf="isProjectContext && selectedProjectData?.isMarkedForDeletion"
                            class="icon-full icon-page-remove icon-marked-for-deletion dm-m-r-4"
                            title="This Project has been marked for deletion in CompassOne"></span>
                        <button class="dm-transparent-btn dm-p-3" (click)="openAssociatedInternalEngagementsModal()"
                            title="View Associated Internal Engagements" (keyup.enter)="openAssociatedInternalEngagementsModal()"
                            *ngIf="!isProjectContext && associatedEngagementList?.length">
                            <i class="icon icon-associate cursor-pointer"></i>
                        </button>
                    </div>
                </div>
                <div class="dm-entity__description dm-entity__name-value"
                    [title]="isProjectContext ? selectedProjectData?.description : engagementData?.description">
                    {{isProjectContext ? selectedProjectData.description :
                    engagementData.description}}
                </div>
                <div class="dm-p-y-imp-15">
                    <dm-summary-progress-bar [progressBarData]="engagementData?.ebsStateProgress"
                        *ngIf="!isProjectContext" [wrapContent]="true">
                    </dm-summary-progress-bar>
                    <dm-summary-progress-bar [progressBarData]="selectedProjectData?.ebsStateProgress"
                        *ngIf="isProjectContext" [wrapContent]="true">
                    </dm-summary-progress-bar>
                </div>
                <div class="display-flex-imp dm-m-b-imp-4 customer-details">
                    <div class="dm-p-y-imp-10 dm-detail">
                        <span class="dm-detail__label">Customer</span>
                        <span class="dm-detail__value">{{isProjectContext ? selectedProjectData?.customerName :
                            engagementData?.customerName}}</span>
                    </div>
                    <div class="dm-p-y-imp-10 dm-detail">
                        <span class="dm-detail__label">Country</span>
                        <span class="dm-detail__value">{{isProjectContext ? projectData?.customerCountry :
                            engagementData?.customerCountry}}</span>
                    </div>
                </div>
                <div class="dm-p-y-imp-10 dm-flex-align-center dm-m-b-12" *ngIf="!isProjectContext">
                    <dm-user-name *ngIf="engagementData.pPjm" [name]="engagementData.pPjm.name"
                        [alias]="engagementData.pPjm.alias" [role]="'Primary Project Manager'" [showPersonCard]="true"
                        [showPersonCardWithViewAvatar]="true">
                    </dm-user-name>
                    <div class="dm-p-l-10">
                        <div>Primary Project Manager</div>
                        <dm-user-name *ngIf="engagementData.pPjm" [name]="engagementData.pPjm.name"
                            [alias]="engagementData.pPjm.alias" [role]="'Primary Project Manager'"
                            [showPersonCard]="true">
                        </dm-user-name>
                    </div>
                </div>
                <div class="dm-p-y-imp-10 dm-flex-align-center dm-m-b-12" *ngIf="isProjectContext">
                    <dm-user-name [name]="selectedProjectData.pjMName" [alias]="selectedProjectData.pjMAlias"
                        [role]="'Project Manager / T&E Approver'" [showPersonCard]="true" [showPersonCardWithViewAvatar]="true">
                    </dm-user-name>
                    <div class="dm-p-l-10">
                        <div class="dm-detail__value">Project Manager / T&E Approver</div>
                        <dm-user-name [name]="selectedProjectData.pjMName" [alias]="selectedProjectData.pjMAlias"
                            [role]="'Project Manager / T&E Approver'" [showPersonCard]="true">
                        </dm-user-name>
                    </div>
                </div>
                <div class="dm-m-t-10 details-container" title="Progress Bar">
                    <dm-date-progress-bar [dateProgressBarData]="engagementDateDetails" [progressBarWidth]="'100%'"
                        *ngIf="!isProjectContext">
                    </dm-date-progress-bar>
                    <dm-date-progress-bar [dateProgressBarData]="projectDateDetails" [progressBarWidth]="'100%'"
                        *ngIf="isProjectContext">
                    </dm-date-progress-bar>
                </div>
            </div>
        </div>
    </ng-container>
    <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
</dm-tile>