
import { Observable } from "rxjs";
import { Component, forwardRef, Inject, QueryList, ViewChildren, Input } from "@angular/core";
import { FxpTelemActionType, DeviceFactoryProvider, ErrorSeverityLevel, FxpTelemEventName, FeatureUsageEvent, FxpConstants, FxpMessageService, FxpRouteService, UserInfoService } from "@fxp/fxpservices";
import moment from "moment";
import { Store } from "@ngrx/store";

import { ConfigManagerService } from "../../common/services/configmanager.service";
import { DmComponentAbstract } from "../../common/abstraction/dm-component.abstract";
import { CountryStateCityDropDownValidator } from "../../common/customformvalidators/dropdown.validator";
import { CompanyCodeValidator } from "../../common/customformvalidators/companycode.validator";
import { CacheService } from "../../common/services/cache.service";
import { DataService } from "../../common/services/data.service";
import { DMLoggerService } from "../../common/services/dmlogger.service";
import { FaultControlService } from "../../common/services/faultcontrol.service";
import { ProjectServiceFunctions } from "../../common/services/projectservice-functions.service";
import { ICityDetails, ICountryDetails, IStateDetails } from "../../common/services/contracts/one-profile.contracts";
import { IInternalEngagementDomainTypes, InternalEngagementType, INewInternalEngagementInput, INewInternalEngagementProjectInput, INewCreateInternalEngagementOutput, IInternalEngagementApprovalDetails, IInternalEngagementApprovalRequest, IInternalEngagementSearchApprovalRequest } from "./new-internal-engagement.contracts";
import { IGrmProjectCreateFunctionRequest, IInternalEngagementInfo } from "./internal-engagement-grm.contracts";
import { IInternalEngagementCreationCode } from "../../common/services/contracts/portfolio.model";
import { LoadMyPortfolioEngagements } from "../../store/my-portfolio/my-portfolio-engagement-list/my-portfolio-engagement-list.action";
import { IOneProfileCompanyCodeAttr, IOneProfileCompanyCodeAttrV2 } from "./../tiles/type-ahead-companycode/type-ahead-companycode-contracts";
import { IRoleDetail, IDomainValues } from "../../common/services/contracts/add-roles.contracts";
import { ISelectedUserAttributes } from "../tiles/type-ahead/type-ahead-contracts";
import { IState } from "../../store/reducers";
import { RouteName, CacheKeys, LogEventName, Components, ComponentPrefix, SourceConstants, Feature } from "../../common/application.constants";
import { OneProfileService } from "../../common/services/one-profile.service";
import { SharedFunctionsService } from "../../common/services/sharedfunctions.service";
import { ICustomer } from "../../common/services/contracts/customer.contract";
import { AddInternalEngagementProjectComponent } from "./add-internal-engagement-project/add-internal-engagement-project.component";
import { NewInternalEngagementService } from "../../common/services/new-internal-engagement-service";
import { IBulkIntEngDetailsResponse, BulkIntEngStatus } from "../../common/services/contracts/bulk-upload-int-engagement.service.contracts";
import { FormGroup, FormBuilder, Validators, AbstractControl } from "@angular/forms";
import { ITeamDetailsV2 } from "../../common/services/contracts/wbs-details-v2.contracts";
import { untilDestroyed } from "ngx-take-until-destroy";
import { IUserMembership, ICheckUserMembershipResponse } from "../../common/services/contracts/projectservice-functions.contract";
import { DmError } from "../../common/error.constants";
import { ITile } from "../tiles/dm-tile/dm-tile.component";
import { isNullOrUndefined } from "util";
import { WBSService } from "../../common/services/wbs.service";
import { IExchangeRate } from "../../common/services/contracts/wbs.service.contracts";

const primaryDomainProjectMangerText: string = "Lead Project Manager - Required";
const DATE_FORMAT: string = "YYYY-MM-DD";
const DATE_FORMAT2: string = "T00:00:00.000";
const SSOSSTGroupName: string = "SSO-SST";
const AREAACCOUNTALIGNED_DOMAIN = "Area Account Aligned";
const internalEngagementDomainValue = {
    domainValueName: AREAACCOUNTALIGNED_DOMAIN,
    mddDomainValueId: "450247"
};
const InternalEngagementCreationCodesForNonISDUsers: string[] = ["92", "91", "93", "Z4", "Z3", "94", "96", "97", "82", "83"];
@Component({
    selector: "dm-new-internal-engagement-v2",
    templateUrl: "./new-internal-engagement-v2.html",
    styleUrls: ["./new-internal-engagement-v2.scss"]
})
export class NewInternalEngagementComponentV2 extends DmComponentAbstract {
    @ViewChildren(AddInternalEngagementProjectComponent) public addProjectsComponent: QueryList<AddInternalEngagementProjectComponent>;
    @Input() public isBulkUploadProcess: boolean = false;
    public additionalPjmsList: ISelectedUserAttributes[] = [];
    public additionalProjectManagers: ISelectedUserAttributes[] = [];
    public amountFixed: number = 0;
    public amountPercent: number;
    public cancelAriaLabel: string;
    public cityList: ICityDetails[] = [];
    public cityCancelAriaLblTxt: string;
    public citySearchAriaLblTxt: string;
    public cityTypeAheadLabelText: string;
    public states$: Observable<IStateDetails[]>;
    public cities$: Observable<ICityDetails[]>;
    public companyCodeCancelAriaLblTxt: string;
    public companyCodeList: IOneProfileCompanyCodeAttrV2[] = [];
    public companyCodeSearchAriaLblTxt: string;
    public companyCodeTypeAheadLabelText: string;
    public countryList: ICountryDetails[] = [];
    public customerName: string = "";
    public customerCancelAriaLblTxt: string;
    public customerSearchAriaLblTxt: string;
    public customerTypeAheadLabelText: string;
    public customerCountry: ICountryDetails[];
    public costCenterCompanyCode: string;
    public engagementEndDate: Date;
    public engagementStartDate: Date;
    public uploadedFileName: string = "";
    public internalEngagementDescription: string;
    public internalEngagementDomain: IInternalEngagementDomainTypes;
    public internalEngagementDomains: IInternalEngagementDomainTypes[] = [];
    public internalEngagementName: string;
    public internalEngagementType: IInternalEngagementCreationCode;
    public internalEngagementTypes: IInternalEngagementCreationCode[] = [];
    public internalEngagementTypesTemp: IInternalEngagementCreationCode[] = [];
    public isAfterEngEndDate: boolean = false;
    public isBeforeEngStartDate: boolean = false;
    public isCityCtrlLoading: boolean = false;
    public isCityDisabled: boolean = true;
    public isCountryCtrlLoading: boolean = false;
    public isCustomerNameReadOnly: boolean;
    public isDisabledCreateEngagement: boolean = false;
    public isLeftNavPinned: boolean;
    public isRoleRequestWithInValidDates: boolean = false;
    public isRoleResourceRequired = false;
    public isStateCtrlLoading: boolean = false;
    public isStateDisabled: boolean = true;
    public federalEngagementFeatureEnabled: boolean;
    public hasNoStates: boolean = false;
    public hasProjectError: boolean = false;
    public loadingText: string = "Creating Internal Engagement";
    public nbueHours: number = 0;
    public newRolesDetails: IRoleDetail[] = [];
    public noResults: string;
    public pjmCancelAriaLblTxt: string;
    public pjmSearchAriaLblTxt: string;
    public pjmTypeAheadLabelText: string;
    public roleRequestWithInValidDatesMessage: string;
    public searchAriaLabel: string;
    public selectedCity: ICityDetails = { CityName: "" };
    public selectedCompanyCode: IOneProfileCompanyCodeAttr;
    public selectedCurrencyCode: string;
    public selectedCustomer: ICustomer;
    public selectedCompanyCodeId: string;
    public selectedCountry: ICountryDetails;
    public selectedPjm: ISelectedUserAttributes;
    public selectedState: IStateDetails;
    public selectedUsers: ISelectedUserAttributes[] = [];
    public showDeliveryDetailsSection: boolean = true;
    public showEndDateValidationMessage: boolean = false;
    public showEngagementDetailsSection: boolean = true;
    public showFinancialDetailsSection: boolean = true;
    public showNbueHoursValidationMessage: boolean = false;
    public showOwnersDetailsSection: boolean = true;
    public showProjectsSection: boolean = true;
    public showStaffingDetailsSection: boolean = true;
    public showStartDateEndDateValidationMessage: boolean = false;
    public showStartDateValidationMessage: boolean = false;
    public showCostCenterErrorMsg: boolean = false;
    public costCenterErrorMsg: string = "";
    public stateList: IStateDetails[] = [];
    public typeAheadLabelText: string;
    public srCrossCharge: string = "FixedAmount";
    public uploadedFileObj: any;
    public showFileUploadValidationMessage: boolean = false;
    public isSrOutReadOnly: boolean;
    public isSrOutLoading: boolean;
    public showEngagementNameValidationMessage: boolean = false;
    public visitedNbueHours: boolean = false;
    public selectedChargeType: string = "CostCenter";
    public showSrOutValidationMessage: boolean = false;
    public costCenter: string;
    public isCostCenterLoading: boolean = false;
    public isCostCenterReadOnly: boolean = false;
    public showCityTypeAhead: boolean = false;
    public showCountryValidationMessage: boolean = false;
    public showStateValidationMessage: boolean = false;
    public showCityValidationMessage: boolean = false;
    public showCompanyCodeValidationMessage: boolean = false;
    public showRoleValidationMessage: boolean = false;
    public showProjectsValidationMessage: boolean = false;
    public showDescriptionValidationMessage: boolean = false;
    public internalEngagementProjects: any = [];
    public projectIndex: number = 0;
    public showAddProjects: boolean = true;
    public showTpidValidationMessage: boolean = false;
    public tpidErrorMsg: string = "";
    public tpid: string;
    public showNewIntEngForm: boolean = true;
    public disableIntEngSection: boolean = false;
    // public isCustomerConfidential: boolean = false;
    public bulkUploadIntEngInitData: IBulkIntEngDetailsResponse;
    public engagementEndDateValidationMessage: string;
    public engagementStartDateValidationMessage: string;
    public internalEngagementErrorMessages = DmError.InternalEngagement;
    public tileContent: ITile;
    public isUserInISDHierarchy: boolean;
    public showApprovalIdInput: boolean;
    public isUserPartOfEligibleNBUEGroup: boolean;
    public isApprovalIdAlreadyUsed: boolean;
    public totalNbueApprovedHoursAcrossProjects: any;
    public nbueApprovedHoursValidationMessages: string;
    public get addProjectsComponentList(): AddInternalEngagementProjectComponent[] {
        return this.addProjectsComponent.toArray();
    }

    public createIntEngForm: FormGroup;
    // convenience getter for easy access to form fields
    public get internalEngagementFormControl(): any {
        return this.createIntEngForm.controls;
    }
    public get internalEngagementNameV2(): any {
        return this.createIntEngForm.get("engagementDetails.internalEngagementNameV2");
    }
    public get internalEngagementDescriptionV2(): any {
        return this.createIntEngForm.get("engagementDetails.internalEngagementDescriptionV2");
    }
    public get internalEngagementTypeV2(): any {
        return this.createIntEngForm.get("engagementDetails.internalEngagementTypeV2");
    }
    public get internalEngagementDomainV2(): any {
        return this.createIntEngForm.get("engagementDetails.internalEngagementDomainV2");
    }
    public get internalEngagementApprovalIdV2(): any {
        return this.createIntEngForm.get("engagementDetails.internalEngagementApprovalIdV2");
    }
    public get internalEngagementCustomerName(): any {
        return this.createIntEngForm.get("engagementDetails.internalEngagementCustomerName");
    }
    public get internalEngagementStartDate(): any {
        return this.createIntEngForm.get("engagementDetails.internalEngagementStartDate");
    }
    public get internalEngagementEndDate(): any {
        return this.createIntEngForm.get("engagementDetails.internalEngagementEndDate");
    }
    public get isCustomerConfidential(): any {
        return this.createIntEngForm.get("engagementDetails.isCustomerConfidential");
    }
    public get internalEngagementCustomerCountry(): any {
        return this.createIntEngForm.get("deliveryDetails.internalEngagementCustomerCountry");
    }
    public get internalEngagementCustomerState(): any {
        return this.createIntEngForm.get("deliveryDetails.internalEngagementCustomerState");
    }
    public get internalEngagementCustomerCity(): any {
        return this.createIntEngForm.get("deliveryDetails.internalEngagementCustomerCity");
    }
    public get internalEngagementCompanyCode(): any {
        return this.createIntEngForm.get("financialDetails.internalEngagementCompanyCode");
    }
    public get internalEngagementPPJM(): any {
        return this.createIntEngForm.get("ownersDetails.internalEngagementPPJM");
    }
    public get internalEngagementAdPPJMList(): any {
        return this.createIntEngForm.get("ownersDetails.internalEngagementAdPPJMList");
    }
    public get internalEngagementFormValue(): any {
        return this.createIntEngForm.value;
    }

    private clearedRolesCompanyCodes: string[];
    private FXP_CONSTANTS = FxpConstants;
    private microsoftCustomer: ICustomer = {
        name: "Microsoft",
        id: "",
        topParentId: "",
        country: undefined,
        isConfidential: false
    };
    private saossoGroupId: string;
    private isResourceScheduleFlowEnabled: boolean;
    private feature: FeatureUsageEvent;
    private experienceResult: boolean;
    private currencyCode = "USD";
    private exchangeRate = 1.0;
    private internalEngagementNBUEGroupIdFromConfig: string;

    public constructor(
        @Inject(forwardRef(() => DeviceFactoryProvider)) public deviceFactory: DeviceFactoryProvider,
        @Inject(forwardRef(() => FxpMessageService)) private fxpMessageService: FxpMessageService,
        @Inject(forwardRef(() => FxpRouteService)) private fxpRouteService: FxpRouteService,
        @Inject(forwardRef(() => UserInfoService)) private fxpUserInfoService: UserInfoService,
        @Inject(ConfigManagerService) private configManagerService: ConfigManagerService,
        @Inject(FormBuilder) private fb: FormBuilder,
        @Inject(OneProfileService) private oneProfileService: OneProfileService,
        @Inject(FaultControlService) private faultControlService: FaultControlService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(NewInternalEngagementService) private newInternalEngagementService: NewInternalEngagementService,
        @Inject(Store) private store: Store<IState>,
        @Inject(CacheService) private cacheService: CacheService,
        @Inject(ProjectServiceFunctions) private projectServiceFunctions: ProjectServiceFunctions,
        @Inject(WBSService) private wbsService: WBSService
    ) {
        super(dmLogger, Components.CreateInternalEngagement);

        this.pjmSearchAriaLblTxt = primaryDomainProjectMangerText;
        this.pjmTypeAheadLabelText = primaryDomainProjectMangerText;
        this.pjmCancelAriaLblTxt = "Remove Primary Project Manager Name";

        this.cityCancelAriaLblTxt = "Remove Selected City";
        this.citySearchAriaLblTxt = "City Name";

        this.customerCancelAriaLblTxt = "Remove Selected Customer";
        this.customerSearchAriaLblTxt = "Customer Name or TPID";

        this.companyCodeSearchAriaLblTxt = "Company Code - Required";
        this.companyCodeTypeAheadLabelText = primaryDomainProjectMangerText;
        this.companyCodeCancelAriaLblTxt = "Remove Company Code";
        this.searchAriaLabel = "Additional Project Manager";
        this.selectedPjm = this.sharedFunctionsService.getCurrentUserInfoAsSelectedUserAttr();
    }

    public ngOnInit(): void {
        this.dmLogger.renewCorrelationId();
        this.experienceResult = false;
        this.feature = this.dmLogger.startFeatureUsageEvent(Feature.CreateInternalEngagement, FxpTelemActionType.User, Feature.CreateInternalEngagement, FxpTelemEventName.ButtonClick);
        this.tileContent = {
            title: "New Internal Engagement",
            link: { name: "Learn more", url: "https://aka.ms/pjm-job-aid/create-internal-engagement", icon: "icon-education", tooltipText: "Learn more about creating Internal Engagements" }
        };
        this.initializeForm();
        this.sharedFunctionsService.focus("engagementDetailsSectionToggleButton", true);
    }

    /**
     * This method sets all the fields based on engagement type change
     */
    public onInternalEngagementTypeChange(internalEngType: IInternalEngagementCreationCode): void {
        if (this.showAddProjects) {
            if (this.addProjectsComponentList && this.addProjectsComponentList.length) {
                for (const project of this.addProjectsComponentList) {
                    project.toggleFieldsOnEngagementType(internalEngType);
                }
            }
        }
        if (internalEngType.typeDescription === InternalEngagementType.demandGeneric ||
            internalEngType.typeDescription === InternalEngagementType.sharedResource ||
            internalEngType.typeDescription === InternalEngagementType.msInternal ||
            internalEngType.typeDescription === InternalEngagementType.unitBased ||
            internalEngType.typeDescription === InternalEngagementType.preSalesGeneric) {
            this.internalEngagementCustomerName.setValue(this.microsoftCustomer);
            // this.customerName = "Microsoft";
            this.selectedCustomerUpdated(this.microsoftCustomer);
            this.isCustomerNameReadOnly = true;
        }
        if (internalEngType.typeDescription === InternalEngagementType.demandAccount
            || internalEngType.typeDescription === InternalEngagementType.preSalesAccount
            || internalEngType.typeDescription === InternalEngagementType.ecifPreSales) {
            this.customerName = "";
            this.selectedCustomerUpdated(null);
            this.isCustomerNameReadOnly = false;
        }
        if (this.sharedFunctionsService.isSrOutEngagementBasedOnCreationCode(internalEngType.engagementCreationCode.toString())) {
            this.internalEngagementCustomerName.setValue("Microsoft");
            this.customerName = "Microsoft";
            this.selectedCustomerUpdated(this.microsoftCustomer);
            this.isCustomerNameReadOnly = false;
        }
        
        if (this.isEngagementTypeforNonISDUsers(internalEngType.engagementCreationCode) 
            && this.isUserInISDHierarchy 
            && !this.isBulkUploadProcess 
            && this.isUserPartOfEligibleNBUEGroup){
            this.showApprovalIdInput = true;
        } else {
            this.showApprovalIdInput = false;
        }
    }

    /**
     * This method sets all the fields based on CompanyCode type change
     */
    public onInternalEngagementCompanyCodeChange(): void {
        if (this.showAddProjects && this.internalEngagementCompanyCode && this.internalEngagementCompanyCode.value && this.internalEngagementCompanyCode.value.companyCode) {
            this.oneProfileService.searchCompanyCodesList(this.internalEngagementCompanyCode.value.companyCode.toString()).toPromise()
                .then((response: IOneProfileCompanyCodeAttr[]) => {
                    const companyCodeDetails = response.filter((companydetails) => companydetails.CompanyCode && companydetails.CompanyCode.toString() === this.internalEngagementCompanyCode.value.companyCode.toString());
                    if (companyCodeDetails.length > 0) {
                        this.currencyCode = !isNullOrUndefined(companyCodeDetails[0]) && companyCodeDetails[0].CurrencyCode !== "" ? companyCodeDetails[0].CurrencyCode : "USD";
                        this.getExchangeRateForProjects();
                    }
                });
        }
    }

    /**
     * This method gets Exchange rate based on Currency Code and Engagement start date
     */
    public getExchangeRateForProjects(): void {
        const engagementStartDate = !isNullOrUndefined(this.internalEngagementStartDate.value) && this.internalEngagementStartDate.value !== "" ? moment(this.internalEngagementStartDate.value).format("MM/DD/YYYY") : moment().format("MM/DD/YYYY");
        if ( this.currencyCode === "USD") {
            this.exchangeRate = 1;
            this.setExchangeRateForProjects();
        }
        else {
            this.wbsService.getExchangeRate("USD", this.currencyCode, engagementStartDate).then((response: IExchangeRate) => {
                this.exchangeRate = response.exchangeRate;
                this.setExchangeRateForProjects();
            });
        }
    }


    /**
     * This method sets Exchange rate and currency code for each project
     */
    public setExchangeRateForProjects(): void {
        for (const project of this.addProjectsComponentList) {
            project.setCurrencyBasedOnCompanyCode(this.currencyCode, this.exchangeRate);
        }
    }

    /**
     * Sets or populates the engagement fields data in the form if data is being passed from
     * bulk create internal engagement process.
     * This function is used in child components, do not delete from here unless it is no longer used in the child component. (do a full project search)
     *  TODO: Need to set some other fields and will be taken care in other PR's after integration.
     *
     * @param {*} initData
     * @memberof NewInternalEngagementComponentV2
     */
    public setInitValues(initData: IBulkIntEngDetailsResponse): void {
        this.initializeForm().then(() => {
            if (initData && initData.engagement) {
                if (initData.status === BulkIntEngStatus.ValidationInProgress ||
                    initData.status === BulkIntEngStatus.QueuedForValidation) {
                    this.disableIntEngSection = true;
                } else {
                    this.disableIntEngSection = false;
                }
                this.internalEngagementProjects = [];
                this.projectIndex = 0;
                this.bulkUploadIntEngInitData = initData;
                this.internalEngagementNameV2.setValue(initData.engagement.name ? initData.engagement.name : undefined);
                this.internalEngagementDescriptionV2.setValue(initData.engagement.description ? initData.engagement.description : undefined);
                this.internalEngagementStartDate.setValue(initData.engagement.startDate ? initData.engagement.startDate : undefined);
                this.internalEngagementEndDate.setValue(initData.engagement.endDate ? initData.engagement.endDate : undefined);
                if (initData.engagement.requestType) {
                    this.internalEngagementTypeV2.setValue(this.internalEngagementTypes.filter((type) => type.typeDescription === initData.engagement.requestType)[0]);
                    this.setCustomerFieldReadOnlyByEngType(this.internalEngagementTypeV2.value);
                }
                if (initData.engagement.primaryDomain && initData.engagement.primaryDomain.name) {
                    this.internalEngagementDomainV2.setValue(this.internalEngagementDomains.filter((domain) => domain.mddDomainValueId === initData.engagement.primaryDomain.id.toString())[0]);
                }
                if (initData.engagement.customer) {
                    this.internalEngagementCustomerName.setValue(initData.engagement.customer);
                }
                if (initData.engagement.isConfidential) {
                    this.isCustomerConfidential.setValue(true);
                }

                if (initData.engagement.customerCountry && initData.engagement.customerCountry.countryName && initData.engagement.customerCountry.countryCode) {
                    this.internalEngagementCustomerCountry.setValue(this.countryList.filter((x) => x.CountryName === initData.engagement.customerCountry.countryName)[0]);
                    this.states$ = Observable.fromPromise(this.oneProfileService.getStatesBasedOnCountryCode(initData.engagement.customerCountry.countryCode));
                    this.states$.pipe(untilDestroyed(this)).subscribe((statesResponse: IStateDetails[]) => {
                        this.stateList = statesResponse;
                        this.isStateCtrlLoading = false;
                        if (statesResponse && statesResponse.length === 1 && statesResponse[0].StateName && statesResponse[0].StateName.trim() === "") {
                            // Some countries has no States(E.g: Singapore or Vatican).
                            // In such cases, OneProfile api returns a single result with StateName as empty text.
                            this.isStateDisabled = true;
                            this.internalEngagementFormControl.deliveryDetails.controls.internalEngagementCustomerState.disable();
                            this.hasNoStates = true;
                            this.selectedState = null;
                            this.isCityCtrlLoading = true;
                            const cacheKey = CacheKeys.Cities.KeyName + initData.engagement.customerCountry.countryCode + initData.engagement.customerState;
                            this.cities$ = Observable.fromPromise(this.cacheService.get(cacheKey, () => this.oneProfileService.getCitiesBasedOnCountryCodeStateName(initData.engagement.customerCountry.countryCode, initData.engagement.customerState), CacheKeys.Cities.Duration));
                            this.cities$.pipe(untilDestroyed(this)).subscribe((citiesResponse: ICityDetails[]) => {
                                this.cityList = citiesResponse;
                                if (initData.engagement.customerCity) {
                                    this.internalEngagementCustomerCity.setValue(this.cityList.filter((x) => x.CityName === initData.engagement.customerCity)[0]);
                                }
                                this.isCityDisabled = false;
                                this.isCityCtrlLoading = false;
                                this.internalEngagementFormControl.deliveryDetails.controls.internalEngagementCustomerState.enable();
                            });
                        } else {
                            this.stateList = statesResponse;
                            if (initData.engagement.customerState && this.stateList.length) {
                                this.internalEngagementCustomerState.setValue(this.stateList.filter((x) => x.StateName === initData.engagement.customerState)[0]);
                                const cacheKey = CacheKeys.Cities.KeyName + initData.engagement.customerCountry.countryCode + initData.engagement.customerState;
                                this.cacheService.get(cacheKey, () => this.oneProfileService.getCitiesBasedOnCountryCodeStateName(initData.engagement.customerCountry.countryCode, initData.engagement.customerState), CacheKeys.Cities.Duration)
                                    .then((citiesResponse: ICityDetails[]) => {
                                        if (citiesResponse.length > 4000) {
                                            this.showCityTypeAhead = true;
                                            this.cityList = citiesResponse;
                                        } else {
                                            this.showCityTypeAhead = false;
                                            this.cityList = citiesResponse;
                                            this.cityList.unshift({ CityName: "Select" });
                                        }

                                        if (initData.engagement.customerCity && this.cityList.length) {
                                            this.internalEngagementCustomerCity.setValue(this.cityList.filter((x) => x.CityName === initData.engagement.customerCity)[0]);
                                        }
                                        this.isCityDisabled = false;
                                        this.isCityCtrlLoading = false;
                                        this.internalEngagementFormControl.deliveryDetails.controls.internalEngagementCustomerState.enable();
                                    });
                            }
                            this.isStateDisabled = false;
                            this.internalEngagementFormControl.deliveryDetails.controls.internalEngagementCustomerState.enable();
                        }
                    });
                }

                this.internalEngagementCompanyCode.setValue(this.companyCodeList.filter((company) => initData.engagement.company && (company.companyCode === initData.engagement.company.companyCode))[0]);

                if (initData.engagement.projects.length && initData.engagement.projects) {
                    initData.engagement.projects.forEach((x, index) => {
                        const projectErrors = {};
                        // Filters the project errors by checking projects key string.
                        if (initData.errorDetails) {
                            Object.entries(initData.errorDetails).forEach(([key, value]) => {
                                if (key.includes(`projects[${index}]`)) {
                                    projectErrors[key.split(/[.]+/).splice(1).toString()] = value;
                                }
                            });
                        }
                        const project = { ...x, id: ++this.projectIndex, errorDetails: projectErrors ? projectErrors : null, isProjectFromBulkProcess: true };
                        this.internalEngagementProjects.push(project);
                        if (Object.keys(projectErrors).length > 0) {
                            this.hasProjectError = true;
                        }
                    });
                }

                // Due to angular form change detection, setTimeout should be used to set errors on the form from the server
                setTimeout(() => {
                    const teamStructure = initData.engagement.teamStructure;
                    if (teamStructure && teamStructure.length) {
                        const PPJM = teamStructure.filter((x) => x.role === "PPJM")[0];
                        if (PPJM && PPJM.bpid) {
                            this.internalEngagementPPJM.setValue(this.sharedFunctionsService.getUserInfoObject(PPJM));
                        }

                        let ADPPJM = teamStructure.filter((t) => t.role === "ADPPJM");
                        ADPPJM = ADPPJM.filter((f) => f.bpid);
                        if (ADPPJM && ADPPJM.length) {
                            this.internalEngagementAdPPJMList.setValue(ADPPJM);
                            this.additionalProjectManagers = ADPPJM.map((x) => this.sharedFunctionsService.getUserInfoObject(x));
                        }

                        // If an error exists in team structure, set error and invalidate proper form control
                        for (const member in teamStructure) {
                            if (initData.errorDetails && initData.errorDetails[`teams[${member}].Bpid`]) {
                                if (teamStructure[member].role === "PPJM") {
                                    this.internalEngagementPPJM.setErrors({ invalidPpjm: DmError.InternalEngagement.UploadedPPJMIsInvalid });
                                } else {
                                    this.internalEngagementAdPPJMList.setErrors({ invalidAdppjm: DmError.InternalEngagement.UploadedAdditionalPPJMsIsInvalid });
                                }
                            }
                        }
                    }

                    if (initData.errorDetails) {
                        this.setErrorMessage("engagementNameApiError", "name", this.internalEngagementNameV2, initData.errorDetails);
                        this.setErrorMessage("engagementDescriptionApiError", "description", this.internalEngagementDescriptionV2, initData.errorDetails);
                        this.setErrorMessage("endDateApiError", "endDate", this.internalEngagementEndDate, initData.errorDetails);
                        this.setErrorMessage("startDateApiError", "startDate", this.internalEngagementStartDate, initData.errorDetails);
                        this.setErrorMessage("customerError", "customer.Id", this.internalEngagementCustomerName, initData.errorDetails);
                        this.setErrorMessage("countryApiError", "customerCountry", this.internalEngagementCustomerCountry, initData.errorDetails);
                        this.setErrorMessage("stateApiError", "customerState", this.internalEngagementCustomerState, initData.errorDetails);
                        this.setErrorMessage("cityApiError", "customerCity", this.internalEngagementCustomerCity, initData.errorDetails);
                    }

                    if (this.createIntEngForm.controls.engagementDetails.valid) {
                        this.showEngagementDetailsSection = false;
                    } else {
                        this.showEngagementDetailsSection = true;
                    }
                    if (this.createIntEngForm.controls.deliveryDetails.valid) {
                        this.showDeliveryDetailsSection = false;
                    } else {
                        this.showDeliveryDetailsSection = true;
                    }
                    if (this.createIntEngForm.controls.ownersDetails.valid) {
                        this.showOwnersDetailsSection = false;
                    } else {
                        this.showOwnersDetailsSection = true;
                    }
                    if (this.createIntEngForm.controls.financialDetails.valid) {
                        this.showFinancialDetailsSection = false;
                    } else {
                        this.showFinancialDetailsSection = true;
                    }
                    if (this.hasProjectError) {
                        this.showProjectsSection = true;
                    } else {
                        this.showProjectsSection = false;
                    }
                    if (initData.errorDetails && Object.keys(initData.errorDetails).length < 1) { // show engagement details section if there are no errors
                        this.showEngagementDetailsSection = true;
                    }
                });
            }
        });
    }

    /**
     * Gets all the internal engagement details from the form.
     * This function is used in child components, do not delete from here unless it is no longer used in the child component. (do a full project search)
     * TODO: Still need to get data from all the fields and will taken care in other PR's after integration.
     *
     * @returns {*}
     * @memberof NewInternalEngagementComponentV2
     */
    public getInternalEngagementDetails(): IBulkIntEngDetailsResponse {
        this.bulkUploadIntEngInitData.engagement.name = this.internalEngagementNameV2.value ? this.internalEngagementNameV2.value : undefined;
        this.bulkUploadIntEngInitData.engagement.description = this.internalEngagementDescriptionV2.value ? this.internalEngagementDescriptionV2.value : undefined;
        this.bulkUploadIntEngInitData.engagement.requestType = this.internalEngagementTypeV2.value && this.internalEngagementTypeV2.value.typeDescription ? this.internalEngagementTypeV2.value.typeDescription : undefined;
        this.bulkUploadIntEngInitData.engagement.requestTypeCode = this.internalEngagementTypeV2.value && this.internalEngagementTypeV2.value.engagementCreationCode ? this.internalEngagementTypeV2.value.engagementCreationCode : undefined;
        this.bulkUploadIntEngInitData.engagement.primaryDomain = this.internalEngagementDomainV2.value && this.internalEngagementDomainV2.value.mddDomainValueId ? { name: this.internalEngagementDomainV2.value.domainValueName, id: this.internalEngagementDomainV2.value.mddDomainValueId } : undefined;
        this.bulkUploadIntEngInitData.engagement.startDate = this.internalEngagementStartDate.value ? moment(this.internalEngagementStartDate.value).format(DATE_FORMAT + DATE_FORMAT2) : undefined;
        this.bulkUploadIntEngInitData.engagement.endDate = this.internalEngagementEndDate.value ? moment(this.internalEngagementEndDate.value).format(DATE_FORMAT + DATE_FORMAT2) : undefined;
        this.bulkUploadIntEngInitData.engagement.customerCountry = this.internalEngagementCustomerCountry.value && this.internalEngagementCustomerCountry.value.CountryName ? { countryCode: this.internalEngagementCustomerCountry.value.CountryCode, countryName: this.internalEngagementCustomerCountry.value.CountryName } : undefined;
        this.bulkUploadIntEngInitData.engagement.inputState = this.internalEngagementCustomerState.value && this.internalEngagementCustomerState.value.StateName ? this.internalEngagementCustomerState.value.StateName : undefined;
        this.bulkUploadIntEngInitData.engagement.inputCity = this.internalEngagementCustomerCity.value && this.internalEngagementCustomerCity.value.CityName ? this.internalEngagementCustomerCity.value.CityName : undefined;
        this.bulkUploadIntEngInitData.engagement.company = this.internalEngagementCompanyCode.value ? this.internalEngagementCompanyCode.value : undefined;

        if (this.internalEngagementPPJM && this.internalEngagementPPJM.value) {
            let teamStructure: ITeamDetailsV2[];
            if (this.internalEngagementPPJM.pristine) {
                teamStructure = this.sharedFunctionsService.createTeamStructure(this.internalEngagementPPJM.value, this.additionalProjectManagers, "Engagement");
            } else {
                teamStructure = this.sharedFunctionsService.createTeamStructureIfDirty(this.internalEngagementPPJM.value, this.additionalProjectManagers, "Engagement");
            }
            this.bulkUploadIntEngInitData.engagement.teamStructure = teamStructure ? teamStructure : undefined;
        }

        if (this.bulkUploadIntEngInitData.engagement.customer) {
            if (this.bulkUploadIntEngInitData.engagement.customer.id || (this.bulkUploadIntEngInitData.engagement.customer.name && this.bulkUploadIntEngInitData.engagement.customer.name.toLowerCase() === "microsoft")) {
                this.bulkUploadIntEngInitData.engagement.customer = { ...this.bulkUploadIntEngInitData.engagement.customer, name: this.bulkUploadIntEngInitData.engagement.customer.name };
            } else {
                this.bulkUploadIntEngInitData.engagement.customer = this.internalEngagementCustomerName.value;
            }
        }

        this.bulkUploadIntEngInitData.engagement.projects = [];
        if (this.addProjectsComponentList && this.addProjectsComponentList.length) {
            for (const project of this.addProjectsComponentList) {
                this.bulkUploadIntEngInitData.engagement.projects.push(project.getProjectDetailsFromBulk());
            }
        }
        return this.bulkUploadIntEngInitData;
    }

    /**
     * Select Engagement start date
     *
     * @param {Date} date
     * @memberof NewInternalEngagementComponentV2
     */
    public selectEngagementStartDate(date: Date): void {
        this.internalEngagementStartDate.setValue(date);
        this.validateDates();
        this.getExchangeRateForProjects();
    }

    /**
     * This method collapses the sections based on name
     *
     * @param {string} sectionName
     * @memberof NewInternalEngagementComponentV2
     */
    public toggleSection(sectionName: string, id: string): void {
        switch (sectionName) {
            case "engagementDetailsSection": {
                this.showEngagementDetailsSection = !this.showEngagementDetailsSection;
                break;
            }
            case "deliveryDetailsSection": {
                this.showDeliveryDetailsSection = !this.showDeliveryDetailsSection;
                break;
            }
            case "financialDetailsSection": {
                this.showFinancialDetailsSection = !this.showFinancialDetailsSection;
                break;
            }
            case "ownersDetailsSection": {
                this.showOwnersDetailsSection = !this.showOwnersDetailsSection;
                break;
            }
            case "staffingDetailsSection": {
                this.showStaffingDetailsSection = !this.showStaffingDetailsSection;
                break;
            }
            case "projectsDetailsSection": {
                this.showProjectsSection = !this.showProjectsSection;
                break;
            }
        }
        this.sharedFunctionsService.focus(id, true);
    }

    /**
     * Adds a project to internal engagement and navigates focus when button is clicked.
     *
     * @memberof NewInternalEngagementComponentV2
     */
    public addProjectClicked(): void {
        const project = { id: ++this.projectIndex, hideNbueUploadField: false};
        if (this.isBulkUploadProcess) {
            project.hideNbueUploadField = true;
        }
        this.internalEngagementProjects.push(project);
        if (this.internalEngagementProjects.length) {
            this.showProjectsValidationMessage = false;
            this.isDisabledCreateEngagement = false;
        }
        this.sharedFunctionsService.focus(`projectSection-${project.id}`, true);
        setTimeout(() => {
            this.setExchangeRateForProjects();
        }, 500);
    }

    /**
     * Delete a selected project.
     *
     * @param {number} id
     * @memberof NewInternalEngagementComponentV2
     */
    public deleteProject(id: number): void {
        this.internalEngagementProjects.splice(id - 1, 1);
        this.projectIndex--;
    }

    /**
     * Track by function used to improve performance state dropdowns.
     * This will help for most cases, however for very large data sets we should use
     * virtual scrolling once Angular upgrade happens.
     *
     * @param {*} index
     * @param {*} item
     * @returns
     * @memberof NewInternalEngagementComponentV2
     */
    public trackByStateName(index: number, item: { StateName: string }): string {
        if (!item) {
            return null;
        }

        return item.StateName;
    }

    /**
     * Track by function used to improve performance city dropdowns.
     * This will help for most cases, however for very large data sets we should use
     * virtual scrolling once Angular upgrade happens.
     *
     * @param {*} index
     * @param {*} item
     * @returns
     * @memberof NewInternalEngagementComponentV2
     */
    public trackByCityName(index: number, item: { CityName: string }): string {
        if (!item) {
            return null;
        }

        return item.CityName;
    }

    /**
     * Select Engagement end date
     *
     * @param {Date} date
     * @memberof NewInternalEngagementComponentV2
     */
    public selectEngagementEndDate(date: Date): void {
        this.internalEngagementEndDate.setValue(date);
        this.validateDates();
    }

    /**
     * selectedUserUpdated todo docs
     * @param value
     */
    public selectedUserUpdated(value: ISelectedUserAttributes): void {
        this.internalEngagementPPJM.setValue(value);
        this.selectedPjm = value;
    }

    /**
     * Gets the selected city from typeahead input
     *
     * @param {string} value
     * @memberof NewInternalEngagementComponentV2
     */
    public selectedCityUpdated(value: string): void {
        this.selectedCity.CityName = value;
    }

    /**
     * Gets the selected customer from typeahead input
     * and sets customer name and tpid.
     *
     * @param {ICustomer} value
     * @memberof NewInternalEngagementComponentV2
     */
    public selectedCustomerUpdated(value: ICustomer): void {
        this.selectedCustomer = value;
        this.showTpidValidationMessage = false;
        if (value) {
            this.internalEngagementCustomerName.setValue(value);
            this.customerName = this.selectedCustomer.name;
            this.tpid = this.selectedCustomer.topParentId;
            this.validateTpid();
            this.isCustomerConfidential.setValue(this.selectedCustomer.isConfidential);
            if (this.selectedCustomer && this.selectedCustomer.country) {
                this.customerCountry = this.countryList.filter((x: ICountryDetails) => x.CountryCode.toLowerCase() === this.selectedCustomer.country.toLowerCase());
                if (this.customerCountry && this.customerCountry.length) {
                    this.onCountryChange(this.customerCountry[0]);
                }
                if (this.selectedCustomer.region) {
                    const customerStateDetails = this.stateList.filter((x: IStateDetails) => x.StateCode.toLowerCase() === this.selectedCustomer.region.toLowerCase());
                    if (customerStateDetails && customerStateDetails.length) {
                        this.onStateChange(customerStateDetails[0]);
                    }
                }
                if (this.selectedCustomer.city) {
                    const customerCityDetails = this.cityList.filter((x: ICityDetails) => x.CityName.toLowerCase() === this.selectedCustomer.city.toLowerCase());
                    if (customerCityDetails && customerCityDetails.length) {
                        this.internalEngagementCustomerCity.setValue(customerCityDetails[0]);
                    }
                }
            }
        } else {
            this.internalEngagementCustomerName.setValue(undefined);
            this.customerName = undefined;
            this.tpid = undefined;
            this.isCustomerConfidential.setValue(false);
        }
    }

    /**
     * Sets the selected company code from user input and also validates with CC/IO company code.
     * @param value
     */
    public selectedCompanyCodeUpdated(value: IOneProfileCompanyCodeAttr): void {
        // this.selectedCompanyCode = value;
        // this.selectedCompanyCodeId = "" + this.selectedCompanyCode.CompanyCode;
        // this.selectedCurrencyCode = "" + this.selectedCompanyCode.CurrencyCode;
        this.showCompanyCodeValidationMessage = false;
        this.showCostCenterErrorMsg = false;
        if (value && value.CompanyCode && value.CompanyCode.toString() !== this.costCenterCompanyCode) {
            this.costCenterErrorMsg = DmError.InternalEngagement.CostCenterOrInternalOrderMustBeInTheCompanyCode;
            this.showCostCenterErrorMsg = true;
        }
    }

    /**
     * setDefaultCurrencyCode todo docs
     * @param value
     */
    public setDefaultCurrencyCode(value: string): void {
        this.selectedCurrencyCode = value;
    }

    /**
     * To go back previous screen
     */
    public goBackToPreviousScreen(): void {
        this.store.dispatch(new LoadMyPortfolioEngagements(false));
        this.fxpRouteService.navigatetoSpecificState(RouteName.Portfolio, {
            loadFromCache: false
        });
        this.focus("newinternalEngagement", true, 300);
    }

    /**
     * On country change, gets states list of that country.
     */
    public onCountryChange(selectedCountry: ICountryDetails): void {
        this.stateList = [];
        this.cityList = [];
        this.internalEngagementCustomerCountry.setValue(selectedCountry);
        if (selectedCountry && selectedCountry.CountryCode && selectedCountry.CountryCode !== "0") {
            this.showCountryValidationMessage = false;
            this.isStateCtrlLoading = true;
            this.oneProfileService.getStatesBasedOnCountryCode(selectedCountry.CountryCode)
                .then((response: IStateDetails[]) => {
                    this.isStateCtrlLoading = false;
                    this.internalEngagementCustomerState.setErrors(null);
                    if (response && response.length === 1 && response[0].StateName && response[0].StateName.trim() === "") {
                        /* Some countries have no States(E.g: Singapore or Vatican).
            In such cases, OneProfile api returns a single result with StateName as empty text.
            We also need to update the form with returned value*/
                        const mockSelectedState: IStateDetails = {
                            StateName: ""
                        };
                        this.internalEngagementCustomerState.setValue(mockSelectedState);
                        this.isStateDisabled = true;
                        this.hasNoStates = true;
                        this.getCities(selectedCountry.CountryCode, mockSelectedState.StateName);
                        this.internalEngagementFormControl.deliveryDetails.controls.internalEngagementCustomerState.disable();
                    } else {
                        this.stateList = response;
                        this.stateList.unshift({ StateCode: "0", StateName: "Select" });
                        this.internalEngagementCustomerState.setValue(this.stateList[0]);
                        this.cityList.unshift({ CityName: "Select" });
                        this.internalEngagementCustomerCity.setValue(this.cityList[0]);
                        this.isStateDisabled = false;
                        this.internalEngagementFormControl.deliveryDetails.controls.internalEngagementCustomerState.enable();
                    }
                }).catch((error) => {
                    const message: string = error.ErrorMessage ? error.ErrorMessage : DmError.InternalEngagement.ErrorOccuredInGettingStatesData;
                    this.logError(SourceConstants.Method.OnCountryChange, error, message, ErrorSeverityLevel && ErrorSeverityLevel.High);
                    this.internalEngagementCustomerState.setErrors({ getStatesListError: message });
                });
        } else {
            this.showCountryValidationMessage = true;
            this.stateList.unshift({ StateCode: "0", StateName: "Select" });
            this.internalEngagementCustomerState.setValue(this.stateList[0]);
            this.cityList.unshift({ CityName: "Select" });
            this.internalEngagementCustomerCity.setValue(this.cityList[0]);
            this.isStateDisabled = true;
            this.isCityDisabled = true;
            this.internalEngagementFormControl.deliveryDetails.controls.internalEngagementCustomerState.disable();
            this.internalEngagementFormControl.deliveryDetails.controls.internalEngagementCustomerCity.disable();
        }
    }

    /**
     * On state change in the form, sets selected state value.
     */
    public onStateChange(selectedState: IStateDetails): void {
        this.cityList = [];
        this.internalEngagementCustomerState.setValue(selectedState);
        if (selectedState && selectedState.StateName && selectedState.StateName.trim() !== "" && selectedState.StateCode !== "0") {
            this.getCities(this.internalEngagementCustomerCountry.value.CountryCode, selectedState.StateName);
            this.showStateValidationMessage = true;
            this.cityList.unshift({ CityName: "Select" });
            this.internalEngagementCustomerCity.setValue(this.cityList[0]);
            this.isCityDisabled = true;
        }
    }

    /**
     * triggered on city change in the form, sets selected city value.
     */
    public onCityChange(selectedCity: ICityDetails): void {
        this.internalEngagementCustomerCity.setValue(selectedCity);
        if (selectedCity && selectedCity.CityName && selectedCity.CityName.trim() && selectedCity.CityName !== "Select") {
            this.showCityValidationMessage = false;
        } else {
            this.showCityValidationMessage = true;
        }
    }

    /**
     * On click of Create Internal engagement, all the fields submitted by user
     * are used in creating a internal engagement.
     * TODO: This method will be changed as per latest changes in bulk upload process
     */
    public async createInternalEngagement(): Promise<void> {
        this.sharedFunctionsService.focus("internalEngagementName", true);
        if (this.internalEngagementProjects.length <= 0) {
            this.showProjectsValidationMessage = true;
            return;
        }

        if (!this.validateTpid()) {
            return;
        }

        let isInvalidEngagementForm = false;
        let isInvalidProjectForm = false;
        isInvalidEngagementForm = this.createIntEngForm.invalid;
        if (isInvalidEngagementForm) {
            this.newInternalEngagementService.markFormGroupTouched(this.createIntEngForm);
        }
        if (this.addProjectsComponentList && this.addProjectsComponentList.length) {
            for (const project of this.addProjectsComponentList) {
                let isRoleEmpty = false;
                if (!project.newRolesDetails || project.newRolesDetails.length < 1) {
                    isRoleEmpty = true;
                    project.showRoleDetailsRequiredMessage = true;
                }
                if (project.projectForm.invalid) {
                    this.newInternalEngagementService.markFormGroupTouched(project.projectForm);
                }
                isInvalidProjectForm = project.projectForm.invalid || isRoleEmpty;

            }
        }
        if (isInvalidProjectForm || isInvalidEngagementForm) {
            return;
        }

        // TODO: Check logic for isClearenceRequired validation

        // if (this.checkIfClearedRolesRequired()) {
        //   return;
        // }
        this.totalNbueApprovedHoursAcrossProjects = 0;
        this.isDisabledCreateEngagement = true;
        const projectDetailsList: INewInternalEngagementProjectInput[] = [];
        if (this.addProjectsComponentList && this.addProjectsComponentList.length) {
            for (const project of this.addProjectsComponentList) {
                if (project.projectForm.valid) {
                    const projectDetails = project.getProjectDetails();
                    projectDetailsList.push(projectDetails);
                } else {
                    // If Project form is not valid, do not make api call
                    this.isDisabledCreateEngagement = false;
                    this.newInternalEngagementService.markFormGroupTouched(this.createIntEngForm);
                    if (this.addProjectsComponentList && this.addProjectsComponentList.length) {
                        for (const projectComponent of this.addProjectsComponentList) {
                            this.newInternalEngagementService.markFormGroupTouched(projectComponent.projectForm);
                        }
                    }
                    return;
                }
            }
            let proceed = false;
            let approvalIdFromForm = "";
            
            if (!this.isUserInISDHierarchy 
                || (this.isUserInISDHierarchy && !this.isEngagementTypeforNonISDUsers(this.internalEngagementTypeV2.value.engagementCreationCode))
                || this.isBulkUploadProcess) {
                proceed = true;
            }
            if (this.isUserInISDHierarchy 
                && this.isUserPartOfEligibleNBUEGroup 
                && this.isEngagementTypeforNonISDUsers(this.internalEngagementTypeV2.value.engagementCreationCode)
                && !this.isBulkUploadProcess) {
                this.nbueApprovedHoursValidationMessages = "";
                if (this.internalEngagementApprovalIdV2 && this.internalEngagementApprovalIdV2.value && this.internalEngagementApprovalIdV2.value !== "") {
                    approvalIdFromForm = this.internalEngagementApprovalIdV2.value;
                }
                else {
                    this.nbueApprovedHoursValidationMessages = "Approval Id is mandatory";
                    this.isDisabledCreateEngagement = false;
                    return;
                }
                if (this.addProjectsComponentList && this.addProjectsComponentList.length) {
                    for (const project of this.addProjectsComponentList) {
                        const projectDetails = project.getProjectDetails();
                        this.totalNbueApprovedHoursAcrossProjects = this.totalNbueApprovedHoursAcrossProjects + projectDetails.nbueApprovedHours;
                    }
                    const internalEngagementSearchApprovalIdRequest: IInternalEngagementSearchApprovalRequest = {
                        approvalId : approvalIdFromForm
                    };
                    await this.newInternalEngagementService.searchApprovalDetailsForInternalEngagement(internalEngagementSearchApprovalIdRequest)
                        .then(async (internalEngagementISDApprovalDetails: IInternalEngagementApprovalDetails) => {
                            if (!internalEngagementISDApprovalDetails) {
                                this.nbueApprovedHoursValidationMessages = `${this.nbueApprovedHoursValidationMessages} Approval Id ${approvalIdFromForm} is not found in system. Please check approval id and confirm.`;
                                proceed = false;
                            }
                            if (internalEngagementISDApprovalDetails && internalEngagementISDApprovalDetails.isUsed) {
                                this.nbueApprovedHoursValidationMessages = `${this.nbueApprovedHoursValidationMessages} Approval Id is already used for engagement ${internalEngagementISDApprovalDetails.engagementId}. Please use approval id which is not associated with any other engagement.`;
                                proceed = false;
                            }
                            if (internalEngagementISDApprovalDetails 
                                && !internalEngagementISDApprovalDetails.isUsed
                                && internalEngagementISDApprovalDetails.nbueHours 
                                && this.totalNbueApprovedHoursAcrossProjects > internalEngagementISDApprovalDetails.nbueHours) {
                                this.nbueApprovedHoursValidationMessages = `${this.nbueApprovedHoursValidationMessages} Total approved NBUE hours across projects ${this.totalNbueApprovedHoursAcrossProjects} hrs is greater than Approved NBUE hours ${internalEngagementISDApprovalDetails.nbueHours} on this engagement . Please reduce  or adjust the Approved NBUE Hours in project(s).`;
                                proceed = false;
                            }
                            if (internalEngagementISDApprovalDetails && internalEngagementISDApprovalDetails.nbueHours
                                && !internalEngagementISDApprovalDetails.isUsed
                                && this.totalNbueApprovedHoursAcrossProjects
                                && this.totalNbueApprovedHoursAcrossProjects <= internalEngagementISDApprovalDetails.nbueHours ) {
                                proceed = true;
                            }
                        }).catch((error) => {
                            if (error && error.status === 404) {
                                this.nbueApprovedHoursValidationMessages = "Approval Id " + approvalIdFromForm + " is not found in system. Please check approval id and confirm.";     
                            }
                            const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                            this.logError(SourceConstants.Method.CreateInternalEngagement, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                            proceed = false;
                            return;
                        });
                }
            }
            if (!proceed) {
                this.isDisabledCreateEngagement = false;
                return;
            }

            let teamStructure: ITeamDetailsV2[];
            if (this.internalEngagementPPJM && this.internalEngagementPPJM.value) {

                if (this.internalEngagementPPJM.pristine) {
                    teamStructure = this.sharedFunctionsService.createTeamStructure(this.internalEngagementPPJM.value, this.additionalProjectManagers, "Engagement");
                } else {
                    teamStructure = this.sharedFunctionsService.createTeamStructureIfDirty(this.internalEngagementPPJM.value, this.additionalProjectManagers, "Engagement");
                }
            }

            // this.isDisabledCreateEngagement = (projectDetailsList && projectDetailsList.length) ? false : true;
            // if (this.isDisabledCreateEngagement) {
            //   this.showProjectsValidationMessage = true;
            //   return;
            // }
            const internalEngagementInputObjectV2: INewInternalEngagementInput = {
                name: this.internalEngagementNameV2.value,
                description: this.internalEngagementDescriptionV2.value,
                primaryDomain: this.internalEngagementDomainV2.value.mddDomainValueId,
                customerName: this.internalEngagementCustomerName.value.name,
                customerId: this.internalEngagementCustomerName.value.number,
                requestType: this.internalEngagementTypeV2.value.engagementCreationCode,
                startDate: moment(this.internalEngagementStartDate.value).format(DATE_FORMAT + DATE_FORMAT2),
                endDate: moment(this.internalEngagementEndDate.value).format(DATE_FORMAT + DATE_FORMAT2),
                teams: teamStructure ? teamStructure : [],
                companyCode: +this.internalEngagementCompanyCode.value.companyCode,
                topParentId: this.tpid, // TODO: Update from CustomerObject
                isConfidential: this.isCustomerConfidential.value,
                customerCountry: this.internalEngagementCustomerCountry.value.CountryCode,
                customerState: this.internalEngagementCustomerState.value.StateName,
                customerCity: this.internalEngagementCustomerCity.value.CityName,
                projects: projectDetailsList,
                createdGroup: this.isResourceScheduleFlowEnabled ? await this.verifyIsUserPartOfSSOSSTGroup() : undefined
            };
            // TODO : Upload attachments for multiple projects

            /* SR Out Cross Charge Validation taken from V1, part of  validateInputsToCreateEngagement()*/
            // const isEngagementSROut: boolean = this.internalEngagementType.engagementCreationCode === srOutCorpHQ
            //     || this.internalEngagementType.engagementCreationCode === srOutOtherBG;
            // let srOutValid: boolean = true;
            // if (isEngagementSROut) {
            //     if (this.srCrossCharge === "Percentage" && this.amountPercent <= 0) {
            //         this.srCrossChargeValidationMessage = "SR Cross Charge percentage must be greater than 0%.";
            //         this.showSrCrossChargeValidationMessage = true;
            //         this.focus("amountPercent", true);
            //         srOutValid = false;
            //     } else if (this.srCrossCharge === "FixedAmount" && this.amountFixed <= 0) {
            //         this.srCrossChargeValidationMessage = "SR Cross Charge monthly fixed charge must be greater than 0.";
            //         this.showSrCrossChargeValidationMessage = true;
            //         this.focus("amountFixed", true);
            //         srOutValid = false;
            //     }
            // }
            // if (!srOutValid) {
            //     return;
            // }

            // Copying addprojectcomponent into a variable before setting loading screen to persist UI component
            const addProjectsComponentListVar = this.addProjectsComponentList;
            this.showLoading = true;
            this.isComponentLoading = true;
            this.loadingText = `Creating Internal Engagement: "${internalEngagementInputObjectV2.name}"`;
            this.newInternalEngagementService.createNewInternalEngagementV2(internalEngagementInputObjectV2).then(async (internalEngagementOutputV2: INewCreateInternalEngagementOutput) => {
                this.fxpMessageService.addMessage("Successfully created SAP project " + internalEngagementOutputV2.id, this.FXP_CONSTANTS.messageType.success, false);
                
                
                const internalEngagementApprovalUpdate: IInternalEngagementApprovalRequest = {
                    engagementId : internalEngagementOutputV2.id,
                    isUsed : true// this.internalEngagementApprovalIdV2.value// "IE.READ-00027Shashank"
                };
                if (this.isUserInISDHierarchy 
                    && this.isUserPartOfEligibleNBUEGroup 
                    && this.isEngagementTypeforNonISDUsers(this.internalEngagementTypeV2.value.engagementCreationCode)) {
                    const internalEngagementISDUpdateApprovalDetails: IInternalEngagementApprovalDetails =  
                        await this.newInternalEngagementService.updateApprovalDetailsForInternalEngagement(approvalIdFromForm, internalEngagementApprovalUpdate);
                }

                const newGrmProjectCreationObject: IGrmProjectCreateFunctionRequest[] = await this.getGrmCreationInstanceV2(internalEngagementInputObjectV2, internalEngagementOutputV2, addProjectsComponentListVar);
                this.loadingText = `Adding Roles to Engagement: "${internalEngagementOutputV2.name}"`;
                for (const grmRequestObject of newGrmProjectCreationObject) {
                    const propertyBag = {
                        engagementType: this.internalEngagementTypeV2.value.typeDescription,
                        engagementId: internalEngagementOutputV2.id,
                        grmApiInput: JSON.stringify(grmRequestObject)
                    };
                    await this.projectServiceFunctions.createInternalProjectToGrm(grmRequestObject)
                        .then(() => {
                            this.fxpMessageService.addMessage("Successfully created GRM projects for " + internalEngagementOutputV2.id, this.FXP_CONSTANTS.messageType.success, false);
                            this.experienceResult = true;
                        }).catch((serviceError: Promise<any>) => {
                            this.fxpMessageService.addMessage(DmError.InternalEngagement.ErrorsInResourceRequest, this.FXP_CONSTANTS.messageType.warning, true);
                            this.dmLogger.logError(ComponentPrefix + this.component.userFriendlyName, SourceConstants.Method.CreateInternalEngagement, serviceError, DmError.InternalEngagement.ErrorsInResourceRequest, null, undefined, DataService.getCorrelationIdFromError(serviceError), ErrorSeverityLevel && ErrorSeverityLevel.High);
                            this.dmLogger.logEvent(SourceConstants.Component.NewInternalEngagementV2, SourceConstants.Method.CreateInternalEngagement, LogEventName.InternalEngagementGrmProjectionCreationFailure, propertyBag);
                            this.faultControlService.logGrmCreateInternalProjectFault(serviceError);
                        });
                }
                this.showLoading = false;
                this.isComponentLoading = false;
                this.isDisabledCreateEngagement = false;
                this.goBackToPreviousScreen();
            }).catch((error: Promise<any>) => {
                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                this.logError(SourceConstants.Method.CreateInternalEngagement, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                this.showLoading = false;
                this.isComponentLoading = false;
                this.isDisabledCreateEngagement = false;
                setTimeout(() => {
                    this.setExchangeRateForProjects();
                }, 500);
            });
        }
        // Todo : set component loading
    }


    /**
     * Updates value of adpjm control when deletion occurs from multiselect typeahead.
     *
     * @param {ISelectedUserAttributes[]} selectedAdpjms
     * @memberof AddInternalEngagementProjectComponent
     */
    public onAdppjmUpdate(selectedAdppjms: ISelectedUserAttributes[]): void {
        this.internalEngagementAdPPJMList.setValue(selectedAdppjms);
        this.additionalPjmsList = selectedAdppjms;
        this.additionalProjectManagers = selectedAdppjms;
    }

    /**
     * called when the component get destroyed and end the feature usage event
     */
    public ngOnDestroy(): void {
        this.dmLogger.endFeatureUsageEvent(SourceConstants.Component.NewInternalEngagementV2, SourceConstants.Method.CreateInternalEngagement, this.feature, this.experienceResult);
    }

    /**
     * Set the read only property of the customer typeahead based on the engagement type.
     *
     * @param {IInternalEngagementCreationCode} engagementType
     * @memberof NewInternalEngagementComponentV2
     */
    private setCustomerFieldReadOnlyByEngType(engagementType: IInternalEngagementCreationCode): void {
        if (engagementType && engagementType.typeDescription === InternalEngagementType.demandGeneric ||
            engagementType.typeDescription === InternalEngagementType.sharedResource ||
            engagementType.typeDescription === InternalEngagementType.msInternal ||
            engagementType.typeDescription === InternalEngagementType.unitBased) {
            this.isCustomerNameReadOnly = true;
        } else {
            this.isCustomerNameReadOnly = false;
        }
    }

    /**
     * Adds a project to internal engagement.
     *
     * @memberof NewInternalEngagementComponentV2
     */
    private addProject(): void {
        const project = { id: ++this.projectIndex };
        this.internalEngagementProjects.push(project);
        if (this.internalEngagementProjects.length) {
            this.showProjectsValidationMessage = false;
            this.isDisabledCreateEngagement = false;
        }
    }

    /**
     * Validate customer/tpid based on engagment type. Required for pre-sales, pre-qual, and demandGen-account.
     * Optional for SROut.
     *
     * @returns {boolean}
     * @memberof NewInternalEngagementComponentV2
     */
    private validateTpid(): boolean {
        const isTpidRequired: boolean = this.internalEngagementTypeV2.value.typeDescription === InternalEngagementType.demandAccount;

        if (isTpidRequired && !this.customerName) {
            this.showTpidValidationMessage = true;
            this.tpidErrorMsg = DmError.InternalEngagement.CustomerRequired;
            return false;
        } else if (isTpidRequired && !this.tpid) {
            this.showTpidValidationMessage = true;
            this.tpidErrorMsg = DmError.InternalEngagement.TPIDRequired;
            return false;
        } else {
            this.showTpidValidationMessage = false;
            return true;
        }
    }

    private setErrorMessage(key: string, apiError: string, input: AbstractControl, errorDetails: any): void {
        if (key && errorDetails && errorDetails[apiError] && input) {
            input.setErrors({ [key]: errorDetails[apiError] });
        }
    }

    /**
     * Get the list of Cities for a State
     * @param countryCode
     * @param stateName
     */
    private getCities(countryCode: string, stateName: string): void {
        this.showStateValidationMessage = false;
        this.isCityCtrlLoading = true;
        this.selectedCity.CityName = "";
        const cacheKey = CacheKeys.Cities.KeyName + countryCode + stateName;
        this.cacheService.get(cacheKey, () => this.oneProfileService.getCitiesBasedOnCountryCodeStateName(countryCode, stateName), CacheKeys.Cities.Duration)
            .then((response: ICityDetails[]) => {
                this.isCityCtrlLoading = false;
                if (response.length > 4000) {
                    this.showCityTypeAhead = true;
                } else {
                    this.showCityTypeAhead = false;
                    this.cityList = response;
                    this.cityList.unshift({ CityName: "Select" });
                    this.selectedCity = this.cityList[0];
                }
                this.isCityDisabled = false;
                this.internalEngagementFormControl.deliveryDetails.controls.internalEngagementCustomerCity.enable();
            });
    }

    /**
     * Move the focus to element for accessibility tooling
     * @param id
     * @param isTimeOut
     */
    private focus(id: string, isTimeOut: boolean, timer: number = 0): void {
        if (isTimeOut) {
            setTimeout(() => {
                const element: HTMLElement = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                    element.focus();
                }
            }, timer);
        } else {
            const element = window.document.getElementById(id);
            if (element) {
                element.focus();
            }
        }
    }

    /**
     * Get request object for GRM project creation function
     * @param internalEngagementInputObjectV2
     * @param internalEngagementOutputV2
     * @param addInternalEngagementProjectComponentVar
     */
    private async getGrmCreationInstanceV2(internalEngagementInputObjectV2: INewInternalEngagementInput, internalEngagementOutputV2: INewCreateInternalEngagementOutput, addInternalEngagementProjectComponentVar: AddInternalEngagementProjectComponent[]): Promise<IGrmProjectCreateFunctionRequest[]> {
        const newRoles: IGrmProjectCreateFunctionRequest[] = [];
        let i = 0;
        for (const project of internalEngagementOutputV2.projects) {
            const projectFromComponent = addInternalEngagementProjectComponentVar.filter((addProjectComponent) => {
                if (addProjectComponent.projectIndex.toString() === project.temporaryId) {
                    return addProjectComponent;
                }
            })[0];

            if (projectFromComponent) { /* Null check in case there is no matc  h */
                const newRole: IGrmProjectCreateFunctionRequest = {
                    engagementInfo: this.getEngagementInfo(internalEngagementInputObjectV2, internalEngagementOutputV2),
                    projectDetail: projectFromComponent.getGrmProjectCreationObject(),
                    roleDetail: await projectFromComponent.getGrmResourceRequests(),
                    projectResponse: internalEngagementOutputV2.projects[i],
                    shouldDisableSystemNotification: false
                };
                newRoles.push(newRole);
            }
            i++;
        }

        return newRoles; /* Will return empty if no matches foun  d */
    }

    /**
     * Get Engagement Info for GRM project creation function
     * @param internalEngagementInputObjectV2
     * @param internalEngagementOutputV2
     */
    private getEngagementInfo(internalEngagementInputObjectV2: INewInternalEngagementInput, internalEngagementOutputV2: INewCreateInternalEngagementOutput): IInternalEngagementInfo {
        const engagementInfo: IInternalEngagementInfo = {
            id: internalEngagementOutputV2.id,
            name: internalEngagementOutputV2.name,
            countryCode: this.internalEngagementCustomerCountry.value.CountryCode,
            state: internalEngagementInputObjectV2.customerState,
            city: internalEngagementInputObjectV2.customerCity,
            isConfidential: internalEngagementInputObjectV2.isConfidential,
            requestTypeCode: this.internalEngagementTypeV2.value.engagementCreationCode.toString(),
            customerName: internalEngagementInputObjectV2.customerName,
            customerId: internalEngagementInputObjectV2.customerId
        };
        return engagementInfo;
    }

    /**
     * Validates project start date and project end date.
     *
     * @memberof AddInternalEngagementProjectComponent
     */
    private validateDates(): void {
        this.internalEngagementEndDate.setErrors(null);
        if (this.internalEngagementEndDate.value && this.internalEngagementStartDate.value && moment(this.internalEngagementStartDate.value).isAfter(this.internalEngagementEndDate.value)) {
            this.internalEngagementEndDate.setErrors({ startDateGreaterThanEndDateError: DmError.InternalEngagement.EndDateCannotBeLessThanStartDate });
        }
    }

    /**
     * Initializes the new internal engagement form to default values.
     *
     * @private
     * @memberof NewInternalEngagementComponentV2
     */
    private initializeForm(): Promise<void> {
        return this.configManagerService.initialize().then(() => {
            this.setLoadersBasedOnItemState();
            this.companyCodeList = this.configManagerService.getValue<IOneProfileCompanyCodeAttrV2[]>("companyCodeList");
            this.internalEngagementTypes = this.configManagerService.getValue<IInternalEngagementCreationCode[]>("internalEngagementCreationCodes");
            this.internalEngagementTypes = this.internalEngagementTypes.filter((type: IInternalEngagementCreationCode) => (type.engagementCreationCode !== "91" && type.engagementCreationCode !== "92"));
            // this.internalEngagementType = this.internalEngagementTypes[0];
            this.clearedRolesCompanyCodes = this.configManagerService.getValue<string[]>("clearedRolesCompanyCodes");
            this.internalEngagementDomains = this.configManagerService.getValue<IDomainValues[]>("internalEngagementDomains");
            this.internalEngagementDomain = internalEngagementDomainValue;
            this.saossoGroupId = this.configManagerService.getValue<string>("SAOSSTGroupId");
            this.internalEngagementNBUEGroupIdFromConfig = this.configManagerService.getValue<string>("InternalEngagementNBUEGroupId");
            this.isResourceScheduleFlowEnabled = this.configManagerService.isFeatureEnabled("enableResourceScheduleFlow");
            this.federalEngagementFeatureEnabled = this.configManagerService.isFeatureEnabled("showMSFederalEngagements");
            if (!this.federalEngagementFeatureEnabled) {
                this.internalEngagementTypes = this.internalEngagementTypes.filter((engType) => !this.sharedFunctionsService.isFederalSrOutEngagementBasedOnCreationCode(engType.engagementCreationCode.toString()));
            }
            
            this.isUserInISDHierarchy = true;
            this.isUserPartOfEligibleNBUEGroup = false;
            this.internalEngagementTypesTemp = this.internalEngagementTypes;
            // this.internalEngagementTypes = this.internalEngagementTypes.filter((engType) => !this.isEngagementTypeforNonISDUsers(engType.engagementCreationCode.toString()));
            const userAlias = this.sharedFunctionsService.getCurrentUserInfoAsSelectedUserAttr().userAlias;
            this.projectServiceFunctions.checkUserHierarchy(userAlias).then((isUserInISDHierarchy) => {
                this.isUserInISDHierarchy = isUserInISDHierarchy;
                if (isUserInISDHierarchy) {
                    this.verifyIsUserPartOfInternalEngagementNBUEGroup(userAlias).then((isISDUserPartOfInternalEngagementNBUEGroup) => {
                        if (isISDUserPartOfInternalEngagementNBUEGroup && isISDUserPartOfInternalEngagementNBUEGroup === "true") {
                            this.isUserPartOfEligibleNBUEGroup = true;
                        }
                        if (!this.isUserPartOfEligibleNBUEGroup) {
                            this.internalEngagementTypes = this.internalEngagementTypes.filter((engType) => !this.isEngagementTypeforNonISDUsers(engType.engagementCreationCode.toString()));
                        }
                    });
                }
                if (!this.isUserInISDHierarchy || (this.isUserInISDHierarchy && this.isUserPartOfEligibleNBUEGroup)) {
                    this.internalEngagementTypes = this.internalEngagementTypesTemp;
                }
            }).catch((error: Promise<any>) => {
                this.isUserInISDHierarchy = true;
                this.isUserPartOfEligibleNBUEGroup = false;
                const errorMessage = DmError.EbsRequestChange.ErrorOccuredInGettingProgressRequest;
                this.logError(SourceConstants.Method.ProjectClosureInProgressRequestCheck, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
            });
            const todaysDate = new Date();
            const oneYearFromToday = new Date().setFullYear(todaysDate.getFullYear() + 1);

            this.createIntEngForm = this.fb.group({
                engagementDetails: this.fb.group({
                    internalEngagementNameV2: ["", [Validators.required, Validators.minLength(3), Validators.maxLength(40)]],
                    internalEngagementDescriptionV2: ["", [Validators.maxLength(255)]],
                    internalEngagementTypeV2: ["", Validators.required],
                    internalEngagementDomainV2: [internalEngagementDomainValue],
                    internalEngagementApprovalIdV2: ["", [Validators.maxLength(40)]],
                    internalEngagementStartDate: [todaysDate, [Validators.required]],
                    internalEngagementEndDate: [oneYearFromToday, Validators.required],
                    internalEngagementCustomerName: [null, Validators.required],
                    isCustomerConfidential: [false]
                }),
                deliveryDetails: this.fb.group({
                    internalEngagementCustomerCountry: [{ disabled: this.countryList.length === 0 }, [Validators.required, CountryStateCityDropDownValidator()]],
                    internalEngagementCustomerState: [{ value: "", disabled: this.isStateDisabled }, [Validators.required, CountryStateCityDropDownValidator()]],
                    internalEngagementCustomerCity: [{ value: "", disabled: this.isCityDisabled }, [Validators.required, CountryStateCityDropDownValidator()]],
                }),
                financialDetails: this.fb.group({
                    internalEngagementCompanyCode: [null, [Validators.required, CompanyCodeValidator()]],
                }),
                ownersDetails: this.fb.group({
                    internalEngagementPPJM: [this.isBulkUploadProcess ? null : this.sharedFunctionsService.getCurrentUserInfoAsSelectedUserAttr(), Validators.required],
                    internalEngagementAdPPJMList: [null]
                })
            });

            if (!this.isBulkUploadProcess) {
                this.addProject();
            }

            if (this.internalEngagementTypeV2.value.typeDescription === "Demand Gen-Generic") {
                this.internalEngagementCustomerName.setValue(this.microsoftCustomer); // Default Customer for demand generic is Microsoft
                this.isCustomerNameReadOnly = true;
            }

            this.cacheService.get(CacheKeys.Countries.KeyName, () => this.oneProfileService.getCountries(), CacheKeys.Countries.Duration).then((countriesResponse: ICountryDetails[]) => {
                this.countryList = [...countriesResponse];
                this.countryList.unshift({ CountryCode: "0", CountryName: "Select" });
                this.internalEngagementCustomerCountry.setValue(this.countryList[0]);
                this.stateList.unshift({ StateCode: "0", StateName: "Select" });
                this.internalEngagementCustomerState.setValue(this.stateList[0]);
                this.isStateDisabled = true;
                this.cityList.unshift({ CityName: "Select" });
                this.internalEngagementCustomerCity.setValue(this.cityList[0]);
                this.isCityDisabled = true;
                this.internalEngagementFormControl.deliveryDetails.controls.internalEngagementCustomerState.disable();
                this.internalEngagementFormControl.deliveryDetails.controls.internalEngagementCustomerCity.disable();
            }).catch((error) => {
                this.fxpMessageService.addMessage(DmError.InternalEngagement.FailedToRetrieveCountriesList, this.FXP_CONSTANTS.messageType.error);
                this.logError(SourceConstants.Method.InitializeForm, error, DmError.InternalEngagement.FailedToRetrieveCountriesList, ErrorSeverityLevel && ErrorSeverityLevel.High);
            });
            if (this.isBulkUploadProcess) {
                this.disableIntEngSection = true; // Initially disabling the section if it is a bulk upload process
                this.hasProjectError = false;
            }
            this.showEngagementDetailsSection = true;  // need to always set to default for any validation errors to properly render
        });
    }

    private verifyIsUserPartOfSSOSSTGroup(): Promise<string> {
        const apiInput: IUserMembership[] = [];
        const userAlias = this.fxpUserInfoService.getCurrentUser();
        apiInput.push({
            alias: userAlias,
            groupIds: [this.saossoGroupId]
        });
        const cacheKey = CacheKeys.UserMembership.KeyName + JSON.stringify(apiInput);
        return this.cacheService.get(cacheKey, () => this.projectServiceFunctions.checkUserGroupMemberShip(apiInput), CacheKeys.UserMembership.Duration)
            .then((result: ICheckUserMembershipResponse[]) => {
                if (result.length > 0 && result[0].GroupMemberships && result[0].GroupMemberships.length > 0 && result[0].GroupMemberships.filter((group) => group.IsMember).length > 0) {
                    return Promise.resolve(SSOSSTGroupName);
                }
                return Promise.resolve(undefined);
            }).catch((error) => {
                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                this.logError(SourceConstants.Method.VerifyIsUserPartOfSSOSSTGroup, error, errorMessage, ErrorSeverityLevel && ErrorSeverityLevel.High);
                Promise.resolve(undefined);
            });
    }

    private verifyIsUserPartOfInternalEngagementNBUEGroup(userAlias: string): Promise<string> {
        const apiInput: IUserMembership[] = [];
        // const userAlias = this.fxpUserInfoService.getCurrentUser();
        // const userAlias = this.sharedFunctionsService.getCurrentUserInfoAsSelectedUserAttr().userAlias;
        apiInput.push({
            alias: userAlias,
            groupIds: [this.internalEngagementNBUEGroupIdFromConfig]
        });
        const cacheKey = CacheKeys.UserMembership.KeyName + JSON.stringify(apiInput);
        return this.cacheService.get(cacheKey, () => this.projectServiceFunctions.checkUserGroupMemberShip(apiInput), CacheKeys.UserMembership.Duration)
            .then((result: ICheckUserMembershipResponse[]) => {
                if (result.length > 0 && result[0].GroupMemberships && result[0].GroupMemberships.length > 0 && result[0].GroupMemberships.filter((group) => group.IsMember).length > 0) {
                    this.isUserPartOfEligibleNBUEGroup = true;
                    return Promise.resolve("true");
                }
                return Promise.resolve(undefined);
            }).catch((error) => {
                const errorMessage = this.sharedFunctionsService.getErrorMessage(error, "");
                this.logError(SourceConstants.Method.VerifyIsUserPartOfInternalEngagementNBUEGroup, error, errorMessage, "3");
                Promise.resolve(undefined);
            });
    }

    /**
     * 7 engagement type available only for non-ISD users
     * @param {string} engagementCode
     * @memberof SharedFunctionsService
     */
    private isEngagementTypeforNonISDUsers(engagementCode: string): boolean {
        return InternalEngagementCreationCodesForNonISDUsers.indexOf(engagementCode) > -1;
    }
}
