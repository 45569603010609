<dm-tile class="col-md-12 col-sm-12 col-lg-12 dm-p-10" [tileHeaderContent]="tileContent"
    [tileMinHeight]="'calc(100vh - 125px)'">
    <dm-loading loader [isCustomText]="true" [loadingText]="loadingText" [showLoading]="showLoading"
        *ngIf="isComponentLoading"></dm-loading>
    <div *ngIf="showNewIntEngForm && !isComponentLoading" class="dm-new-int-engagement">
        <p class="font-caption-alt">
            All fields are required unless otherwise specified.
        </p>
        <form *ngIf="createIntEngForm" #createEngagement="ngForm" [formGroup]="createIntEngForm">
            <!--novalidate-->
            <button id="engagementDetailsSectionToggleButton"
                class="col-md-12 col-sm-12 col-xs-12 dm-p-x-imp-0 section-header font-caption-alt text-left set-focus"
                type="button" [attr.aria-expanded]="showEngagementDetailsSection"
                (click)="toggleSection('engagementDetailsSection', 'engagementDetailsSectionToggleButton')">
                <i class="icon icon-chevron-down split-btn" aria-hidden="true"
                    [ngClass]="{'icon-chevron-up': !showEngagementDetailsSection, 'icon-chevron-down': showEngagementDetailsSection}">
                </i>
                <span class="section-header-title">Engagement Details</span>
                <span class="icon icon-exclamation icon-rounded dm-error"
                    *ngIf="internalEngagementFormControl.engagementDetails?.invalid && isBulkUploadProcess"></span>
            </button>
            <section *ngIf="showEngagementDetailsSection"
                class="col-md-12 col-sm-12 col-xs-12 dm-p-x-imp-0 engagement-details-section"
                formGroupName="engagementDetails" [ngClass]="{'disp-flex' : !deviceFactory.isMobile()}">
                <section class="col-sm-9 dm-p-l-imp-0">
                    <section>
                        <section class="col-sm-12 dm-p-l-imp-0">
                            <section class="col-sm-4 dm-p-l-imp-0">
                                <section class="engagement-name-section">
                                    <label class="font-caption" for="internalEngagementName">
                                        <span>Engagement Name</span>
                                        <span class="font-label">(max 40 characters)</span>
                                    </label>
                                    <input class="font-caption-alt dm-input" type="text"
                                        formControlName="internalEngagementNameV2" maxlength="40"
                                        [ngClass]="{'input-error-border': !(internalEngagementNameV2?.dirty || internalEngagementNameV2?.touched) && (internalEngagementNameV2?.errors?.engagementNameApiError)}"
                                        id="internalEngagementName" name="internalEngagementNameV2"
                                        aria-label="Engagement name" placeholder="Engagement Name">
                                    <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                        aria-live="polite"
                                        *ngIf="(internalEngagementNameV2?.dirty || internalEngagementNameV2?.touched) && internalEngagementNameV2?.errors?.minlength">
                                        {{internalEngagementErrorMessages.MinimumCharacterLength}}
                                    </p>
                                    <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                        aria-live="polite"
                                        *ngIf="(internalEngagementNameV2?.dirty || internalEngagementNameV2?.touched) && internalEngagementNameV2?.errors?.maxlength">
                                        {{internalEngagementErrorMessages.EngagementNameMaximumCharacterLength}}
                                    </p>
                                    <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                        aria-live="polite"
                                        *ngIf="(internalEngagementNameV2?.dirty || internalEngagementNameV2?.touched) && internalEngagementNameV2?.errors?.required">
                                        {{internalEngagementErrorMessages.EngagementNameIsRequired}}</p>
                                    <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                        aria-live="polite"
                                        *ngIf="!(internalEngagementNameV2?.dirty || internalEngagementNameV2?.touched) && (internalEngagementNameV2?.errors?.engagementNameApiError)">
                                        {{internalEngagementNameV2?.errors?.engagementNameApiError}}</p>
                                </section>
                            </section>
                            <section class="col-sm-4 date-section dm-p-l-imp-0">
                                <section class="disp-inline-block dm-p-x-imp-0">
                                    <section class="dm-datepicker mr-l-0">
                                        <label class="font-caption" for="newStartDate">
                                            Start Date
                                        </label>
                                        <dm-datepicker id="startDate" name="startDate"
                                            formControlName="internalEngagementStartDate"
                                            [startDate]="internalEngagementStartDate.value"
                                            [modelDate]="internalEngagementStartDate.value" ariaLabelButton="Start date"
                                            [placeholder]="'dd-mm-yyyy'" ariaLabelCalendar="Start date"
                                            (onDateChange)="selectEngagementStartDate($event)"
                                            previosFousingElementId="internalEngagementName">
                                        </dm-datepicker>
                                    </section>
                                    <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                        aria-live="polite"
                                        *ngIf="(internalEngagementStartDate?.dirty || internalEngagementStartDate?.touched) && internalEngagementStartDate?.errors?.required">
                                        {{internalEngagementErrorMessages.EngagementStartDateIsRequired}}</p>
                                    <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                        aria-live="polite">
                                        {{engagementStartDateValidationMessage}}</p>
                                    <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                        aria-live="polite"
                                        *ngIf="!(internalEngagementStartDate?.dirty || internalEngagementStartDate?.touched) && (bulkUploadIntEngInitData?.errorDetails?.startDate)">
                                        {{bulkUploadIntEngInitData?.errorDetails?.startDate}}</p>
                                </section>
                                <section class="disp-inline-block dm-p-x-imp-0">
                                    <section class="dm-datepicker mr-l-0">
                                        <label class="font-caption" for="newEndDate">
                                            End Date
                                        </label>
                                        <dm-datepicker id="endDate" name="endDate"
                                            [minDate]="internalEngagementStartDate.value"
                                            [startDate]="internalEngagementEndDate.value"
                                            [modelDate]="internalEngagementEndDate.value"
                                            formControlName="internalEngagementEndDate" [placeholder]="'dd-mm-yyyy'"
                                            ariaLabelButton="End date" ariaLabelCalendar="End date"
                                            (onDateChange)="selectEngagementEndDate($event)"
                                            previosFousingElementId="calenderBtn_Start date">
                                        </dm-datepicker>
                                    </section>
                                    <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                        aria-live="polite"
                                        *ngIf="(internalEngagementEndDate?.dirty || internalEngagementEndDate?.touched) && internalEngagementEndDate?.errors?.required">
                                        {{internalEngagementErrorMessages.EngagementEndDateIsRequired}}</p>
                                    <!-- <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                          aria-live="polite">
                          {{engagementEndDateValidationMessage}}</p> -->
                                    <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                        *ngIf="(internalEngagementEndDate?.dirty || internalEngagementEndDate?.touched) && internalEngagementEndDate?.errors?.startDateGreaterThanEndDateError">
                                        {{internalEngagementEndDate?.errors?.startDateGreaterThanEndDateError}}</p>
                                    <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                        aria-live="polite"
                                        *ngIf="!(internalEngagementEndDate?.dirty || internalEngagementEndDate?.touched) && internalEngagementEndDate?.errors?.endDateApiError">
                                        {{internalEngagementEndDate?.errors?.endDateApiError}}</p>
                                </section>
                            </section>
                        </section>
                        <section class="col-sm-12 dm-p-l-imp-0">
                            <section class="col-sm-8 dm-p-l-imp-0">
                                <label class="font-caption" for="internalEngagementDescription">
                                    Description
                                    <span class="font-label">(optional, max 255 characters)</span>
                                </label>
                                <textarea class="font-caption-alt dm-p-10 dm-textarea dm-description" type="text"
                                    id="internalEngagementDescription"
                                    [ngClass]="{'input-error-border': !(internalEngagementDescriptionV2?.dirty || internalEngagementDescriptionV2?.touched) && (bulkUploadIntEngInitData?.errorDetails?.description)}"
                                    name=" internalEngagementDescription" placeholder="Description" maxlength="255"
                                    formControlName="internalEngagementDescriptionV2"
                                    aria-label="Engagement Description (optional, max 255 characters)"></textarea>
                                <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                    aria-live="polite"
                                    *ngIf="(internalEngagementDescriptionV2?.dirty || internalEngagementDescriptionV2?.touched) && internalEngagementDescriptionV2?.errors?.maxlength">
                                    {{internalEngagementErrorMessages.EngagementDescriptionMaximumCharacterLength}}
                                </p>
                                <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                    aria-live="polite"
                                    *ngIf="!(internalEngagementDescriptionV2?.dirty || internalEngagementDescriptionV2?.touched) && (bulkUploadIntEngInitData?.errorDetails?.description)">
                                    {{bulkUploadIntEngInitData?.errorDetails?.description}}</p>
                            </section>
                        </section>
                        <section class="col-sm-12 dm-p-l-imp-0">
                            <section class="col-sm-4 dm-p-l-imp-0">
                                <label class="font-caption" for="engagementType">Engagement Type</label>
                                <dm-dropdown-menu class="dm-m-r-20"
                                    id="'internalEngagementTypesListDropdown'-{{internalEngagementTypeV2?.value}}"
                                    name="engagementType" [ariaLabel]="'internal Engagement Type Dropdown'"
                                    [ngClass]="{'input-error-border': !(internalEngagementTypeV2?.dirty || internalEngagementTypeV2?.touched) && (bulkUploadIntEngInitData?.errorDetails?.requestType)}"
                                    [options]="internalEngagementTypes" formControlName="internalEngagementTypeV2"
                                    [labels]="'typeDescription'"
                                    (change)="onInternalEngagementTypeChange(internalEngagementTypeV2?.value)">
                                    <option [value]="placeholder">Select Engagement Type</option>
                                </dm-dropdown-menu>
                                <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                    aria-live="polite"
                                    *ngIf=" (internalEngagementTypeV2?.dirty || internalEngagementTypeV2?.touched) && internalEngagementTypeV2?.errors?.required">
                                    {{internalEngagementErrorMessages.EngagementTypeIsRequired}}</p>
                                <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                    aria-live="polite"
                                    *ngIf=" !(internalEngagementTypeV2?.dirty || internalEngagementTypeV2?.touched) && (bulkUploadIntEngInitData?.errorDetails?.requestType)">
                                    {{bulkUploadIntEngInitData?.errorDetails?.requestType}}</p>
                            </section>
                            <section class="col-sm-4 dm-p-l-imp-0" *ngIf="false">
                                <label class="font-caption" for="domainName">Solution Area <span class="required-mark" aria-hidden="true">*</span></label>
                                <dm-dropdown-menu
                                    id="'internalEngagementDomainListDropdown'-{{internalEngagementDomainV2?.value?.mddDomainValueId}}"
                                    name="domainName" class="dm-m-r-20" [ariaLabel]="'engagement Domain List Dropdown'"
                                    [ngClass]="{'input-error-border': !(internalEngagementDomainV2?.dirty || internalEngagementDomainV2?.touched) && (bulkUploadIntEngInitData?.errorDetails?.primaryDomain)}"
                                    [options]="internalEngagementDomains" formControlName="internalEngagementDomainV2"
                                    [labels]="'domainValueName'" [isRequired]="true">
                                </dm-dropdown-menu>
                                <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                    aria-live="polite"
                                    *ngIf="(internalEngagementDomainV2?.dirty || internalEngagementDomainV2?.touched) && internalEngagementDomainV2?.errors?.required">
                                    {{internalEngagementErrorMessages.EngagementDomainIsRequired}}</p>
                                <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                    aria-live="polite"
                                    *ngIf="!(internalEngagementDomainV2?.dirty || internalEngagementDomainV2?.touched) && (bulkUploadIntEngInitData?.errorDetails?.primaryDomain)">
                                    {{bulkUploadIntEngInitData?.errorDetails?.primaryDomain}}</p>
                            </section>
                            <section class="col-sm-4 dm-p-l-imp-0" *ngIf="isUserInISDHierarchy && !isBulkUploadProcess">
                                <div role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite" [innerHTML]="internalEngagementErrorMessages.InternalEngagementISDUserMessage">
                                <b>Be strong</b></div>
                            </section>
                        </section>
                        <section class="col-sm-12 dm-p-l-imp-0"  *ngIf="isUserInISDHierarchy && !isBulkUploadProcess && isUserPartOfEligibleNBUEGroup && showApprovalIdInput">
                            <section class="col-sm-4 dm-p-l-imp-0">
                                    <label class="font-caption" for="internalEngagementApprovalId">
                                        <span>Approval Id </span>
                                        <span class="required-mark" aria-hidden="true" >*</span>
                                    </label>
                                    <input class="font-caption-alt dm-input" type="text"
                                            formControlName="internalEngagementApprovalIdV2" maxlength="40"
                                            id="internalEngagementApprovalId" name="internalEngagementApprovalIdV2"
                                            aria-label="Approval Id" placeholder="Approval Id">
                                    <section class="engagement-name-section">
                                        <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                        aria-live="polite">
                                        {{nbueApprovedHoursValidationMessages}}</p>
                                    </section>
                            </section>
                        </section>
                        <section class="col-sm-12 dm-p-l-imp-0">
                            <section class="col-sm-4 dm-p-l-imp-0">
                                <section class="input__label display-grid">
                                    <label class="input__label--text font-caption" for="customerName">
                                        Customer (TPID or Name)
                                    </label>
                                    <dm-type-ahead-tpid [typeAheadId]="'customerName'"
                                        [ngClass]="{'input-error-border': !(internalEngagementCustomerName?.dirty || internalEngagementCustomerName?.touched) && (bulkUploadIntEngInitData?.errorDetails?.customerName)}"
                                        [btnSearchAriaLabelText]="customerSearchAriaLblTxt" [typeAheadLabelText]=""
                                        [btnCancelAriaLabelText]="customerCancelAriaLblTxt" [noResults]="noResults"
                                        [selectedCustomer]="selectedCustomer"
                                        (selectedCustomerUpdated)="selectedCustomerUpdated($event)" [isRequired]="true"
                                        [typeaheadMinLength]="3" [isDisabled]="isCustomerNameReadOnly"
                                        formControlName="internalEngagementCustomerName">
                                    </dm-type-ahead-tpid>
                                    <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                        aria-live="polite" *ngIf="showTpidValidationMessage">
                                        {{tpidErrorMsg}}
                                    </p>
                                    <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                        aria-live="polite"
                                        *ngIf="(internalEngagementCustomerName?.dirty || internalEngagementCustomerName?.touched) && internalEngagementCustomerName?.errors?.required">
                                        {{internalEngagementErrorMessages.CustomerNameIsRequired}}</p>
                                    <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                        aria-live="polite"
                                        *ngIf="!(internalEngagementCustomerName?.dirty || internalEngagementCustomerName?.touched) && (internalEngagementCustomerName?.errors?.customerError)">
                                        {{internalEngagementCustomerName?.errors?.customerError}}</p>
                                </section>
                            </section>
                            <section class="col-sm-4 dm-p-l-imp-0">
                                <section class="input__label confidential-section">
                                    <label class="input__label--text input__label--d-b font-caption" for="confidential">
                                        Confidential
                                    </label>
                                    <div class="slide-toggle">
                                        <input type="checkbox" id="confidential" name="confidential"
                                            formControlName="isCustomerConfidential" disabled>
                                        <span class="slide-toggle-slider"></span>
                                        <span
                                            class="input__label--text slide-toggle-text">{{isCustomerConfidential.value
                                            ? "On" : "Off"}}</span>
                                    </div>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
            <!--Delivery Details-->
            <button id="deliveryDetailsSectionToggleButton"
                class="col-md-12 col-sm-12 col-xs-12 section-header font-caption-alt text-left set-focus" type="button"
                [attr.aria-expanded]="showDeliveryDetailsSection"
                (click)="toggleSection('deliveryDetailsSection', 'deliveryDetailsSectionToggleButton')">
                <i class=" icon icon-chevron-down split-btn" aria-hidden="true"
                    [ngClass]="{'icon-chevron-up': !showDeliveryDetailsSection, 'icon-chevron-down': showDeliveryDetailsSection}">
                </i>
                <span class="section-header-title">Delivery</span>
                <span class="icon icon-exclamation icon-rounded dm-error"
                    *ngIf="internalEngagementFormControl.deliveryDetails?.invalid && isBulkUploadProcess"></span>
            </button>
            <section *ngIf="showDeliveryDetailsSection" class="col-sm-9 dm-p-l-imp-0" formGroupName="deliveryDetails">
                <section class="col-sm-12 dm-p-l-imp-0">
                    <section class="col-sm-4 dm-p-l-imp-0">
                        <section class="input__label">
                            <label class="input__label--text input__label--d-b font-caption"
                                for="country">Country</label>
                            <section class="input__select--icon" [ngClass]="{'spin-icon':isCountryCtrlLoading}">
                                <select id="country" name="country"
                                    class="input__select input__select--big font-caption-alt"
                                    [ngClass]="{'input-error-border': !(internalEngagementCustomerCountry?.dirty || internalEngagementCustomerCountry?.touched) && (bulkUploadIntEngInitData?.errorDetails?.customerCountry)}"
                                    formControlName="internalEngagementCustomerCountry"
                                    (change)="onCountryChange(internalEngagementCustomerCountry?.value)">
                                    <option *ngFor="let country of countryList" [ngValue]="country"
                                        [label]="country.CountryName">
                                        {{country.CountryName}}
                                    </option>
                                </select>
                            </section>
                            <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite"
                                *ngIf="(internalEngagementCustomerCountry?.dirty || internalEngagementCustomerCountry?.touched) && internalEngagementCustomerCountry?.value?.CountryName === 'Select'">
                                {{internalEngagementErrorMessages.SelectCountry}}</p>
                            <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite"
                                *ngIf="!(internalEngagementCustomerCountry?.dirty || internalEngagementCustomerCountry?.touched) && (bulkUploadIntEngInitData?.errorDetails?.customerCountry)">
                                {{bulkUploadIntEngInitData?.errorDetails?.customerCountry}}</p>
                        </section>
                    </section>
                    <section class="col-sm-4 dm-p-l-imp-0">
                        <section class="input__label">
                            <label class="input__label--text input__label--d-b font-caption" for="state">
                                State / Province
                            </label>
                            <section class="input__select--icon" [ngClass]="{'spin-icon':isStateCtrlLoading}">
                                <select id="state" name="state"
                                    class="input__select input__select--big font-caption-alt"
                                    [ngClass]="{'input-error-border': !(internalEngagementCustomerState?.dirty || internalEngagementCustomerState?.touched) && (bulkUploadIntEngInitData?.errorDetails?.customerState), 'disabled-class':isStateDisabled}"
                                    formControlName="internalEngagementCustomerState"
                                    (change)="onStateChange(internalEngagementCustomerState.value)">
                                    <option *ngFor="let state of stateList; trackBy: trackByStateName" [ngValue]="state"
                                        [label]="state.StateName">
                                        {{state.StateName}}
                                    </option>
                                </select>
                            </section>
                            <p role="alert" class="font-caption-alt inp-validation error-msg m-t-4" aria-atomic="true"
                                aria-live="polite"
                                *ngIf="(internalEngagementCustomerState?.dirty || internalEngagementCustomerState?.touched) && !hasNoStates && internalEngagementCustomerState?.value?.StateName === 'Select'">
                                {{internalEngagementErrorMessages.SelectState}}
                            </p>
                            <p role="alert" class="font-caption-alt inp-validation error-msg m-t-4" aria-atomic="true"
                                aria-live="polite" *ngIf="internalEngagementCustomerState?.errors?.getStatesListError">
                                {{internalEngagementCustomerState?.errors?.getStatesListError}}
                            </p>
                            <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite"
                                *ngIf="!(internalEngagementCustomerState?.dirty || internalEngagementCustomerState?.touched) && (bulkUploadIntEngInitData?.errorDetails?.customerState) && !hasNoStates">
                                {{bulkUploadIntEngInitData?.errorDetails?.customerState}}</p>
                        </section>
                    </section>
                    <section class="col-sm-4 dm-p-l-imp-0">
                        <section class="input__label">
                            <label class="input__label--text input__label--d-b font-caption" for="city">
                                City
                                <span class="required-mark" aria-hidden="true">*</span>
                            </label>
                            <section class="input__select--icon" *ngIf="!showCityTypeAhead"
                                [ngClass]="{'spin-icon':isCityCtrlLoading}">
                                <select id="city" name="city" class="input__select input__select--big font-caption-alt"
                                    formControlName="internalEngagementCustomerCity"
                                    [ngClass]="{'input-error-border': !(internalEngagementCustomerCity?.dirty || internalEngagementCustomerCity?.touched) && (bulkUploadIntEngInitData?.errorDetails?.customerCity), 'disabled-class':isCityDisabled}">
                                    <option *ngFor="let city of cityList; trackBy: trackByCityName" [ngValue]="city"
                                        [label]="city.CityName">
                                        {{city.CityName}}
                                    </option>
                                </select>
                            </section>
                            <dm-type-ahead-city [typeAheadId]="'cityName'" *ngIf="showCityTypeAhead"
                                [btnSearchAriaLabelText]="citySearchAriaLblTxt" [typeAheadLabelText]=""
                                [btnCancelAriaLabelText]="cityCancelAriaLblTxt" [noResults]="noResults"
                                [selectedCity]="selectedCity.CityName"
                                [selectedCountryCode]="internalEngagementCustomerCountry.value.CountryCode"
                                [selectedStateName]="internalEngagementCustomerState.value.StateName"
                                (selectedCityUpdated)="onCityChange($event)" [isRequired]="true"
                                [typeaheadMinLength]="3" [isDisabled]="isCityDisabled"
                                [modelValue]="selectedCity.CityName" formControlName="internalEngagementCustomerCity">
                            </dm-type-ahead-city>
                            <p role="alert" class="font-caption-alt inp-validation error-msg m-t-4" aria-atomic="true"
                                aria-live="polite"
                                *ngIf="(internalEngagementCustomerCity?.dirty || internalEngagementCustomerCity?.touched) && !internalEngagementCustomerCity?.value?.CityName">
                                {{internalEngagementErrorMessages.SelectCity}}
                            </p>
                            <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite"
                                *ngIf="!(internalEngagementCustomerCity?.dirty || internalEngagementCustomerCity?.touched) && (bulkUploadIntEngInitData?.errorDetails?.customerCity)">
                                {{bulkUploadIntEngInitData?.errorDetails?.customerCity}}</p>
                        </section>
                    </section>
                </section>
            </section>
            <!---end old code to be replaced----->

            <!--Finance Details-->
            <button id="financialDetailsSectionToggleButton"
                class="col-md-12 col-sm-12 col-xs-12 section-header font-caption-alt text-left set-focus" type="button"
                [attr.aria-expanded]="showFinancialDetailsSection"
                (click)="toggleSection('financialDetailsSection', 'financialDetailsSectionToggleButton')">
                <i class=" icon icon-chevron-down split-btn" aria-hidden="true"
                    [ngClass]="{'icon-chevron-up': !showFinancialDetailsSection, 'icon-chevron-down': showFinancialDetailsSection}">
                </i>
                <span class="section-header-title">Finances</span>
                <span class="icon icon-exclamation icon-rounded dm-error"
                    *ngIf="internalEngagementFormControl.financialDetails?.invalid && isBulkUploadProcess"></span>
            </button>
            <section *ngIf="showFinancialDetailsSection" class="col-sm-9 dm-p-l-imp-0 dm-p-b-10"
                formGroupName="financialDetails">
                <section class="col-sm-12 dm-p-l-imp-0">
                    <section class="col-sm-4 dm-p-l-imp-0">
                        <section class="company-code-wrapper input__label dm-p-b-0">
                            <label class="input__label--text input__label--d-b font-caption" for="companyCode">
                                Company Code
                            </label>
                            <section class="input__select--icon">
                                <select id="companyCode" class="input__select input__select--big font-caption-alt"
                                    [ngClass]="{'input-error-border': !(internalEngagementCompanyCode?.dirty || internalEngagementCompanyCode?.touched) && (bulkUploadIntEngInitData?.errorDetails?.company)}"
                                    name="companyCode" formControlName="internalEngagementCompanyCode"
                                    (ngModelChange)="onInternalEngagementCompanyCodeChange()">
                                    <option *ngFor="let company of companyCodeList" [ngValue]="company">
                                        {{ company.companyCode }}-{{ company.companyName }}
                                    </option>
                                </select>
                                <span class="icon select-caret icon-chevron-down pull-right" aria-hidden="true"></span>
                            </section>
                            <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite"
                                *ngIf="(internalEngagementCompanyCode?.dirty || internalEngagementCompanyCode?.touched) && (internalEngagementCompanyCode?.errors?.required || !internalEngagementCompanyCode?.value?.companyCode)">
                                {{internalEngagementErrorMessages.CompanyCodeIsRequired}}</p>
                            <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                                aria-live="polite"
                                *ngIf="(!(internalEngagementCompanyCode?.dirty || internalEngagementCompanyCode?.touched) && bulkUploadIntEngInitData?.errorDetails?.company)">
                                {{bulkUploadIntEngInitData?.errorDetails?.company}}</p>
                        </section>
                    </section>
                </section>
            </section>

            <!--Owners Details Section-->
            <button id="ownersDetailsSectionToggleButton"
                class="col-md-12 col-sm-12 col-xs-12 section-header font-caption-alt text-left set-focus" type="button"
                [attr.aria-expanded]="showOwnersDetailsSection"
                (click)="toggleSection('ownersDetailsSection', 'ownersDetailsSectionToggleButton')">
                <i class=" icon icon-chevron-down split-btn" aria-hidden="true"
                    [ngClass]="{'icon-chevron-up': !showOwnersDetailsSection, 'icon-chevron-down': showOwnersDetailsSection}">
                </i>
                <span class="section-header-title">Owners</span>
                <span class="icon icon-exclamation icon-rounded dm-error"
                    *ngIf="internalEngagementFormControl.ownersDetails?.invalid && isBulkUploadProcess"></span>
            </button>
            <section *ngIf="showOwnersDetailsSection" class="col-sm-9 dm-p-l-imp-0" formGroupName="ownersDetails">
                <section class="col-sm-12 dm-p-l-imp-0">
                    <section class="col-sm-4 dm-p-l-imp-0">
                        <label class="font-caption input__label--text" for="internalEngTypeAheadProjectManager">
                            Primary Project Manager
                            <span class="font-label"></span>
                        </label>
                        <dm-type-ahead [typeAheadId]="'internalEngTypeAheadProjectManager'"
                            [ngClass]="{'input-error-border': !(internalEngagementPPJM?.dirty || internalEngagementPPJM?.touched) && (bulkUploadIntEngInitData?.errorDetails?.ppjm)}"
                            [btnSearchAriaLabelText]="pjmSearchAriaLblTxt" [typeAheadLabelText]=""
                            [btnCancelAriaLabelText]="pjmCancelAriaLblTxt"
                            [typeAheadUserValidationRequiredMessage]="'Project Manager is required'"
                            [noResults]="noResults" [isRequired]="false" [isMultiselect]="false" [fteOnly]="true"
                            [typeaheadMinLength]="3" [ignoredExistingManagers]="additionalProjectManagers"
                            formControlName="internalEngagementPPJM"
                            (selectedUserUpdated)="selectedUserUpdated($event)">
                        </dm-type-ahead>
                        <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                            aria-live="polite"
                            *ngIf="(internalEngagementPPJM?.dirty || internalEngagementPPJM?.touched) && internalEngagementPPJM?.errors?.required">
                            {{internalEngagementErrorMessages.PrimaryProjectManagerIsRequired}}</p>
                        <p role="alert" class="font-caption-alt inp-validation error-msg" aria-atomic="true"
                            aria-live="polite"
                            *ngIf="!(internalEngagementPPJM?.dirty || internalEngagementPPJM?.touched) && (internalEngagementPPJM?.errors?.invalidPpjm)">
                            {{internalEngagementPPJM?.errors?.invalidPpjm}}</p>
                    </section>
                    <section class="col-sm-4 dm-p-l-imp-0">
                        <label class="font-caption input__label--text" for="projectmanagersTypeAheadProject">
                            Additional Project Managers
                            <span class="font-label">(optional)</span>
                        </label>
                        <dm-type-ahead [typeAheadId]="'projectmanagersTypeAheadProject'" [showTypeAheadLabel]="false"
                            [ngClass]="{'input-error-border': !(internalEngagementAdPPJMList?.dirty || internalEngagementAdPPJMList?.touched) && (bulkUploadIntEngInitData?.errorDetails?.adppjm)}"
                            [btnSearchAriaLabelText]="searchAriaLabel" [typeAheadLabelText]="typeAheadLabelText"
                            [btnCancelAriaLabelText]="cancelAriaLabel" [selectedUsers]="additionalProjectManagers"
                            [isRequired]="false" [isMultiselect]="true" [fteOnly]="false"
                            formControlName="internalEngagementAdPPJMList"
                            (multiSelectedUsersUpdated)="onAdppjmUpdate($event)"
                            [ignoredExistingManagers]="[internalEngagementPPJM.value]">
                        </dm-type-ahead>
                    </section>
                    <section class="col-sm-4 dm-p-l-imp-0">
                        <p role="alert" id="adppjmErrorMessage" class="font-caption-alt inp-validation error-msg"
                            aria-atomic="true" aria-live="polite"
                            *ngIf="!(internalEngagementAdPPJMList?.dirty || internalEngagementAdPPJMList?.touched) && (internalEngagementAdPPJMList?.errors?.invalidAdppjm)">
                            {{internalEngagementAdPPJMList?.errors?.invalidAdppjm}}</p>
                    </section>
                </section>
            </section>

            <!--Project Details Section-->
            <button id="projectDetailsSectionToggleButton"
                class="col-md-12 col-sm-12 col-xs-12 section-header font-caption-alt text-left set-focus" type="button"
                [attr.aria-expanded]="showProjectsSection"
                (click)="toggleSection('projectsDetailsSection', 'projectDetailsSectionToggleButton')">
                <i class=" icon icon-chevron-down split-btn" aria-hidden="true"
                    [ngClass]="{'icon-chevron-up': !showProjectsSection, 'icon-chevron-down': showProjectsSection}">
                </i>
                <span class="section-header-title">Projects</span>
                <span class="icon icon-exclamation icon-rounded dm-error"
                    *ngIf="(internalEngagementFormControl?.projectDetails?.invalid || bulkUploadIntEngInitData?.errorDetails?.projects) && isBulkUploadProcess"></span>
            </button>
            <section class="pull-left col-md-12 col-sm-12 col-xs-12 dm-p-x-imp-0"
                [style.display]="showProjectsSection ? 'block' : 'none'">
                <section class="pull-left col-md-12 col-sm-12 col-xs-12 dm-p-x-imp-0 dm-p-y-imp-10">
                    <p role="alert" class="font-caption-alt inp-validation error-msg dm-p-x-imp-10 dm-p-b-10"
                        aria-atomic="true" aria-live="polite"
                        *ngIf="showProjectsValidationMessage || bulkUploadIntEngInitData?.errorDetails?.projects">
                        Add at least one project.
                    </p>
                    <section class="col-md-12 col-sm-12 col-xs-12 dm-p-x-imp-0 display-flex-imp">
                        <button type="button" (click)="addProjectClicked()" class="fxp-btn fxp-btn--primary">
                            <i class="icon-AddLegacy icon"></i>
                            Add Project
                        </button>
                    </section>
                </section>
                <section *ngFor="let project of internalEngagementProjects; let projIndex = index;">
                    <dm-add-internal-engagement-project (onProjectDeleted)="deleteProject($event)"
                        [projectData]="project" [projectIndex]="projIndex + 1"
                        [internalEngagementType]="internalEngagementTypeV2?.value"
                        [isEngagementConfidential]="isCustomerConfidential?.value"
                        [engagementCompany]="internalEngagementCompanyCode?.value"
                        [engagementStartDate]="internalEngagementStartDate?.value"
                        [engagementEndDate]="internalEngagementEndDate?.value">
                    </dm-add-internal-engagement-project>
                </section>
            </section>

            <!--Form footer section-->
            <section class="pull-left col-md-12 col-sm-12 col-xs-12 dm-p-x-imp-0 dm-p-y-imp-10"
                *ngIf="!bulkUploadIntEngInitData">
                <button type="button" (click)="goBackToPreviousScreen()" id="cancelInternalEngagementCreation"
                    class="form-btn fxp-btn fxp-btn--secondary">
                    Cancel
                </button>
                <button type="button" (click)="createInternalEngagement()" [disabled]="isDisabledCreateEngagement"
                    class="form-btn fxp-btn fxp-btn--primary">
                    Create Engagement
                </button>
            </section>
        </form>
    </div>
</dm-tile>
