<dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'300px'" [hasNonOverflowContent]="true" [isV2Tile]="true">
    <dm-loading loader *ngIf="isComponentLoading && !isServerError" [loadingText]="'Breakdown Structure Details'"
        [showLoading]="showLoading">
    </dm-loading>
    <section *ngIf="!isComponentLoading && !isServerError" class="col-md-12 dm-p-imp-0">
        <dm-project-filter-v2 class="dm-wbs-filter" *ngIf="projectDataV2" [sourceDataV2]="projectDataV2"
            [selectedProjectId]="selectedProjectId" [selectedServiceId]="selectedServiceId"
            [selectedTaskId]="selectedTaskId" (updateSelectedProjectId)="setSelectedProjectId($event)"
            (updateSelectedServiceId)="setSelectedServiceId($event)" (updateSelectedTaskId)="setSelectedTaskId($event)">
        </dm-project-filter-v2>
    </section>
    <section *ngIf="!isComponentLoading && !isServerError">
        <div role="grid" aria-label="Engagement Breakdown Structure">
            <div role="rowgroup" class="dm-grid-view-table">
                <div role="row"
                    [ngClass]="{'dm-grid-view-table-v2__row' : areMXDRChangesEnabled, 'dm-grid-view-table__row': !areMXDRChangesEnabled}">
                    <div role="gridcell" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                        <span type="button" role="button" tabindex="0" id="toggleProjects" class="acc-btn"
                            (keyup.enter)="expandCollapseAllProjects()" (keyup.Space)="expandCollapseAllProjects()"
                            (click)="expandCollapseAllProjects()"
                            [title]="showProjectsExpanded ? 'Collapse Projects' : 'Expand Projects'"
                            [attr.aria-expanded]="!showProjectsExpanded">
                            <span class="icon"
                                [ngClass]="showProjectsExpanded ? 'icon-DashKey' : 'icon-AddLegacy'"></span>
                        </span>Name
                    </div>
                    <div role="gridcell" class="dm-grid-view-table__headerCell dm-p-x-12-y-8">
                        Contract <br />
                        Type
                        <ng-template #profileDescContactPopup>
                            <div class="type-dialogBox">
                                <h4 class="type-dialogBox--header font-caption-alt text-left dm-m-b-12">
                                    Contract Types
                                </h4>
                                <ul class="type-dialogBox__ul col-md-12 no-pad font-caption-alt">
                                    <li *ngFor="let data of contractTypeColors"
                                        class="type-dialogBox__ul--li col-md-6 dm-p-l-imp-0 dm-m-t-imp-6">
                                        <div class="col-md-4 dm-p-l-imp-0">
                                            <span class="dm-badge dm-contract-type"
                                                [ngStyle]="data.typeColorCode">{{data.typeShortForm}}</span>
                                        </div>
                                        <div class="col-md-8 text-left v-a-m fullformLbl">
                                            <span>{{data.typeFullForm}}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </ng-template>
                        <dm-tooltip role="tooltip" class="dm-tooltipinline contract-type-tooltip-padding"
                            [dmPlacement]="'bottom'" [ngbTooltipContent]="profileDescContactPopup"
                            [dmIcon]="'tile__icon--info icon icon-info'" ariaLabelToolTip="Contract Type Information"
                            (click)="logClick(LogEventConstants.ContractTypeInfoTooltip)">
                        </dm-tooltip>
                    </div>
                    <div role="gridcell" *ngIf="areMXDRChangesEnabled"
                        class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                        Rec Rev
                        <ng-template #RecRevTypePopup>
                            <div class="type-dialogBox">
                                <h4 class="type-dialogBox--header font-caption-alt text-left dm-p-x-5-y-10">
                                    Rec Rev
                                </h4>
                                <ul class="type-dialogBox__ul col-md-12 no-pad font-caption-alt">
                                    <li *ngFor="let data of recRevTypeList"
                                        class="type-dialogBox__ul--li col-md-6 dm-p-l-imp-0 dm-m-t-imp-6">
                                        <div class="col-md-4 dm-p-l-imp-0">
                                            {{data.typeShortForm}}
                                        </div>
                                        <div class="col-md-8 text-left v-a-m fullformLbl">
                                            <span>{{data.typeFullForm}}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </ng-template>
                        <dm-tooltip role="tooltip" class="dm-tooltipinline dm-tooltipinline-msup-type"
                            [dmPlacement]="'bottom'" [ngbTooltipContent]="RecRevTypePopup"
                            [dmIcon]="'tile__icon--info icon icon-info'"
                            ariaLabelToolTip="Information. press space to view tooltip">
                        </dm-tooltip>
                        <span class="sr-only">press tab to navigate to tooltip button</span>
                    </div>
                    <div role="gridcell" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                        Start Date
                    </div>
                    <div role="gridcell" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                        End Date
                    </div>
                    <div role="gridcell" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8 ebs-state-pad">
                        EBS State
                        <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'bottom'"
                            [ngbTooltipContent]="ebsStateText" [dmIcon]="'tile__icon--info icon icon-info'"
                            ariaLabelToolTip="EBS State Information"
                            (click)="logClick(LogEventConstants.EbsStateInfoTooltip)">
                        </dm-tooltip>
                    </div>
                    <div role="gridcell" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                        Actions
                    </div>
                </div>
            </div>
            <div role="rowgroup" class="dm-grid-view-table">
                <div class="dm-grid-view-table__body entity-info"
                    *ngFor="let project of projectDataV2 | filter: (selectedProjectId ? {id: selectedProjectId} : undefined)">
                    <div role="row"
                        [ngClass]="{'dm-grid-view-table-v2__row' : areMXDRChangesEnabled, 'dm-grid-view-table__row': !areMXDRChangesEnabled}">
                        <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8 name-grid">
                            <span class="sr-only">Name</span>
                            <span class="dm-p-r-10">
                                <span class="icon set-focus" role="button" tabindex="0"
                                    (click)="expandCollapseProjects(project)"
                                    (keyup.enter)="expandCollapseProjects(project)"
                                    (keyup.Space)="expandCollapseProjects(project)"
                                    [title]="project.isExpand ? 'Collapse expanded project' : 'Expand collapsed project'"
                                    [ngClass]="{'icon-ChevronUp': project.isExpand, 'icon-ChevronDown': !project.isExpand}"
                                    [attr.aria-expanded]="project.isExpand?true:false"></span>
                            </span>
                            <div class="display-grid name-section">
                                <span class="display-flex line-height-1">
                                    <a *ngIf="!project.isExternalLink" [uiSref]="RouteName.ProjectSummaryV2"
                                        class="dm-m-r-4 name set-focus" [uiParams]="{projectId: project.id}"
                                        id="{{ 'dm-project-' + project.id }}"
                                        (click)="logClick(LogEventConstants.NavigateToProjectSummaryLinkClicked)">
                                        Project: {{project.name}}
                                    </a>
                                    <dm-tooltip role="tooltip" class="dm-tooltipinline dm-m-r-4 confidentialicon"
                                        *ngIf="project.isConfidentialDeal" [dmPlacement]="'right'"
                                        [ngbTooltipContent]="projConfidentialText"
                                        [dmIcon]="'tile__icon--info confidentialkey'"
                                        ariaLabelToolTip="confidentiality information"
                                        (click)="logClick(LogEventConstants.ConfidentialTooltip)">
                                    </dm-tooltip>
                                    <dm-tooltip role="tooltip" class="dm-tooltipinline pubsec dm-m-r-4"
                                        *ngIf="project.isUsPubSec && isPubSecEnabled && project.isPublicSector"
                                        [dmPlacement]="'right'" [ngbTooltipContent]="pubSecText"
                                        [dmIcon]="'pubsec_icon'" ariaLabelToolTip="pub sec Information"
                                        (click)="logClick(LogEventConstants.PubsecTooltip)">
                                    </dm-tooltip>
                                    <span *ngIf="project.isUsPubSec" class="uspubsec uspubsec_project-desktop dm-m-r-4"
                                        title="US Pubsec"></span>
                                    <span tabindex="0" *ngIf="project?.hasUnitBasedDemands" class="units-icon dm-m-r-4"
                                        title="Units"></span>
                                    <span tabindex="0" *ngIf="project?.isMarkedForDeletion"
                                        class="icon-full icon-page-remove icon-marked-for-deletion dm-m-r-4"
                                        title="This Project has been marked for deletion in CompassOne"></span>
                                    <span class="bif--wrapper dm-m-r-4 dm-badge-v2" *ngIf="project.showBadge">
                                        <span class="bif--content">{{project.badgeText}}</span>
                                    </span>
                                </span>
                                <p>Solution Area: {{project.primaryDomain}}</p>                                
                                <p class="font-caption-alt dm-project-data__title--tooltip">
                                    EBS: {{project.id}}
                                </p>
                                <p *ngIf="project.showPjmField">
                                    Project Manager / T&E Approver:
                                    <dm-user-name *ngIf="project.pjm" [name]="project.pjm.name"
                                        [alias]="project.pjm.alias" [role]="'Project Manager'"
                                        [shouldDisplayChatIcon]="true" [showPersonCard]="true"
                                        (onNameClick)="logEmployeeClick()" [personCardLabel]="'breakdown' + project.id">
                                    </dm-user-name>
                                </p>
                            </div>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                            <span class="sr-only">Contract Type</span>
                            <span *ngIf="project.contractType"
                                class="dm-badge dm-badge-v2 dm-contract-type display-inline-block-imp"
                                [ngStyle]="project.typeColorCode">{{project.contractType}}</span>
                            <span *ngIf="!project.contractType"
                                class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div *ngIf="areMXDRChangesEnabled" role="gridcell"
                            class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                            <span class="sr-only">Rev Rec</span>{{project.recRevType}}
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                            <span class="sr-only">Start Date</span>
                            {{project.startDate | dmDisplayDateOrDashPipe}}
                            <span *ngIf="!project.startDate"
                                class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                            <span class="sr-only">End Date</span>
                            {{project.endDate | dmDisplayDateOrDashPipe}}
                            <span *ngIf="!project.endDate"
                                class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8 ebs-state-pad">
                            <span class="sr-only">EBS State</span>
                            {{project.currentStatus | dmDisplayDashIfEmptyOrNull}}
                            <span *ngIf="!project.currentStatus"
                                class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                        </div>
                        <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8 dropdown"
                            id="{{'project-' + project.id + '-dropdown'}}">
                            <span class="sr-only">Actions</span>
                            <span
                                *ngIf="!(!isProjectContext && (project.canEditProject || (project.canRequestEbsStateChange && project.showPjmField)))"
                                class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                            <button type="button" id="{{'project-' + project.id + '-dropdown-btn'}}"
                                data-toggle="dropdown" class="dm-p-imp-0 set-focus"
                                (click)="logClick(LogEventConstants.ManageEBSProjectActionsDropdownClick)"
                                [title]="'Project Actions Dropdown ' + project.id"
                                *ngIf="!isProjectContext && (project.canEditProject || (project.canRequestEbsStateChange && project.showPjmField))"
                                (keydown.ArrowDown)="moveFocus('project', project.id, accessibilityConstants.EditDetails)"
                                (keydown.shift.tab)="closeDropdownOnTab('project-' + project.id + '-dropdown')">
                                <span class="icon-full icon-more-vertical"></span>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right font-caption-alt dm-p-imp-0">
                                <span id="{{ 'project-' + project.id + '-' + accessibilityConstants.EditDetails }}"
                                    class="dropdown-item" *ngIf="project.canEditProject" tabindex="0"
                                    (click)="openEditProjectModal(project)"
                                    (keyup.enter)="openEditProjectModal(project)"
                                    (keyup.Space)="openEditProjectModal(project)"
                                    (keydown.ArrowDown)="moveFocus('project', project.id, accessibilityConstants.EditTeam)">
                                    Edit Details
                                </span>
                                <span id="{{ 'project-' + project.id + '-' + accessibilityConstants.EditTeam }}"
                                    class="dropdown-item" *ngIf="project.canEditProject" tabindex="0"
                                    (click)="openEditProjectTeam(project)" (keyup.enter)="openEditProjectTeam(project)"
                                    (keyup.Space)="openEditProjectTeam(project)"
                                    (keydown.ArrowUp)="moveFocus('project', project.id, accessibilityConstants.EditDetails)"
                                    (keydown.ArrowDown)="moveFocus('project', project.id, accessibilityConstants.RequestStatusChange)">
                                    Edit Team
                                </span>
                                <span
                                    id="{{ 'project-' + project.id + '-' + accessibilityConstants.RequestStatusChange }}"
                                    class="dropdown-item" tabindex="0"
                                    *ngIf="project.canRequestEbsStateChange && project.showPjmField"
                                    (click)="openStatusChangeModal(project, 'project')"
                                    (keyup.enter)="openStatusChangeModal(project, 'project')"
                                    (keyup.Space)="openStatusChangeModal(project, 'project')"
                                    (keydown.ArrowUp)="moveFocus('project', project.id, accessibilityConstants.EditTeam)"
                                    (keydown.Tab)="closeDropdownOnTab('project-' + project.id + '-dropdown')">
                                    Request Status
                                </span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="project.isExpand">
                        <div
                            *ngFor="let service of project.services | filter: (selectedServiceId ? {id: selectedServiceId}: undefined)">
                            <div role="row"
                                [ngClass]="{'dm-grid-view-table-v2__row' : areMXDRChangesEnabled, 'dm-grid-view-table__row': !areMXDRChangesEnabled}">
                                <div role="gridcell"
                                    class="dm-grid-view-table__bodyCell dm-p-x-12-y-8 service-details name-grid">
                                    <span class="sr-only">Name</span>
                                    <span class="dm-p-r-10">
                                        <span class="icon set-focus" tabindex="0"
                                            (click)="expandCollapseServices(service)" role="button"
                                            (keyup.enter)="expandCollapseServices(service)"
                                            (keyup.Space)="expandCollapseServices(service)"
                                            [title]="service.isExpand ? 'Collapse expanded service' : 'Expand collapsed service'"
                                            [ngClass]="{'icon-ChevronUp': service.isExpand, 'icon-ChevronDown': !service.isExpand}"
                                            [attr.aria-expanded]="service.isExpand?true:false"></span>
                                    </span>
                                    <span>
                                        <p *ngIf="service.serviceType != expenseTypeName && service.serviceType != nuanceServiceTypeName"
                                            class="service-task-header-font">
                                            <span class="dm-m-r-4">Service: {{service.name}}</span>
                                        </p>
                                        <p *ngIf="service.serviceType === expenseTypeName"
                                            class="service-task-header-font">
                                            Expense: {{service.name}}
                                        </p>
                                        <p *ngIf="service.serviceType === nuanceServiceTypeName"
                                            class="service-task-header-font">
                                            Product: {{service.name}}
                                        </p>
                                        <p>EBS: {{service.id}}</p>
                                    </span>
                                    <span class="bif--wrapper dm-badge-v2 service-badge" *ngIf="service.showBadge">
                                        <span class="bif--content">{{service.badgeText}}</span>
                                    </span>
                                </div>
                                <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                                    <span class="sr-only">Contract Type</span>
                                    <span
                                        class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                </div>
                                <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                                    <span class="sr-only">Rec Rev</span>
                                    <span
                                        class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                </div>
                                <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                                    <span class="sr-only">Start Date</span>
                                    {{service.startDate | dmDisplayDateOrDashPipe}}
                                    <span *ngIf="!service.startDate"
                                        class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                </div>
                                <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                                    <span class="sr-only">End Date</span>
                                    {{service.endDate | dmDisplayDateOrDashPipe}}
                                    <span *ngIf="!service.endDate"
                                        class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                </div>
                                <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8 ebs-state-pad">
                                    <span class="sr-only">EBS State</span>
                                    {{service.currentStatus | dmDisplayDashIfEmptyOrNull}}
                                    <span *ngIf="!service.currentStatus"
                                        class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                </div>
                                <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8 dropdown"
                                    id="{{'service-' + service.id + '-dropdown'}}">
                                    <span class="sr-only">Actions</span>
                                    <span
                                        *ngIf="service.serviceType === expenseTypeName || !(service.serviceType !== expenseTypeName && (project.canEditProject || project.canRequestEbsStateChange))"
                                        class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                    <button type="button" id="{{'service-' + service.id + '-dropdown-btn'}}"
                                        data-toggle="dropdown" class="dm-p-imp-0 set-focus"
                                        (click)="logClick(LogEventConstants.ManageEBSServiceActionsDropdownClick)"
                                        [title]="'Service Actions Dropdown ' + service.id"
                                        *ngIf="service.serviceType !== expenseTypeName && (project.canEditProject || project.canRequestEbsStateChange)"
                                        (keydown.ArrowDown)="moveFocus('service', service.id, accessibilityConstants.EditDetails)"
                                        (keydown.shift.tab)="closeDropdownOnTab('service-' + service.id + '-dropdown')">
                                        <span class="icon-full icon-more-vertical"></span>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right font-caption-alt dm-p-imp-0">
                                        <span
                                            id="{{ 'service-' + service.id + '-' + accessibilityConstants.EditDetails }}"
                                            class="dropdown-item" tabindex="0"
                                            *ngIf="service.serviceType !== expenseTypeName && project.canEditProject && service.serviceType !== nuanceServiceTypeName"
                                            (click)="openEditServiceModal(service, project.startDate, project.endDate, project.ioEndDate, project.projectTypeCode)"
                                            (keyup.enter)="openEditServiceModal(service, project.startDate, project.endDate, project.ioEndDate, project.projectTypeCode)"
                                            (keyup.Space)="openEditServiceModal(service, project.startDate, project.endDate, project.ioEndDate, project.projectTypeCode)"
                                            (keydown.ArrowDown)="moveFocus('service', service.id, accessibilityConstants.RequestStatusChange)">
                                            Edit
                                        </span>
                                        <span
                                            id="{{ 'service-' + service.id + '-' + accessibilityConstants.RequestStatusChange }}"
                                            class="dropdown-item" tabindex="0"
                                            *ngIf="service.serviceType !== expenseTypeName && project.canRequestEbsStateChange && !isProjectClsoureFeatureEnabled && service.serviceType !== nuanceServiceTypeName"
                                            (click)="openStatusChangeModal(service, 'service')"
                                            (keyup.enter)="openStatusChangeModal(service, 'service')"
                                            (keyup.Space)="openStatusChangeModal(service, 'service')"
                                            (keydown.ArrowUp)="moveFocus('service', service.id, accessibilityConstants.EditDetails)"
                                            (keydown.ArrowDown)="moveFocus('service', service.id, accessibilityConstants.AddTask)">
                                            Request Status
                                        </span>
                                        <span id="{{ 'service-' + service.id + '-' + accessibilityConstants.AddTask }}"
                                            class="dropdown-item" tabindex="0"
                                            *ngIf="service.serviceType != expenseTypeName && service.serviceType !== serviceTypeNames.OneTimeFee && service.serviceType !== serviceTypeNames.RecurringFee && project.canEditProject && service.badgeText !== ECIFStatus && service.serviceType !== nuanceServiceTypeName"
                                            (click)="addTaskModal(service)" (keyup.enter)="addTaskModal(service)"
                                            (keyup.Space)="addTaskModal(service)"
                                            (keydown.ArrowUp)="moveFocus('service', service.id, accessibilityConstants.RequestStatusChange)"
                                            (keydown.Tab)="closeDropdownOnTab('service-' + service.id + '-dropdown')">
                                            Add Task
                                        </span>
                                        <span id="{{ 'service-' + service.id + '-' + accessibilityConstants.AddTask }}"
                                            class="dropdown-item" tabindex="0"
                                            *ngIf="project.canEditProject && service.serviceType === nuanceServiceTypeName"
                                            (click)="openEditServiceModal(service, project.startDate, project.endDate, project.ioEndDate, project.projectTypeCode)"
                                            (keyup.enter)="openEditServiceModal(service, project.startDate, project.endDate, project.ioEndDate, project.projectTypeCode)"
                                            (keyup.Space)="openEditServiceModal(service, project.startDate, project.endDate, project.ioEndDate, project.projectTypeCode)"
                                            (keydown.ArrowUp)="moveFocus('service', service.id, accessibilityConstants.RequestStatusChange, project.projectTypeCode)"
                                            (keydown.Tab)="closeDropdownOnTab('service-' + service.id + '-dropdown')">
                                            Edit Dates
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="service.tasks && service.tasks.length && service.isExpand">
                                <div role="row" class="dm-grid-view-table__row"
                                    *ngFor="let task of service.tasks | filter: (selectedTaskId ? {id: selectedTaskId} : undefined)">
                                    <div role="gridcell"
                                        class="dm-grid-view-table__bodyCell dm-p-x-12-y-8 task-details">
                                        <span class="sr-only">Name</span>
                                        <span>
                                            <p class="service-task-header-font">
                                                <span class="dm-m-r-4">Task: {{task.name}}</span>
                                            </p>
                                            <p>EBS: {{task.id}}</p>
                                        </span>
                                    </div>
                                    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                                        <span class="sr-only">Contract Type</span>
                                        <span
                                            class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                    </div>
                                    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                                        <span class="sr-only">Rec Rev</span>
                                        <span
                                            class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                    </div>
                                    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                                        <span class="sr-only">Start Date</span>
                                        {{task.startDate | dmDisplayDateOrDashPipe}}
                                        <span *ngIf="!task.startDate"
                                            class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                    </div>
                                    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8">
                                        <span class="sr-only">End Date</span>
                                        {{task.endDate | dmDisplayDateOrDashPipe}}
                                        <span *ngIf="!task.endDate"
                                            class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                    </div>
                                    <div role="gridcell"
                                        class="dm-grid-view-table__bodyCell dm-p-x-12-y-8 ebs-state-pad">
                                        <span class="sr-only">EBS State</span>
                                        {{task.currentStatus | dmDisplayDashIfEmptyOrNull}}
                                        <span *ngIf="!task.currentStatus"
                                            class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                    </div>
                                    <div role="gridcell" class="dm-grid-view-table__bodyCell dm-p-x-12-y-8 dropdown"
                                        id="{{'task-' + task.id + '-dropdown'}}">
                                        <span class="sr-only">Actions</span>
                                        <span
                                            *ngIf="service.serviceType === expenseTypeName || !(service.serviceType !== expenseTypeName && (project.canEditProject || project.canRequestEbsStateChange))"
                                            class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                        <button type="button" id="{{'task-' + task.id + '-dropdown-btn'}}"
                                            data-toggle="dropdown" class="dm-p-imp-0 set-focus"
                                            (click)="logClick(LogEventConstants.ManageEBSTaskActionsDropdownClick)"
                                            [title]="'Task Actions Dropdown ' + task.id"
                                            *ngIf="service.serviceType !== expenseTypeName && (project.canEditProject || project.canRequestEbsStateChange)"
                                            (keydown.ArrowDown)="moveFocus('task', task.id, accessibilityConstants.EditDetails)"
                                            (keydown.shift.tab)="closeDropdownOnTab('task-' + task.id + '-dropdown')">
                                            <span class="icon-full icon-more-vertical"></span>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right font-caption-alt dm-p-imp-0">
                                            <span
                                                id="{{ 'task-' + task.id + '-' + accessibilityConstants.EditDetails }}"
                                                class="dropdown-item" tabindex="0"
                                                *ngIf="service.serviceType !== expenseTypeName && project.canEditProject"
                                                (click)="openEditTaskModal(task, service.name,  service.startDate, service.endDate)"
                                                (keyup.enter)="openEditTaskModal(task, service.name,  service.startDate, service.endDate)"
                                                (keyup.Space)="openEditTaskModal(task, service.name,  service.startDate, service.endDate)"
                                                (keydown.ArrowDown)="moveFocus('task', task.id, accessibilityConstants.RequestStatusChange)">
                                                Edit
                                            </span>
                                            <span
                                                id="{{ 'task-' + task.id + '-' + accessibilityConstants.RequestStatusChange }}"
                                                class="dropdown-item" tabindex="0"
                                                *ngIf="service.serviceType !== expenseTypeName && project.canRequestEbsStateChange && !isProjectClsoureFeatureEnabled"
                                                (click)="openStatusChangeModal(task, 'task')"
                                                (keyup.enter)="openStatusChangeModal(task, 'task')"
                                                (keyup.Space)="openStatusChangeModal(task, 'task')"
                                                (keydown.ArrowUp)="moveFocus('task', task.id, accessibilityConstants.EditDetails)"
                                                (keydown.Tab)="closeDropdownOnTab('task-' + task.id + '-dropdown')">
                                                Request Status
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="errorText"></dm-service-error>
</dm-tile>