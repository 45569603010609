<div class="dm-hidden-placeholder"></div>

<section class="p-b-12" *ngIf="deviceFactory.isMobile() && isAdvancedSearch && !isServerError">
    <!-- (keydown)="focusBack($event,'back-to-quick',null,'one')" not defined in component -->
    <a href id="ebs-focus-back" class="dm-link" (click)="showHideAdvancedSearch()">
        <span class="icon icon-chevron-left dm-tile--m-t-5 dm-tile--view-chevron"></span>
        Back to Quick Search
    </a>
</section>
<section class="row dm-advanced-search">
    <section class="col-md-12 col-sm-12 col-xs-12 dm-p-imp-0">

        <!--================================= Desktop/Tablet View =================================-->
        <dm-tile [tileHeaderContent]="tileContent" [tileMinHeight]="'100vh'">
            <dm-loading loader *ngIf="isLoadingSearch" [isCustomText]="true" [loadingText]="'Searching'"
                       [showLoading]="showLoadingSearch"></dm-loading>
            <section class="row">
                <!-- =============================== Quick Search Field ============================ -->
                <section *ngIf="!isAdvancedSearch && !isServerError"
                    class="quick-search text-left col-md-12 col-sm-12 col-xs-12 dm-tile--m-b-24">
                    <form name="quickSearchForm" role="search">
                        <label class="input__label--text font-caption-alt dm-tile--m-b-12" for="quick-search">
                            Quick Search

                            <dm-tooltip [dmPlacement]="'bottom-left'"
                                [ngbTooltipContent]="'All Quick Searches will be parsed as searching by Project Name unless the search query is formatted as a proper Engagement ID (a letter, a period, and ten number digits), or a Project ID (a letter, a period, ten number digits, a period, and six number digits).'"
                                [dmIcon]="'tile__icon--info icon icon-info'"
                                ariaLabelToolTip="Quick Search Information"></dm-tooltip>
                        </label>
                        <section class="dm-tile--no-pad" *ngIf="!deviceFactory.isMobile()">
                            <input class="font-caption-alt input__text" autofocus type="text" id="quickSearch"
                                name="quickSearch" [(ngModel)]="quickSearch"
                                placeholder="Search Project Name/ID or Engagement ID and press Enter" minlength="3"
                                aria-label="Search Project Name/ID or Engagement ID and press Enter"
                                (keyup.enter)="quickSearchApply()" />
                            <!--Keydown was getting in the way of being able to type into the input-->
                            <!--(keydown)="$event.which === 13 && quickSearchApply()"-->
                            <button title="Clear" class="input__icon search__icon input__icon__cancel-btn"
                               (click)="clearText()" *ngIf="quickSearch.length > 0" type="button">
                                <i class="icon icon__ele icon-cancel"></i>
                            </button>
                            <button title="Search" aria-label="search" (click)="quickSearchApply()"
                                class="input__icon input__icon__search-btn" type="button" id="quicksearch-search"
                                [disabled]="quickSearch.length < 3">
                                <i class="icon icon__ele icon-search"></i>
                            </button>
                            <button type="button" id="advancedSearch" role="link" (click)="showHideAdvancedSearch()"
                                class="fxp-btn fxp-btn--primary">
                                Advanced Search
                            </button>
                        </section>
                        <section>
                            <section class="dm-tile--no-pad clearfix">
                                <p role="alert" *ngIf="quickSearch && quickSearch.length && quickSearch.length < 3"
                                    class="font-caption-alt dm-tile--m-t-4 dm-tile--error-msg">
                                    {{advancedSearchErrorMessages.MinimumCharacterLength}}
                                </p>
                                <p role="alert" *ngIf="showQuickSearchValidationMsg"
                                    class="font-caption-alt dm-tile--m-t-4 dm-tile--error-msg">
                                    {{advancedSearchErrorMessages.EnterAProjectName}}
                                </p>
                            </section>
                        </section>
                    </form>
                </section>

                <!-- ================================== Advanced Search Field =================================== -->
                <section class="advanced-search col-md-12 col-sm-12 col-xs-12" *ngIf="isAdvancedSearch && !isServerError">
                    <section class="dm-tile--m-b-16">
                        <p class="font-caption-alt">Advanced Search: Search across one or more search parameters </p>
                    </section>
                    <section>
                        <!--Set the variable #advancedSearch as ngForm so that you can check entire form validity/pristine/dirty by using advancedSearch.form.invalid, etc-->
                        <form name="advancedSearch" #advancedSearch="ngForm">
                            <!-- ========== Removing form (keydown) functionality for accessibility purpose because (keydown) is executing when click on clear button in text filed ---- (keydown)="$event.which === 13 && !(advancedSearch.invalid || searchButtonDisabled()) && advancedSearchList()" ================= -->
                            <section class="row dm-tile--m-b-0-mobile">
                                <section class="col-md-3 col-sm-4 pad-b-12-mobile">
                                    <label class="input__label--text font-caption-alt" for="projectName">
                                        Project Name
                                    </label>
                                    <section class="dm-tile--no-pad">
                                        <input class="font-caption-alt input__text" type="text" id="projectName"
                                            name="projectName" [(ngModel)]="projectName" minlength="3" maxlength="40"
                                            (blur)="visitedProjectName=true" />
                                        <button title="Clear"
                                            class="input__search-icon input__icon__search-btn advanced-search--cancelbtn"
                                            (click)="clearTextField('projectName')"
                                            *ngIf="projectName.length > 0" type="button">
                                            <i class="icon icon__ele icon-cancel"></i>
                                        </button>
                                    </section>
                                    <section class="col-md-12 col-sm-12 dm-tile--no-pad">
                                        <p role="alert" *ngIf="projectName && projectName.length < 3"
                                            class="font-caption-alt dm-tile--m-t-4 dm-tile--error-msg">
                                            {{advancedSearchErrorMessages.MinimumCharacterLength}}
                                        </p>
                                        <p role="alert" *ngIf="projectName && projectName.length > 40"
                                            class="font-caption-alt dm-tile--m-t-4 dm-tile--error-msg">
                                            {{advancedSearchErrorMessages.ProjectNamesMaximumCharacterLength}}
                                            {{projectName.length - 40}} characters.
                                        </p>
                                    </section>
                                </section>
                                <section class="col-md-3 col-sm-4 pad-b-12-mobile dm-m-l-imp-26">
                                    <label class="input__label--text font-caption-alt" for="engagementName">
                                        Engagement Name
                                    </label>
                                    <section class="dm-tile--no-pad">
                                        <input class="font-caption-alt input__text" type="text" id="engagementName"
                                            name="engagementName" [(ngModel)]="engagementName" minlength="3"
                                            maxlength="38" (blur)="visitedEngagementName=true" />
                                        <button title="Clear"
                                            class="input__search-icon input__icon__search-btn advanced-search--cancelbtn"
                                            (click)="clearTextField('engagementName')"
                                            *ngIf="engagementName.length > 0" type="button">
                                            <i class="icon icon__ele icon-cancel"></i>
                                        </button>
                                    </section>
                                    <section class="col-md-12 col-sm-12 dm-tile--no-pad">
                                        <p role="alert" *ngIf="engagementName && engagementName.length < 3"
                                            class="font-caption-alt dm-tile--m-t-4 dm-tile--error-msg">
                                            {{advancedSearchErrorMessages.MinimumCharacterLength}}
                                        </p>
                                        <p role="alert" *ngIf="engagementName && engagementName.length > 40"
                                            class="font-caption-alt dm-tile--m-t-4 dm-tile--error-msg">
                                            {{advancedSearchErrorMessages.EngagementNamesMaximumCharacterLength}}
                                            by {{engagementName.length - 38}} characters.
                                        </p>
                                    </section>
                                </section>
                                <section class="col-md-3 col-sm-4 pad-b-12-mobile dm-m-l-imp-26">
                                    <label class="input__label--text font-caption-alt" for="proEngId">
                                        Project or Engagement ID
                                        <dm-tooltip *ngIf="deviceFactory.isSmallScreen()"
                                            [dmPlacement]="deviceFactory.isTablet() ? 'bottom-right' : 'bottom'"
                                            [ngbTooltipContent]="'Search query should be formatted as a proper Project ID (a letter, a period, ten number digits, a period, and six number digits), or Engagement ID (a letter, a period, and ten number digits).'"
                                            [dmIcon]="'tile__icon--info icon icon-info'"
                                            ariaLabelToolTip="Project or Engagement ID Information"></dm-tooltip>
                                        <dm-tooltip *ngIf="deviceFactory.isDesktop()" [dmPlacement]="'bottom-left'"
                                            [ngbTooltipContent]="'Search query should be formatted as a proper Project ID (a letter, a period, ten number digits, a period, and six number digits), or Engagement ID (a letter, a period, and ten number digits).'"
                                            [dmIcon]="'tile__icon--info icon icon-info'"
                                            ariaLabelToolTip="Project or Engagement ID Information"></dm-tooltip>
                                    </label>
                                    <section class="dm-tile--no-pad">
                                        <input class="font-caption-alt input__text" type="text" id="proEngId"
                                            name="proEngId" #projectEngagementId="ngModel" [(ngModel)]="proEngId"
                                            minlength="12" (blur)="visitedProjEngID=true" />
                                        <button title="Clear"
                                            class="input__search-icon input__icon__search-btn advanced-search--cancelbtn"
                                            (click)="clearTextField('proEngId')"
                                            *ngIf="proEngId.length > 0" type="button">
                                            <i class="icon icon__ele icon-cancel"></i>
                                        </button>
                                    </section>
                                    <section class="col-md-12 col-sm-12 dm-tile--no-pad">
                                        <!--  visitedProjEngID-->
                                        <!-- <p role="alert" *ngIf="visitedProjEngID && projectEngagementId.invalid" class="font-caption-alt dm-tile--m-t-4 dm-tile--error-msg">
                                            Enter minimum 12 characters
                                        </p> -->
                                        <p role="alert" *ngIf="!validateProjEngId()"
                                            class="font-caption-alt dm-tile--m-t-4 dm-tile--error-msg">
                                            {{advancedSearchErrorMessages.EnterValidProjectID}}
                                        </p>
                                    </section>
                                </section>
                                <section class="col-md-3 col-sm-4 dm-m-t-12" *ngIf="deviceFactory.isDesktop()">
                                    <label class="input__label--text font-caption-alt" for="opportunityId">
                                        Opportunity ID
                                    </label>
                                    <section class="dm-tile--no-pad">
                                        <input class="font-caption-alt input__text" type="text" id="opportunityId"
                                            name="opportunityId" [(ngModel)]="opportunityId" minlength="3"
                                            (blur)="visitedOpportunityID=true" />
                                        <button title="Clear"
                                            class="input__search-icon input__icon__search-btn advanced-search--cancelbtn"
                                            (click)="clearTextField('opportunityId')"
                                            *ngIf="opportunityId.length > 0" type="button">
                                            <i class="icon icon__ele icon-cancel"></i>
                                        </button>
                                    </section>
                                    <section class="col-md-12 col-sm-12 dm-tile--no-pad">
                                        <p role="alert" *ngIf="opportunityId && opportunityId.length < 3"
                                            class="font-caption-alt dm-tile--m-t-4 dm-tile--error-msg">
                                            {{advancedSearchErrorMessages.MinimumCharacterLength}}
                                        </p>
                                    </section>
                                </section>
                                <section class="col-md-3 col-sm-4 pad-b-12-mobile" *ngIf="deviceFactory.isMobile()">
                                    <label
                                        class="input__label--text font-caption-alt dm-tile--m-t-18 dm-tile--m-t-0-mobile"
                                        for="opportunityId">
                                        Opportunity ID
                                    </label>
                                    <section class="dm-tile--no-pad">
                                        <input class="font-caption-alt input__text" type="text" id="opportunityId"
                                            name="opportunityId" [(ngModel)]="opportunityId" minlength="3" />
                                        <button title="Clear"
                                            class="input__search-icon input__icon__search-btn advanced-search--cancelbtn"
                                            (click)="clearTextField('opportunityId')"
                                            *ngIf="opportunityId.length > 0" type="button">
                                            <i class="icon icon__ele icon-cancel"></i>
                                        </button>
                                    </section>
                                    <section class="col-md-12 col-sm-12 dm-tile--no-pad">
                                        <p role="alert" *ngIf="opportunityId && opportunityId.length < 3"
                                            class="font-caption-alt dm-tile--m-t-4 dm-tile--error-msg">
                                            {{advancedSearchErrorMessages.MinimumCharacterLength}}
                                        </p>
                                    </section>
                                </section>

                                <section class="col-md-3 col-sm-4 pad-b-12-mobile primary-section dm-m-t-12 dm-m-l-imp-26">
                                    <label class="input__label--text font-caption-alt" for="{{typeAheadId}}"
                                        *ngIf="!deviceFactory.isMobile()">
                                        Primary Domain / Project Manager / T&E Approver Name
                                    </label>
                                    <label class="input__label--text font-caption-alt" for="{{typeAheadId}}"
                                        *ngIf="deviceFactory.isMobile()">
                                        Primary Domain Or Project Manager / T&E Approver Name
                                    </label>
                                    <section class="dm-tile--no-pad pjmTypeAhead">
                                        <dm-type-ahead [typeAheadId]="typeAheadId"
                                            [btnSearchAriaLabelText]="searchAriaLabel"
                                            [typeAheadLabelText]="typeAheadLabelText"
                                            [showTypeAheadLabel]="false"
                                            [btnCancelAriaLabelText]="cancelAriaLabel"
                                            [typeAheadUserValidationRequiredMessage]="requiredMsg"
                                            [noResults]="noResults" [disabled]="false" [selectedUser]="selectedUser"
                                            [isRequired]="false" [isMultiselect]="false" [fteOnly]="false"
                                            [typeaheadMinLength]="3" [ignoredExistingManagers]="true"
                                            [modelValue]="selectedUser" (selectedUserUpdated)="setSelectedUser($event)">
                                        </dm-type-ahead>
                                    </section>
                                </section>
                                <section class="col-md-3 col-sm-4 dm-m-l-imp-26">
                                    <label
                                        class="input__label--text font-caption-alt dm-tile--m-t-16 dm-tile--m-t-0-mobile"
                                        for="customerName">
                                        Customer Name
                                    </label>
                                    <section class="dm-tile--no-pad">
                                        <input class="font-caption-alt input__text" type="text" id="customerName"
                                            name="customerName" [(ngModel)]="customerName" minlength="3" maxlength="23"
                                            (blur)="visitedCustomerName=true" />
                                        <button title="Clear"
                                            class="input__search-icon input__icon__search-btn advanced-search--cancelbtn"
                                            (click)="clearTextField('customerName')"
                                            *ngIf="customerName.length > 0" type="button">
                                            <i class="icon icon__ele icon-cancel dm-p-t-10"></i>
                                        </button>
                                    </section>
                                    <section class="col-md-12 col-sm-12 dm-tile--no-pad">
                                        <p role="alert" *ngIf="customerName && customerName.length < 3"
                                            class="font-caption-alt dm-tile--m-t-4 dm-tile--error-msg">
                                            {{advancedSearchErrorMessages.MinimumCharacterLength}}
                                        </p>
                                        <p role="alert" *ngIf="customerName && customerName.length > 23"
                                            class="font-caption-alt dm-tile--m-t-4 dm-tile--error-msg">
                                            {{advancedSearchErrorMessages.CustomerNamesMaximumCharacterLength}}
                                             {{customerName.length - 23}} characters.
                                        </p>
                                    </section>
                                </section>
                                <section class="col-md-3 col-sm-4 dm-m-l-imp-26" *ngIf="deviceFactory.isTablet()">
                                    <label
                                        class="input__label--text font-caption-alt dm-tile--m-t-16 dm-tile--m-t-0-mobile"
                                        for="opportunityId">
                                        Opportunity ID
                                    </label>
                                    <section class="dm-tile--no-pad">
                                        <input class="font-caption-alt input__text" type="text" id="opportunityId"
                                            name="opportunityId" [(ngModel)]="opportunityId" minlength="3" />
                                        <button title="Clear"
                                            class="input__search-icon input__icon__search-btn advanced-search--cancelbtn"
                                            (click)="clearTextField('opportunityId')"
                                            *ngIf="opportunityId.length > 0" type="button">
                                            <i class="icon icon__ele icon-cancel"></i>
                                        </button>
                                    </section>
                                    <section class="col-md-12 col-sm-12 dm-tile--no-pad">
                                        <p role="alert" *ngIf="opportunityId && opportunityId.length < 3"
                                            class="font-caption-alt dm-tile--m-t-4 dm-tile--error-msg">
                                            {{advancedSearchErrorMessages.MinimumCharacterLength}}
                                        </p>
                                    </section>
                                </section>
                            </section>

                            <section class="row dm-tile--p-l-7">
                                <div class="dm-tile--m-b-16 col-md-12">
                                    <section class="col-md-3 col-sm-4 dm-p-imp-0">
                                        <button type="button" class="fxp-btn fxp-btn--primary search-reset-buttons"
                                            (click)="advancedSearchList()"
                                            [disabled]="advancedSearch.form.invalid || searchButtonDisabled()">
                                            Search
                                        </button>
                                        <button type="button" class="fxp-btn fxp-btn--secondary reset-btn search-reset-buttons"
                                            (click)="resetSearch()">Reset</button>
                                    </section>
                                    <button type="button" class="fxp-btn fxp-btn--primary quick-search-btn"
                                        *ngIf="!deviceFactory.isMobile() && showBackToQuickSearch"
                                        (click)="showHideAdvancedSearch()">
                                        Back to Quick Search
                                    </button>
                                </div>
                            </section>
                        </form>
                    </section>
                </section>

                <!--========================== Search Results ======================== -->

                <section class="search-results pos-rel col-md-12 col-sm-12 col-lg-12" *ngIf="isShowResults && !isServerError">                    
                    <section *ngIf="!isLoadingSearch" class="row">
                        <section class="text-left col-md-12 col-sm-12 col-xs-12">
                            <h3 class="dm-project-data__title dm-tile__header dm-tile--m-b-12">
                                <span role="alert">{{searchText}}:
                                    <span *ngIf="!searchResultsViewModel.engagementDetails.length"> NONE</span>
                                    <span *ngIf="searchResultsViewModel.engagementDetails.length > 0">
                                        {{searchResultsViewModel.engagementDetails.length}}
                                        {{getWordPluralWithS("ENGAGEMENT", searchResultsViewModel.engagementDetails.length,
                                        true)}} AND {{projectCount}} {{getWordPluralWithS("PROJECT", projectCount, true)}}
                                        <span
                                            *ngIf="searchResultsViewModel.confidentialEngagementsCount && searchText === 'SEARCH RESULTS' ">
                                            ({{searchResultsViewModel.confidentialEngagementsCount}} CONFIDENTIAL
                                            {{getWordPluralWithS("RESULT",
                                            searchResultsViewModel.confidentialEngagementsCount, true)}} HIDDEN)
                                        </span>
                                    </span>
                                    <span
                                        *ngIf="unfilteredSearchResults.engagementDetails.length === 0 && unfilteredSearchResults.confidentialEngagementsCount && searchText === 'SEARCH RESULTS' ">
                                        ({{unfilteredSearchResults.confidentialEngagementsCount}}
                                        CONFIDENTIAL
                                        {{getWordPluralWithS("RESULT",
                                        unfilteredSearchResults.confidentialEngagementsCount, true)}}
                                        HIDDEN)
                                    </span>
                                </span>
                            </h3>
                        </section>
                        <section class="search-results--filter-no-results col-md-12 col-lg-12 col-sm-12 clearfix"
                            *ngIf="!isShowSearchResults" role="alert">
                            <p class="font-subheader">There are no results to display</p>
                        </section>

                        <!-- ================== Filter Options =============== -->
                        <section *ngIf="isShowSearchResults" class="col-md-12 col-xs-12 col-sm-12 col-lg-12">
                            <section class="search-results__filter dm-tile--m-b-20">
                                <section class="row">
                                    <section
                                        class="search-results__ebssatte col-md-3 col-sm-4 dm-tile--padding-right-0">
                                        <label class="input__label--text font-caption-alt" for="quick-search">
                                            EBS State
                                        </label>
                                        <section>
                                            <section class="input__select--icon dm-tile--padding-right-0">
                                                <select id="dm-invoice-services-ddl" aria-label="EBS States"
                                                    class="input__select input__select--big"
                                                    [(ngModel)]="statusSelected">
                                                    <option value="">All</option>
                                                    <option *ngFor="let item of statusList" [value]="item">
                                                        {{item}}
                                                    </option>
                                                </select>
                                                <span class="icon icon-chevron-down pull-right"
                                                    aria-hidden="true"></span>
                                            </section>
                                        </section>
                                    </section>
                                     <section class="col-sm-4 dm-p-l-imp-0 dm-m-l-40">
                                            <section>
                                                <label class="input__label--text font-caption-alt" for="duration">
                                                    Duration
                                                </label>
                                            </section>
                                            <section class="date-section">
                                                <section class="dm-datepicker">
                                                        <dm-datepicker [modelDate]="filterStartDate"
                                                            (onDateChange)="setStartDate($event)"
                                                            ariaLabelButton="Duration Start date input field"
                                                            ariaLabelCalendar="Start date"
                                                            previosFousingElementId="dm-invoice-services-ddl">
                                                        </dm-datepicker>
                                                </section>
                                                <span class="font-caption-alt duration-section"> to </span>
                                                <section
                                                   class ="dm-datepicker">
                                                        <dm-datepicker [modelDate]="filterEndDate"
                                                            (onDateChange)="setEndDate($event)"
                                                            ariaLabelButton="Duration End date input field"
                                                            ariaLabelCalendar="End date"
                                                            previosFousingElementId="calenderBtn_Duration Start date input field">
                                                        </dm-datepicker>
                                                </section>
                                            </section>
                                        </section>
                                        <section class="dm-tile--no-pad col-sm-4 col-md-pull-1 filter-section"
                                        *ngIf="deviceFactory.isDesktop()">
                                        <div>
                                            <button type="button" id="addTaskSubmit"
                                                class="fxp-btn fxp-btn--primary apply-reset-section"
                                                [disabled]="applyFiltersButtonDisabled()" (click)="applyFilter()">
                                                Apply Filters
                                            </button>
                                            <button type="button" class="fxp-btn fxp-btn--secondary apply-reset-section reset-btn"
                                                (click)="resetFilter()">
                                                Reset Filters
                                            </button>
                                        </div>
                                    </section>
                                    <section class="search-results__buttons" *ngIf="deviceFactory.isTablet()">
                                        <div>
                                            <button type="button" id="addTaskSubmit" class="fxp-btn fxp-btn--primary"
                                                (click)="applyFilter()" [disabled]="applyFiltersButtonDisabled">
                                                Apply Filters
                                            </button>
                                            <button type="button" class="fxp-btn fxp-btn--secondary"
                                                (click)="resetFilter()">
                                                Reset Filters
                                            </button>
                                        </div>
                                    </section>
                                </section>
                            </section>
                            <section class="search-results-list">
                                <section *ngIf="!isShowFilterResults">
                                    <section *ngIf="deviceFactory.isDesktop()">
                                        <div role="grid">
                                            <div id="searchResultCaption" class="sr-only" role="alert">{{tableCaption}}
                                            </div>
                                            <div role="rowgroup" class="dm-grid-view-table">
                                                <div role="row" class="dm-grid-view-table__row">
                                                    <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                                        Name
                                                    </div>
                                                    <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                                        EBS State
                                                        <dm-tooltip [dmPlacement]="'bottom-left'"
                                                            [ngbTooltipContent]="tooltipText"
                                                            [dmIcon]="'tile__icon--info icon icon-info'"
                                                            ariaLabelToolTip="EBS State Information"></dm-tooltip>
                                                            <span class="sr-only">press tab to navigate to tooltip button</span>
                                                    </div>
                                                    <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                                        Customer
                                                    </div>
                                                    <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                                        Duration
                                                    </div>
                                                    <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                                        Type
                                                        <!--Template content for the tooltip-->
                                                        <ng-template #descContactPopupStatic>
                                                            <div class="type-dialogBox">
                                                                <h4 class="type-dialogBox--header text-left">Contract Types
                                                                </h4>
                                                                <ul class="type-dialogBox__ul col-md-12 no-pad">
                                                                    <li *ngFor="let data of typeOfResources"
                                                                        class="type-dialogBox__ul--li col-md-6">
                                                                        <div class="col-md-4">
                                                                            <span class="dm-badge dm-contract-type"
                                                                                [ngStyle]="data.typeColorCode">{{data.typeShortForm}}</span>
                                                                        </div>
                                                                        <div class="col-md-8 text-left v-a-m fullformLbl">
                                                                            <span>{{data.typeFullForm}}</span>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </ng-template>
                                                        <dm-tooltip [dmPlacement]="'bottom'"
                                                            [ngbTooltipContent]="'#descContactPopupStatic'"
                                                            [dmIcon]="'tile__icon--info icon icon-info'"
                                                            ariaLabelToolTip="Type Information"></dm-tooltip>
                                                            <span class="sr-only">press tab to navigate to tooltip button</span>
                                                    </div>
                                                    <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                                        Project Manager / T&E Approver
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    </section>
                                    <table class="font-caption-alt search-results-list--tablet" width="100%"
                                        role="presentation" *ngIf="deviceFactory.isTablet()">
                                        <caption class="sr-only" role="alert">{{tableCaption}}</caption>
                                        <thead>
                                            <tr class="dm-tile__table__th">
                                                <th scope="col" class="text-left search-results-list--tablet--w-50per">
                                                    Name
                                                </th>
                                                <th scope="col" class="text-left search-results-list--tablet--w-25per">
                                                    Customer
                                                </th>
                                                <th scope="col" class="text-left search-results-list--tablet--w-25per">
                                                    Domain
                                                    <br />Project Manager
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                    <section class="search-results--filter-no-results">
                                        <p class="font-subheader">There are no results to display</p>
                                        <p>
                                            <button type="button" (click)="resetFilter()"
                                                class="quick-search__advanced-btn">
                                                Want to reset your filter?
                                            </button>
                                        </p>
                                    </section>
                                </section>
                                <section *ngIf="isShowFilterResults">
                                    <section *ngIf="deviceFactory.isDesktop()">
                                        <div role="grid">
                                            <div id="searchResultCaption" class="sr-only" role="alert">{{tableCaption}}
                                            </div>
                                            <div role="rowgroup" class="dm-grid-view-table">
                                                <div role="row" class="dm-grid-view-table__row">
                                                    <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                                        Name
                                                    </div>
                                                    <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                                        EBS State
                                                        <dm-tooltip [dmPlacement]="'bottom-left'"
                                                            [ngbTooltipContent]="tooltipText"
                                                            [dmIcon]="'tile__icon--info icon icon-info'"
                                                            ariaLabelToolTip="EBS State Information"></dm-tooltip>
                                                            <span class="sr-only">press tab to navigate to tooltip button</span>
                                                    </div>
                                                    <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                                        Customer
                                                    </div>
                                                    <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                                        Duration
                                                    </div>
                                                    <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                                        Type
                                                        <!--Template content for the tooltip-->
                                                        <ng-template #descContactPopup>
                                                            <div class="type-dialogBox font-caption-alt">
                                                                <h4 class="type-dialogBox--header text-left">Contract
                                                                    Types</h4>
                                                                <ul class="type-dialogBox__ul row">
                                                                    <li *ngFor="let data of typeOfResources"
                                                                        class="type-dialogBox__ul--li col-md-6">
                                                                        <div class="row">
                                                                            <div class="col-md-4">
                                                                                <span class="dm-badge dm-contract-type"
                                                                                    [ngStyle]="data.typeColorCode">{{data.typeShortForm}}</span>
                                                                            </div>
                                                                            <div
                                                                                class="col-md-8 text-left v-a-m fullformLbl">
                                                                                <span>{{data.typeFullForm}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </ng-template>
                                                        <dm-tooltip [dmPlacement]="'bottom'"
                                                            [ngbTooltipContent]="descContactPopup"
                                                            [dmIcon]="'tile__icon--info icon icon-info'"
                                                            ariaLabelToolTip="Type Information"></dm-tooltip>
                                                            <span class="sr-only">press tab to navigate to tooltip button</span>
                                                    </div>
                                                    <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                                        Project Manager / T&E Approver
                                                    </div>
                                                </div>
                                                <!-- ==================== Search Results Table Desktop View ================= -->
                                                <div class="dm-grid-view-table__body" *ngFor="let engagement of searchResultsViewModel.engagementDetails | slice:slicedItemsNumber:gridItemsDisplay; let i = index">
                                                    <div role="row" class="dm-grid-view-table__row">
                                                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-left dm-p-x-12-y-8"> 
                                                            <span>
                                                                <p>
                                                                    <b>
                                                                        {{engagement.isInternalEngagment ? 'Internal' :
                                                                        'Engagement'}}:
                                                                        
                                                                        <ng-container>
                                                                            <a class="dm-tile--m-r-8 dm-link"
                                                                                *ngIf="!engagement.isInternalEngagment"
                                                                                [uiSref]="RouteName.EngagementSummaryV2"
                                                                                [uiParams]="{engagementId: engagement.engagementId}">
                                                                                <span
                                                                                    [innerHTML]="highlightText(engagement.name, (isAdvancedSearch) ? engagementName:quickSearch)"></span>
                                                                            </a>
                                                                            <a class="dm-tile--m-r-8 dm-link"
                                                                                *ngIf="engagement.isInternalEngagment"
                                                                                [uiSref]="RouteName.InternalEngagementSummary"
                                                                                [uiParams]="{engagementId: engagement.engagementId}">
                                                                                <span
                                                                                    [innerHTML]="highlightText(engagement.name, (isAdvancedSearch) ? engagementName:quickSearch)"></span>
                                                                            </a>
                                                                        </ng-container>
                                                                        <section *ngIf="!engagement.isInternalEngagment"
                                                                            class="dropdown pull-right" id="{{'engagement-' + engagement.engagementId + '-dropdown-' + i}}">
                                                                            <button type="button" data-toggle="dropdown"
                                                                                [title]="'Engagement Dropdown '+engagement.engagementId"
                                                                                [attr.aria-label]="'Engagement Dropdown '+engagement.engagementId"
                                                                                (keydown.ArrowDown)="moveFocus('engagement', engagement.engagementId , ' accessibilityConstants.Financials')"
                                                                                (keydown.shift.tab)="closeDropdownOnTab('engagement-' + engagement.engagementId + '-dropdown-' + i)">
                                                                                <span
                                                                                    class="icon-full icon-more-vertical"></span>
                                                                            </button>
                                                                            <span class="sr-only">press tab to navigate to dropdown button</span>
                                                                            <div
                                                                                class="dropdown-menu dropdown-menu-right font-caption-alt">
                                                                                <a class="dropdown-item dm-link"
                                                                                    [uiSref]="RouteName.EngagementFinancials"
                                                                                    [uiParams]="{engagementId: engagement.engagementId}"
                                                                                    (keydown.ArrowDown)="moveFocus('engagement', engagement.engagementId , ' accessibilityConstants.ManageEBS')"
                                                                                    id="{{ 'dm-grid-engagement-moreoptions' + engagement.engagementId + ' accessibilityConstants.Financials'}}">
                                                                                    Financials
                                                                                </a>
                                                                                <a class="dropdown-item dm-link"
                                                                                    [uiSref]="RouteName.EngagementManageEBS"
                                                                                    [uiParams]="{engagementId: engagement.engagementId}"
                                                                                    (keydown.ArrowUp)="moveFocus('engagement', engagement.engagementId , ' accessibilityConstants.Financials')"
                                                                                    (keydown.ArrowDown)="moveFocus('engagement', engagement.engagementId , ' accessibilityConstants.PlanForecast')"
                                                                                    id="{{ 'dm-grid-engagement-moreoptions' + engagement.engagementId + ' accessibilityConstants.ManageEBS'}}">
                                                                                    Manage EBS
                                                                                </a>
                                                                                <a class="dropdown-item dm-link"
                                                                                    [uiSref]="RouteName.EngagementPlanForecast"
                                                                                    [uiParams]="{engagementId: engagement.engagementId}"
                                                                                    (keydown.ArrowUp)="moveFocus('engagement', engagement.engagementId , ' accessibilityConstants.ManageEBS')"
                                                                                    (keydown.ArrowDown)="moveFocus('engagement', engagement.engagementId , ' accessibilityConstants.Staffing')"
                                                                                    id="{{ 'dm-grid-engagement-moreoptions' + engagement.engagementId + ' accessibilityConstants.PlanForecast' }}">
                                                                                    Plan & Forecast
                                                                                </a>
                                                                                <a class="dropdown-item dm-link"
                                                                                    [uiSref]="RouteName.EngagementStaffing"
                                                                                    [uiParams]="{engagementId: engagement.engagementId}"
                                                                                    (keydown.ArrowUp)="moveFocus('engagement', engagement.engagementId , ' accessibilityConstants.PlanForecast')"
                                                                                    (keydown.ArrowDown)="moveFocus('engagement', engagement.engagementId , ' accessibilityConstants.ManageSuppliers')"
                                                                                    id="{{ 'dm-grid-engagement-moreoptions' + engagement.engagementId + ' accessibilityConstants.Staffing'}}">
                                                                                    Staffing
                                                                                </a>
                                                                                <a class="dropdown-item dm-link"
                                                                                    [uiSref]="RouteName.EngagementManageSuppliers"
                                                                                    [uiParams]="{engagementId: engagement.engagementId}"
                                                                                    (keydown.ArrowUp)="moveFocus('engagement', engagement.engagementId , ' accessibilityConstants.Staffing')"
                                                                                    (keydown.ArrowDown)="moveFocus('engagement', engagement.engagementId , ' accessibilityConstants.Milestones')"
                                                                                    id="{{ 'dm-grid-engagement-moreoptions' + engagement.engagementId + ' accessibilityConstants.ManageSuppliers'}}">
                                                                                    Manage Suppliers
                                                                                </a>
                                                                                <a class="dropdown-item dm-link"
                                                                                    [uiSref]="RouteName.EngagementMilestones"
                                                                                    [uiParams]="{engagementId: engagement.engagementId}"
                                                                                    (keydown.ArrowUp)="moveFocus('engagement', engagement.engagementId , ' accessibilityConstants.ManageSuppliers')"
                                                                                    (keydown.ArrowDown)="moveFocus('engagement', engagement.engagementId , ' accessibilityConstants.Invoices')"
                                                                                    id="{{ 'dm-grid-engagement-moreoptions' + engagement.engagementId + ' accessibilityConstants.Milestones'}}">
                                                                                    Milestones
                                                                                </a>
                                                                                <a class="dropdown-item dm-link"
                                                                                    [uiSref]="RouteName.EngagementInvoices"
                                                                                    [uiParams]="{engagementId: engagement.engagementId}"
                                                                                    (keydown.ArrowUp)="moveFocus('engagement', engagement.engagementId , ' accessibilityConstants.Milestones')"
                                                                                    (keydown.Tab)="closeDropdownOnTab('engagement-' + engagement.engagementId + '-dropdown-' + i)"
                                                                                    id="{{ 'dm-grid-engagement-moreoptions' + engagement.engagementId + ' accessibilityConstants.Invoices'}}">
                                                                                    Invoices
                                                                                </a>
                                                                            </div>
                                                                        </section>
                                                                        <section *ngIf="engagement.isInternalEngagment"
                                                                            class="dropdown pull-right" id="{{'dropdown-' + i}}">
                                                                            <button type="button" data-toggle="dropdown"
                                                                                [title]="'Internal Engagement Dropdown '+engagement.engagementId"
                                                                                [attr.aria-label]="'Internal Engagement Dropdown '+engagement.engagementId"
                                                                                (keydown.ArrowDown)="moveFocus('internal', engagement.engagementId, ' accessibilityConstants.Financials')" (keydown.shift.tab)="closeDropdownOnTab('dropdown-' + i)">
                                                                                <span class="icon-full icon-more-vertical"></span>
                                                                            </button>
                                                                            <span class="sr-only">press tab to navigate to dropdown button</span>
                                                                            <div
                                                                                class="dropdown-menu dropdown-menu-right font-caption-alt">
                                                                                <a class="dropdown-item dm-link"
                                                                                    [uiSref]="RouteName.InternalEngagementFinancials"
                                                                                    [uiParams]="{engagementId:engagement.engagementId }"                                                                                    
                                                                                    (keydown.ArrowDown)="moveFocus('internal', engagement.engagementId, ' accessibilityConstants.Staffing')"
                                                                                    id="{{ 'dm-grid-internal-moreoptions' + engagement.engagementId + ' accessibilityConstants.Financials'}}">
                                                                                    Financials
                                                                                </a>
                                                                                <a class="dropdown-item dm-link"
                                                                                    [uiSref]="RouteName.InternalEngagementStaffing"
                                                                                    [uiParams]="{engagementId:engagement.engagementId }"
                                                                                    (keydown.ArrowUp)="moveFocus('internal', engagement.engagementId, ' accessibilityConstants.Financials')"
                                                                                    (keydown.Tab)="closeDropdownOnTab('dropdown-' + i)"
                                                                                    id="{{ 'dm-grid-internal-moreoptions' + engagement.engagementId + ' accessibilityConstants.Staffing'}}">
                                                                                    Staffing
                                                                                </a>
                                                                            </div>
                                                                        </section>
                                                                        <dm-tooltip *ngIf="engagement.isConfidentialDeal"
                                                                            class="confidentialicon"
                                                                            [dmPlacement]="'bottom'"
                                                                            [ngbTooltipContent]="'This engagement is marked as confidential.'"
                                                                            [dmIcon]="'tile__icon--info confidentialkey'"
                                                                            ariaLabelToolTip="confidentiality information">
                                                                        </dm-tooltip>
                                                                        <dm-tooltip
                                                                            *ngIf="engagement.hasAssociatedEngagements && engagement.isInternalEngagment"
                                                                            [dmPlacement]="'bottom'"
                                                                            [ngbTooltipContent]="'This engagement has associated customer delivery engagements.'"
                                                                            [dmIcon]="'tile__icon--info icon icon-link link-posig'"
                                                                            ariaLabelToolTip="associated engagement information">
                                                                        </dm-tooltip>
                                                                        <button class="inward"
                                                                            *ngIf="engagement.delegationAllocationType === 'delegatedInward'"
                                                                            (click)="openDelegationModal(engagement.delegationDetails, 'delegatedInward', 'engagement')">
                                                                            <span class="delegated"
                                                                                aria-hidden="true">D</span>
                                                                            <span>
                                                                                <i title="Delegated to me"
                                                                                    class="icon icon-ResizeTouchSmaller icon-delegated"></i>
                                                                                <span class="sr-only">
                                                                                    {{engagement.name}} delegated to me
                                                                                </span>
                                                                            </span>
                                                                        </button>
                                                                        <button class="outward"
                                                                            *ngIf="engagement.delegationAllocationType === 'delegatedOutward'"
                                                                            (click)="openDelegationModal(engagement.delegationDetails, 'delegatedOutward', 'engagement')">
                                                                            <span class="delegated"
                                                                                aria-hidden="true">D</span>
                                                                            <span>
                                                                                <i title="Delegated by me"
                                                                                    class="icon icon-ResizeTouchLarger icon-delegated"></i>
                                                                                <span class="sr-only">
                                                                                    {{engagement.name}} delegated to others
                                                                                </span>
                                                                            </span>
                                                                        </button>
                                                                        <dm-tooltip
                                                                            *ngIf="!engagement.isUsPubSec && isPubSecEnabled && engagement.isPublicSector"
                                                                            [dmPlacement]="'bottom'"
                                                                            [ngbTooltipContent]="'Pub Sec'"
                                                                            [dmIcon]="'pubsec_icon'"
                                                                            ariaLabelToolTip="pub sec Information">
                                                                        </dm-tooltip>
                                                                        <span *ngIf="engagement.isUsPubSec"
                                                                            class="uspubsec">
                                                                            <!--todo this span should never be activated because the search API does not return isUsPubSec. Right now
                                                                            we are defining this for when it's applied in the future.-->
                                                                        </span>
                                                                    </b>
                                                                </p>
                                                                <p>
                                                                    EBS:
                                                                    <span
                                                                        [innerHTML]="highlightText(engagement.engagementId, (isAdvancedSearch) ? opportunityId:quickSearch)"></span>
                                                                </p>
                                                                <!-- <p>
                                                                    Domain:
                                                                    <span>{{engagement.primaryDomain}}</span>
                                                                    <span class="sr-only">press tab to navigate to link</span>
                                                                </p> -->
                                                                <span class="sr-only">press tab to navigate to link</span>
                                                            </span>
                                                        </div>
                                                        <div role="gridcell"
                                                            class="dm-grid-view-table__bodyCell text-left dm-p-x-12-y-8">
                                                            {{engagement.stateDescription}}
                                                        </div>
                                                        <div role="gridcell"
                                                            class="dm-grid-view-table__bodyCell text-left dm-p-x-12-y-8"
                                                            [innerHTML]="highlightText(engagement.customerName, customerName)">
                                                        </div> 
                                                        <div role="gridcell"
                                                            class="dm-grid-view-table__bodyCell text-left dm-p-x-12-y-8">
                                                            <span>
                                                                {{engagement.startDate | dmDisplayDateOrDashPipe}}
                                                                <span *ngIf="!engagement.startDate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                                            </span>
                                                            <span class="font-caption-alt dm-p-5"> to </span>
                                                            <span>
                                                                {{engagement.endDate | dmDisplayDateOrDashPipe}}
                                                                <span *ngIf="!engagement.endDate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                                            </span>
                                                        </div>
                                                        <div role="gridcell"
                                                            class="dm-grid-view-table__bodyCell text-left dm-p-x-12-y-8">
                                                            <span *ngIf="!engagement.isInternalEngagment"
                                                            [ngStyle]="engagement.typeColorCode"
                                                            class="dm-badge dm-contract-type">{{engagement.contractType}}</span>
                                                        <span
                                                            *ngIf="engagement.isInternalEngagment">{{engagement.internalEngagmentType}}</span>
                                                        </div>
                                                        <div role="gridcell"
                                                            class="dm-grid-view-table__bodyCell text-left dm-p-x-12-y-8"
                                                            [innerHTML]="highlightText(engagement.pPjMName, selectedUser ? selectedUser.userName: undefined)">
                                                        </div> 
                                                    </div>
                                                    <div role="row" class="dm-grid-view-table__row" *ngFor="let project of engagement.projectDetails; let j = index">
                                                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-left dm-p-x-12-y-8 dm-p-l-imp-24"> 
                                                            <span>
                                                                <p>
                                                                    <b>
                                                                        Project:
                                                                        <a class="dm-tile--m-r-8 dm-link"
                                                                            [uiSref]="RouteName.ProjectSummaryV2"
                                                                            [uiParams]="{projectId:project.projectId}">
                                                                            <span
                                                                                [innerHTML]="highlightText(project.name, (isAdvancedSearch) ? projectName:quickSearch)"></span>
                                                                        </a>
                                                                        <span class="sr-only">press tab to navigate to link</span>
                                                                        <section class="dropdown pull-right" id="{{'project-' + project.projectId + '-dropdown-' + j}}">
                                                                            <button type="button" data-toggle="dropdown"
                                                                                [title]="'Project Dropdown '+project.projectId"
                                                                                [attr.aria-label]="'Project Dropdown '+project.projectId"
                                                                                (keydown.ArrowDown)="moveFocus('project', project.projectId , ' accessibilityConstants.Financials')"
                                                                                (keydown.shift.tab)="closeDropdownOnTab('project-' + project.projectId + '-dropdown-' + j)">
                                                                                <span
                                                                                    class="icon-full icon-more-vertical"></span>
                                                                            </button>
                                                                            <span class="sr-only">press tab to navigate to dropdown button</span>
                                                                            <div
                                                                                class="dropdown-menu dropdown-menu-right font-caption-alt">
                                                                                <a class="dropdown-item dm-link"
                                                                                    [uiSref]="RouteName.ProjectFinancials"
                                                                                    [uiParams]="{projectId: project.projectId}"
                                                                                    (keydown.ArrowDown)="moveFocus('project', project.projectId , ' accessibilityConstants.PlanForecast')"
                                                                                    id="{{ 'dm-grid-project-moreoptions' + project.projectId + ' accessibilityConstants.Financials' }}">
                                                                                    Financials
                                                                                </a>
                                                                                <a class="dropdown-item dm-link"
                                                                                    [uiSref]="RouteName.ProjectPlanForecast"
                                                                                    [uiParams]="{projectId: project.projectId}"
                                                                                    (keydown.ArrowUp)="moveFocus('project', project.projectId , ' accessibilityConstants.Financials')"
                                                                                    (keydown.ArrowDown)="moveFocus('project', project.projectId , ' accessibilityConstants.ManageEBS')"
                                                                                    id="{{ 'dm-grid-project-moreoptions' + project.projectId + ' accessibilityConstants.PlanForecast'}}">
                                                                                    Plan & Forecast
                                                                                </a>
                                                                                <a class="dropdown-item dm-link"
                                                                                    [uiSref]="RouteName.ProjectManageEBS"
                                                                                    [uiParams]="{projectId: project.projectId}"
                                                                                    (keydown.ArrowUp)="moveFocus('project', project.projectId , ' accessibilityConstants.PlanForecast')"
                                                                                    (keydown.ArrowDown)="moveFocus('project', project.projectId , ' accessibilityConstants.Staffing')"
                                                                                    id="{{ 'dm-grid-project-moreoptions' + project.projectId + ' accessibilityConstants.ManageEBS'}}">
                                                                                    Manage EBS
                                                                                </a>
                                                                                <a class="dropdown-item dm-link"
                                                                                    [uiSref]="RouteName.ProjectStaffing"
                                                                                    [uiParams]="{projectId: project.projectId}"
                                                                                    (keydown.ArrowUp)="moveFocus('project', project.projectId , ' accessibilityConstants.ManageEBS')"
                                                                                    (keydown.ArrowDown)="moveFocus('project', project.projectId , ' accessibilityConstants.ManageSuppliers')"
                                                                                    id="{{ 'dm-grid-project-moreoptions' + project.projectId + ' accessibilityConstants.Staffing'}}">
                                                                                    Staffing
                                                                                </a>
                                                                                <a class="dropdown-item dm-link"
                                                                                    [uiSref]="RouteName.ProjectManageSuppliers"
                                                                                    [uiParams]="{projectId: project.projectId}"
                                                                                    (keydown.ArrowUp)="moveFocus('project', project.projectId , ' accessibilityConstants.Staffing')"
                                                                                    (keydown.ArrowDown)="moveFocus('project', project.projectId , ' accessibilityConstants.Milestones')"
                                                                                    id="{{ 'dm-grid-project-moreoptions' + project.projectId + ' accessibilityConstants.ManageSuppliers'}}">
                                                                                    Manage Suppliers
                                                                                </a>
                                                                                <a class="dropdown-item dm-link"
                                                                                    [uiSref]="RouteName.ProjectMilestones"
                                                                                    [uiParams]="{projectId: project.projectId}"
                                                                                    (keydown.ArrowUp)="moveFocus('project', project.projectId , ' accessibilityConstants.ManageSuppliers')"
                                                                                    (keydown.ArrowDown)="moveFocus('project', project.projectId , ' accessibilityConstants.Invoices')"
                                                                                    id="{{ 'dm-grid-project-moreoptions' + project.projectId + ' accessibilityConstants.Milestones'}}">
                                                                                    Milestones
                                                                                </a>
                                                                                <a class="dropdown-item dm-link"
                                                                                    [uiSref]="RouteName.ProjectInvoices"
                                                                                    [uiParams]="{projectId: project.projectId}"
                                                                                    (keydown.ArrowUp)="moveFocus('project', project.projectId , ' accessibilityConstants.Milestones')"
                                                                                    (keydown.Tab)="closeDropdownOnTab('project-' + project.projectId + '-dropdown-' + j)"
                                                                                    id="{{ 'dm-grid-project-moreoptions' + project.projectId + ' accessibilityConstants.Invoices' }}">
                                                                                    Invoices
                                                                                </a>
                                                                            </div>
                                                                        </section>
                                                                        <dm-tooltip *ngIf="project.isConfidentialDeal"
                                                                            class="confidentialicon"
                                                                            [dmPlacement]="'bottom'"
                                                                            [ngbTooltipContent]="'This engagement is marked as confidential.'"
                                                                            [dmIcon]="'tile__icon--info confidentialkey'"
                                                                            ariaLabelToolTip="confidentiality information">
                                                                        </dm-tooltip>
                                                                        <button class="inward"
                                                                            *ngIf="project.delegationAllocationType === 'delegatedInward'"
                                                                            (click)="openDelegationModal(project.delegationDetails, 'delegatedInward', 'project')">
                                                                            <span class="delegated"
                                                                                aria-hidden="true">D</span>
                                                                            <span>
                                                                                <i title="Delegated to me"
                                                                                    class="icon icon-ResizeTouchSmaller icon-delegated"></i>
                                                                                <span class="sr-only">
                                                                                    {{project.name}} Project delegated to
                                                                                    me
                                                                                </span>
                                                                            </span>
                                                                        </button>
                                                                        <button class="outward"
                                                                            *ngIf="project.delegationAllocationType === 'delegatedOutward'"
                                                                            (click)="openDelegationModal(project.delegationDetails, 'delegatedOutward', 'project')">
    
                                                                            <span class="delegated"
                                                                                aria-hidden="true">D</span>
                                                                            <span>
                                                                                <i title="Delegated by me"
                                                                                    class="icon icon-ResizeTouchLarger icon-delegated"></i>
                                                                                <span class="sr-only">
                                                                                    {{project.name}} Project
                                                                                    delegated to others
                                                                                </span>
                                                                            </span>
                                                                        </button>
                                                                        <dm-tooltip
                                                                            *ngIf="!project.isUsPubSec && isPubSecEnabled && project.isPublicSector"
                                                                            [dmPlacement]="'bottom'"
                                                                            [ngbTooltipContent]="'Pub Sec'"
                                                                            [dmIcon]="'pubsec_icon'"
                                                                            ariaLabelToolTip="pub sec Information">
                                                                        </dm-tooltip>
                                                                        <span tabindex="0" *ngIf="project?.isMarkedForDeletion" class="icon-full icon-page-remove icon-marked-for-deletion dm-m-r-4" title="This Project has been marked for deletion in CompassOne"></span>
                                                                        <span *ngIf="project.isUsPubSec" class="uspubsec">
                                                                            <!--todo this span should never be activated because the search API does not return isUsPubSec. Right now
                                                                            we are defining this for when it's applied in the future.-->
                                                                        </span>
                                                                    </b>
                                                                </p>
                                                                <p>
                                                                    EBS:
                                                                    <span
                                                                        [innerHTML]="highlightText(project.projectId, (isAdvancedSearch) ? opportunityId:quickSearch)"></span>
                                                                </p>
                                                                <p>
                                                                     Solution Area:
                                                                    <span>{{project.domain}}</span>
                                                                </p>
                                                            </span>
                                                        </div>
                                                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-left dm-p-x-12-y-8"> 
                                                            {{project.stateDescription}}
                                                        </div>
                                                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-left dm-p-x-12-y-8"
                                                            [innerHTML]="highlightText(engagement.customerName, customerName)"> 
                                                        </div>
                                                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-left dm-p-x-12-y-8"> 
                                                            <span>
                                                                {{project.startDate | dmDisplayDateOrDashPipe}}
                                                                <span *ngIf="!project.startDate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                                            </span>
                                                            <span class="font-caption-alt dm-p-5"> to </span>
                                                            <span>
                                                                {{project.endDate | dmDisplayDateOrDashPipe}}
                                                                <span *ngIf="!project.endDate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                                            </span>
                                                        </div>
                                                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-left dm-p-x-12-y-8">
                                                            <span
                                                            [ngStyle]="project.typeColorCode" 
                                                            class="dm-badge dm-contract-type"> {{project.contractType}}</span>
                                                        </div>
                                                        <div role="gridcell" class="dm-grid-view-table__bodyCell text-left dm-p-x-12-y-8"
                                                            [innerHTML]="highlightText(project.pjMName, selectedUser ? selectedUser.userName: undefined)">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                          
                                        </div>
                                        <section class="col-md-12 dm-pagination">
                                            <dm-pagination
                                                *ngIf="searchResultsViewModel && searchResultsViewModel.engagementDetails && searchResultsViewModel.engagementDetails.length > 5"
                                                [portfolioItemsDisp]="portfolioItemsDisp"
                                                [portfolioDataLengthAfterFilter]="searchResultsViewModel.engagementDetails.length"
                                                [currentPage]="currentPage"
                                                (currentPageChanged)="currentPageChangedHandler($event)">
                                            </dm-pagination>
                                        </section>
                                    </section>
                                    
                                    <!-- ======================== Search Results Table Tablet View ===================== -->
                                    <!-- <section class="search-results-list--tablet" *ngIf="deviceFactory.isTablet()">
                                        <table class="font-caption-alt" width="100%">
                                            <caption class="sr-only">Search List</caption>
                                            <thead>
                                                <tr class="dm-tile__table__th">
                                                    <th scope="col" class="text-left search-results-list--tablet--w-50per pd-l-48">
                                                        Name
                                                    </th>
                                                    <th scope="col" class="text-left search-results-list--tablet--w-25per">
                                                        Customer
                                                    </th>
                                                    <th scope="col" class="text-left search-results-list--tablet--w-25per">
                                                        Domain<br />Project Manager
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                        <section *ngFor="let engagement of searchResultsViewModel.engagementDetails">
                                            <table class="font-caption-alt" width="100%">
                                                <caption class="sr-only">Search List</caption>
                                                <thead>
                                                    <tr class="dm-tile__table__th sr-only">
                                                        <th scope="col" class="text-left search-results-list--tablet--w-50per">
                                                            Name
                                                        </th>
                                                        <th scope="col" class="text-left search-results-list--tablet--w-25per">
                                                            Customer
                                                        </th>
                                                        <th scope="col" class="text-left search-results-list--tablet--w-25per">
                                                            Domain<br />Project Manager
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr class="dm-tile__table__tr" [ngClass]="{'search-results-list--tablet--expanded':engagement.openEngagement}">
                                                        <th scope="row" class="text-left search-results-list--tablet--w-50per text-capitilize">
                                                            <div class="search-results-list--tablet--accicon">
                                                                <button role="button" [ngClass]="{'icon-chevron-up': engagement.openEngagement, 'icon-chevron-down': !engagement.openEngagement}"
                                                                        class="icon projects-btn" (click)="engagement.openEngagement=!engagement.openEngagement" aria-label="Spacebar to toggle">
                                                                    <span class="sr-only" *ngIf="!engagement.openEngagement" aria-live="polite">{{engagement.openEngagement?'Expanded':'Collapsed'}}</span>
                                                                </button>
                                                            </div>
                                                            <div class="search-results-list--tablet--coltext">
                                                                <p>
                                                                    <b>
                                                                        {{engagement.isInternalEngagment ? 'Internal:' : 'Engagement:'}}
                                                                        <span *ngIf="engagement.isExternalDomain" class="dm-tile--m-r-8" [innerHTML]="highlightText(engagement.name, (isAdvancedSearch) ? engagementName:quickSearch)"></span>
                                                                        <a routerLink="#/dm/engagement/{{engagement.engagementId}}/summary" class="dm-tile--m-r-8" *ngIf="!engagement.isExternalDomain"><span [innerHTML]="highlightText(engagement.name, (isAdvancedSearch) ? engagementName:quickSearch)"></span></a>
                                                                        <dm-tooltip *ngIf="engagement.isConfidentialDeal" [dmPlacement]="'bottom-left'" [ngbTooltipContent]="'This engagement is marked as confidential.'" class="confidentialicon" [dmIcon]="'tile__icon--info confidentialkey'"></dm-tooltip>

                                                                        <button class="inward" *ngIf="engagement.delegationAllocationType === 'delegatedInward'"
                                                                                (click)="openDelegationModal(engagement.delegationDetails, 'delegatedInward', 'engagement')">
                                                                            <span class="delegated" aria-hidden="true">D</span>
                                                                            <span>
                                                                                <i title="Delegated to me" class="icon icon-ResizeTouchSmaller icon-delegated"></i>
                                                                                <span class="sr-only">{{engagement.name}} delegated to me</span>
                                                                            </span>
                                                                        </button>
                                                                        <button class="outward" *ngIf="engagement.delegationAllocationType === 'delegatedOutward'"
                                                                                (click)="openDelegationModal(engagement.delegationDetails, 'delegatedOutward', 'engagement')">
                                                                            <span class="delegated" aria-hidden="true">D</span>
                                                                            <span>
                                                                                <i title="Delegated by me" class="icon icon-ResizeTouchLarger icon-delegated"></i>
                                                                                <span class="sr-only">{{engagement.name}} delegated to others</span>
                                                                            </span>
                                                                        </button>
                                                                        <dm-tooltip *ngIf="isPubSecEnabled && engagement.isPublicSector" [dmPlacement]="'bottom-left'" [ngbTooltipContent]="'Pub Sec'" class="confidentialicon" [dmIcon]="'tile__icon--info confidentialkey'"></dm-tooltip>
                                                                    </b>
                                                                </p>
                                                                <p>
                                                                    ID: <span [innerHTML]="highlightText(engagement.engagementId, (isAdvancedSearch) ? proEngOppId:quickSearch)"></span>
                                                                </p>
                                                                <p *ngIf="engagement.isExternalDomain">
                                                                    External Domain: <span [innerHtml]="engagement.primaryDomain"></span>
                                                                </p>
                                                            </div>
                                                        </th>
                                                        <td class="text-left text-capitilize search-results-list--tablet--w-25per" [innerHTML]="highlightText(engagement.customerName, customerName)"></td>
                                                        <td *ngIf="selectedUser" class="text-left text-capitilize search-results-list--tablet--w-25per" [innerHTML]="highlightText(engagement.pPjMName, selectedUser.userName)"></td>
                                                    <td *ngIf="!selectedUser"> {{engagement.pPjMName}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <section class="col-sm-12 col-md-12 pd-t-20 pd-l-42 pd-b-20 b-b font-caption-alt" *ngIf="engagement.openEngagement">
                                                <section class="col-sm-3 col-md-3">
                                                    <h4 class="section-header pd-b-6">
                                                        Type
                                                        <dm-tooltip [dmPlacement]="'bottom-left'" [ngbTooltipContent]="'#profile-descContactPopup-search{{$index}}-mob'" [dmIcon]="'tile__icon--info icon icon-info'"></dm-tooltip>
                                                    </h4>
                                                    <div id="profile-descContactPopup-search{{$index}}-mob" class="hidden">
                                                        <div class="type-dialogBox">
                                                            <h4 class="type-dialogBox--header text-left">Contract Types</h4>
                                                            <ul class="type-dialogBox__ul col-md-12 no-pad">
                                                                <li *ngFor="let data of typeOfResources track" class="type-dialogBox__ul--li col-md-6 col-sm-6">
                                                                    <div class="col-md-4 col-sm-4">
                                                                        <span class="shortformLbl" [ngStyle]="data.typeColorCode" [innerHtml]="data.typeShortForm"></span>
                                                                    </div>
                                                                    <div class="col-md-8 col-sm-8 text-left v-a-m fullformLbl">
                                                                        <span [innerHtml]="data.typeFullForm"></span>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <p class="text-left text-capitilize">
                                                        <span *ngIf="!engagement.isInternalEngagment" [ngStyle]="engagement.typeColorCode" class="search-results--contract-type" [innerHtml]="engagement.contractType"></span>
                                                        <span *ngIf="engagement.isInternalEngagment" [innerHtml]="engagement.internalEngagmentType"></span>
                                                    </p>
                                                </section>
                                                <section class="col-sm-3 col-md-3">
                                                    <h4 class="section-header pd-b-6">
                                                        EBS State
                                                        <dm-uib-popover role="application" class="dm-tooltipinline" dm-back="ebsstate8-mob" dm-aria="EBS State info window" dm-tooltip-aria="EBS State info popup opened" dm-template="EBS State of EBS elements (states include Created, Released, Locked, Technically Complete, Closed)" dm-placement="bottom-left" dm-trigger="outsideClick click" dm-icon="tile__icon--info icon icon-info"></dm-uib-popover>
                                                    </h4>
                                                    <p [innerHtml]="engagement.stateDescription">
                                                    </p>
                                                </section>
                                                <section class="col-sm-4 col-md-4">
                                                    <h4 class="section-header pd-b-6">
                                                        Duration
                                                    </h4>
                                                    <p>
                                                        <span [innerHtml]="engagement.startDate | date:'dd-MMM-yyyy'"></span> - <span [innerHtml]="engagement.endDate | date:'dd-MMM-yyyy'"></span>
                                                    </p>
                                                </section>
                                                <section class="col-sm-2 col-md-2">
                                                    <h4 class="section-header pd-b-6">
                                                        Pinned
                                                    </h4>
                                                    <p>
                                                        <span *ngIf="!engagement.isAssigned && engagement.isPinned && engagement.isInternalEngagment">
                                                            <button type="button" (click)="unpinEntity(engagement,'engagement')" class="button-icon">
                                                                <i class="icon icon-pin"></i>
                                                                <span class="sr-only">
                                                                    Engagement {{engagement.name}} is pinned click to unpin.
                                                                </span>
                                                            </button>
                                                        </span>
                                                        <span *ngIf="!engagement.isAssigned && engagement.isPinned && engagement.isInternalEngagment">
                                                            <button type="button" (click)="pinEntity(engagement,'engagement')" class="button-icon">
                                                                <i class="icon icon-Unpin"></i><span class="sr-only">
                                                                    Engagement {{engagement.name}} is unpinned click to pin.
                                                                </span>
                                                            </button>
                                                        </span>
                                                    </p>
                                                </section>
                                            </section>
                                            <section *ngFor="let project of engagement.projectDetails">
                                                <table class="font-caption-alt" width="100%">
                                                    <caption class="sr-only">Overview details</caption>
                                                    <thead class="sr-only">
                                                        <tr class="sr-only dm-tile__table__th">
                                                            <th scope="col" class="w-54 p-l-46">Name</th>
                                                            <th scope="col" class="w-26">Customer</th>
                                                            <th scope="col" class="w-20">Domain<br />Project Manager</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr class="dm-tile__table__tr" [ngClass]="{'search-results-list--tablet--expanded':project.openProject}">
                                                            <th scope="row" class="text-left search-results-list--tablet--w-50per text-capitilize">
                                                                <div class="search-results-list--tablet--accicon">
                                                                    <button role="button" [ngClass]="{'icon-chevron-up': project.openProject, 'icon-chevron-down': !project.openProject}"
                                                                            class="icon projects-btn" (click)="project.openProject=!project.openProject" aria-label="Spacebar to toggle">
                                                                        <span class="sr-only" *ngIf="!project.openProject" aria-live="polite">{{project.openProject?'Expanded':'Collapsed'}}</span>
                                                                    </button>
                                                                </div>
                                                                <div class="dm-tile--p-l-12 search-results-list--tablet--coltext">
                                                                    <p>
                                                                        <b>
                                                                            Project: <span [hidden]="!project.isExternalDomain" class="dm-tile--m-r-8" [innerHTML]="highlightText(project.name, (isAdvancedSearch) ? projectName:quickSearch)"></span>
                                                                            <a routerLink="#/dm/project/{{project.projectId}}/summary" class="dm-tile--m-r-8" [hidden]="project.isExternalDomain"><span [innerHTML]="highlightText(project.name, (isAdvancedSearch) ? projectName:quickSearch)"></span></a>
                                                                            <dm-uib-popover title="This project is marked as confidential." *ngIf="project.isConfidentialDeal" role="application" class="dm-tooltipinline dm-tile--m-r-4 confidentialicon" dm-back="projectconfidentialinfoicontip" dm-aria="confidential info window" dm-tooltip-aria="confidential popup opened" dm-template="This project is marked as confidential." dm-placement="bottom" dm-trigger="outsideClick" dm-icon="tile__icon--info confidentialkey"></dm-uib-popover>
                                                                            <button class="inward" *ngIf="project.delegationAllocationType === 'delegatedInward'"
                                                                                    (click)="openDelegationModal(project.delegationDetails, 'delegatedInward', 'project')">
                                                                                <span class="delegated" aria-hidden="true">D</span>
                                                                                <span>
                                                                                    <i title="Delegated to me" class="icon icon-ResizeTouchSmaller icon-delegated"></i>
                                                                                    <span class="sr-only">{{project.name}} Project delegated to me</span>
                                                                                </span>
                                                                            </button>
                                                                            <button class="outward" *ngIf="project.delegationAllocationType === 'delegatedOutward'"
                                                                                    (click)="openDelegationModal(project.delegationDetails, 'delegatedOutward', 'project')">

                                                                                <span class="delegated" aria-hidden="true">D</span>
                                                                                <span>
                                                                                    <i title="Delegated by me" class="icon icon-ResizeTouchLarger icon-delegated"></i>
                                                                                    <span class="sr-only">{{project.projectName}} Project delegated to others</span>
                                                                                </span>
                                                                            </button>
                                                                            <dm-uib-popover title="Pub Sec" *ngIf="isPubSecEnabled && project.isPublicSector" role="tooltip" class="dm-tooltipinline pubsec" dm-back="pubseciconsearchprojtab_{{$index}}" dm-aria="pubsec info window" dm-tooltip-aria="pubsec popup opened" dm-template="Pub Sec" dm-placement="bottom-left" dm-trigger="outsideClick" dm-icon="pubsec_icon"></dm-uib-popover>
                                                                        </b>
                                                                    </p>
                                                                    <p>
                                                                        ID: <span [innerHTML]="highlightText(project.projectId, (isAdvancedSearch) ? proEngOppId:quickSearch)"></span>
                                                                    </p>
                                                                    <p *ngIf="project.isExternalDomain">
                                                                        External Domain: <span [innerHtml]="project.domain"></span>
                                                                    </p>
                                                                </div>
                                                            </th>
                                                            <td class="search-results-list--tablet--w-25per" [innerHTML]="highlightText(engagement.customerName, customerName)"></td>
                                                            <td *ngIf="selectedUser" class="search-results-list--tablet--w-25per" [innerHTML]="highlightText(project.pjMName, selectedUser.userName)"></td>
                                                        <td *ngIf="!selectedUser">{{project.pjMName}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <section class="col-sm-12 col-md-12 pd-t-20 pd-l-58 pd-b-20 b-b font-caption-alt" *ngIf="project.openProject">
                                                    <section class="col-sm-3 col-md-3">
                                                        <h4 class="section-header pd-b-6 pd-l-12">
                                                            Type
                                                             <dm-tooltip [dmPlacement]="'bottom-left'" [ngbTooltipContent]="'#profile-descContactPopup-searchproject{{$index}}-proj-mob'" [dmIcon]="'tile__icon--info icon icon-info'"></dm-tooltip>
                                                        </h4>
                                                        <div id="profile-descContactPopup-searchproject{{$index}}-proj-mob" class="hidden">
                                                            <div class="type-dialogBox">
                                                                <h4 class="type-dialogBox--header text-left">Contract Types</h4>
                                                                <ul class="type-dialogBox__ul col-md-12 no-pad">
                                                                    <li *ngFor="let data of typeOfResources" class="type-dialogBox__ul--li col-md-6 col-sm-6">
                                                                        <div class="col-md-4 col-sm-4">
                                                                            <span class="shortformLbl" [ngStyle]="data.typeColorCode" [innerHtml]="data.typeShortForm"></span>
                                                                        </div>
                                                                        <div class="col-md-8 col-sm-8 text-left v-a-m fullformLbl">
                                                                            <span [innerHtml]="data.typeFullForm"></span>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <p class="text-left text-capitilize pd-l-12">
                                                            <span [ngStyle]="project.typeColorCode" class="search-results--contract-type" [innerHtml]="project.contractType"></span>
                                                        </p>
                                                    </section>
                                                    <section class="col-sm-3 col-md-3">
                                                        <h4 class="section-header pd-b-6">
                                                            EBS State
                                                            <dm-uib-popover role="application" class="dm-tooltipinline" dm-back="ebsstate7-mob-proj-search" dm-aria="EBS State info window" dm-tooltip-aria="EBS State info popup opened" dm-template="EBS State of EBS elements (states include Created, Released, Locked, Technically Complete, Closed)" dm-placement="bottom-left" dm-trigger="outsideClick click" dm-icon="tile__icon--info icon icon-info"></dm-uib-popover>
                                                        </h4>
                                                        <p [innerHtml]="project.stateDescription">
                                                        </p>
                                                    </section>
                                                    <section class="col-sm-4 col-md-4">
                                                        <h4 class="section-header pd-b-6">
                                                            Duration
                                                        </h4>
                                                        <p>
                                                            <span [innerHtml]="project.startDate | date: 'dd-MMM-yyyy'"></span> - <span [innerHtml]="project.endDate | date: 'dd-MMM-yyyy'"></span>
                                                        </p>
                                                    </section>
                                                    <section class="col-sm-2 col-md-2">
                                                        <h4 class="section-header pd-b-6">
                                                            Pinned
                                                        </h4>
                                                        <p>
                                                            <span *ngIf="!project.isAssigned && project.isPinned">
                                                                <button type="button" (click)="unpinEntity(project, 'project')" class="button-icon">
                                                                    <i class="icon icon-pin"></i><span class="sr-only">
                                                                        Project {{project.projectName}} is pinned click to unpin.
                                                                    </span>
                                                                </button>
                                                            </span>
                                                            <span *ngIf="!project.isAssigned && !project.isPinned">
                                                                <button type="button" (click)="pinEntity(project, 'project')" class="button-icon">
                                                                    <i class="icon icon-Unpin"></i><span class="sr-only">
                                                                        Project {{project.projectName}} is unpinned click to pin.
                                                                    </span>
                                                                </button>
                                                            </span>
                                                        </p>
                                                    </section>
                                                </section>
                                            </section>
                                        </section>
                                    </section> -->
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
            </section>
        <dm-service-error *ngIf="isServerError" [ngbTooltipContent]="errorSearchResultsText"></dm-service-error>
        </dm-tile>

        <!--======================= Mobile display ==========================-->
        <!-- <section class="dm-tile dm-advanced-search--tile" *ngIf="deviceFactory.isMobile() && !projectdisplay && advancedSearchResult">
            <section class="row">
                <section class="text-left col-md-12 col-sm-12 col-xs-12 dm-tile--no-pad">
                    <h3 class="dm-project-data__title dm-tile__header dm-tile--m-b-20">
                        {{currentUserBusinessDomain ? 'SEARCH ALL ENGAGEMENTS AND PROJECTS IN MY DOMAIN' : 'SEARCH' }}
                    </h3>
                </section>
                <section *ngIf="!isAdvancedSearch" class="quick-search text-left col-md-12 col-sm-12 col-xs-12 dm-tile--no-pad dm-tile--m-b-24">

                    <section *ngIf="isQuickSearchEnabled">
                        <form name="quickSearchForm">
                            <label class="input__label--text font-caption-alt dm-tile--m-b-12" for="quick-search">
                                Quick Search
                                <dm-uib-popover role="application" class="dm-tooltipinline" dm-back="quicksearch" dm-aria="Quick search info window" dm-tooltip-aria="Quick search info popup opened" dm-template="All Quick Searches will be parsed as searching by Project Name unless the search query is formatted as a proper Engagement ID (a letter, a period, and ten number digits), or a Project ID (a letter, a period, ten number digits, a period, and six number digits)." dm-placement="bottom-left" dm-trigger="outsideClick click" dm-icon="tile__icon--info icon icon-info"></dm-uib-popover>
                            </label>

                            <section class="dm-tile--no-pad">
                                <section class="col-xs-9 dm-tile--no-pad">
                                    <input class="font-caption-alt input__text text-ellipse" type="text" id="quickSearch" name="quickSearch"
                                           [(ngModel)]="quickSearch" placeholder="Search Project Name/ID or Engagement ID" minlength="3"
                                           aria-label="Search Project Name/ID or Engagement ID" (keydown)="$event.which === 13 && quickSearchApply()" />
                                    <button class="input__icon search__icon input__icon__cancel-btn" aria-label="Clear"
                                            (click)="clearText()" [hidden]="quickSearch.length <= 0" type="button">
                                        <i class="icon icon__ele icon-cancel"></i>
                                    </button>
                                    <button aria-label="search" (click)="quickSearchApply()" class="input__icon input__icon__search-btn" type="button" id="quicksearch-search">
                                        <i class="icon icon__ele icon-search"></i>
                                    </button>
                                </section>
                                <section class="col-xs-3 dm-tile--no-pad">
                                    <button type="button" (click)="showHideAdvancedSearch();isShowResults=false" class="quick-search__advanced-btn">Advanced Search</button>
                                </section>
                            </section>

                            <section class="row">
                                <section class="dm-tile--no-pad clearfix">
                                    <p role="alert" *ngIf="quickSearchForm.quickSearch.invalid" class="font-caption-alt dm-tile--m-t-4 dm-tile--error-msg">Minimum length is 3 letter</p>
                                    <p role="alert" *ngIf="showQuickSearchValidationMsg" class="font-caption-alt dm-tile--m-t-4 dm-tile--error-msg">Enter Valid Project Name/ID or Engagement ID</p>
                                </section>
                            </section>
                        </form>
                    </section>
                </section>

                <dm-loading loading-text="'Loading Advanced Search'"
                            error-text="errorAdvancedSearchResultsText"
                            show-loading="showLoadingAdvancedSearch"
                            *ngIf="isLoadingAdvancedSearch"></dm-loading>
                <section class="advanced-search" *ngIf="isAdvancedSearch">
                    <section class="dm-tile--m-b-16">
                        <h4 class="font-caption-alt">Advanced Search: Search across one or more search parameters </h4>
                    </section>
                    <section>
                        <form name="advancedSearch">
                            <section class="row dm-tile--m-b-24 dm-tile--m-b-0-mobile">
                                <section class="col-md-3 col-sm-4 pad-b-12-mobile">
                                    <label class="input__label--text font-caption-alt" for="projectName">
                                        Project Name
                                    </label>
                                    <section class="dm-tile--no-pad">
                                        <input class="font-caption-alt input__text" type="text" id="projectName" name="projectName"
                                               [(ngModel)]="projectName" minlength="3" maxlength="40" ng-maxlength="40" (blur)="visitedProjectName=true" />
                                    </section>
                                    <section class="col-md-12 col-sm-12 dm-tile--no-pad">
                                        <p role="alert" *ngIf="visitedProjectName && advancedSearch.projectName.dirty && advancedSearch.projectName.invalid && !advancedSearch.projectName.pristine" class="font-caption-alt dm-tile--m-t-4 dm-tile--error-msg">Enter minimum 3 characters</p>
                                    </section>
                                </section>
                                <section class="col-md-3 col-sm-4 pad-b-12-mobile">
                                    <label class="input__label--text font-caption-alt" for="engagementName">
                                        Engagement Name
                                    </label>
                                    <section class="dm-tile--no-pad">
                                        <input class="font-caption-alt input__text" type="text" id="engagementName" name="engagementName"
                                               [(ngModel)]="engagementName" minlength="3" maxlength="40" ng-maxlength="40" (blur)="visitedEngagementName=true" />
                                    </section>
                                    <section class="col-md-12 col-sm-12 dm-tile--no-pad">
                                        <p role="alert" *ngIf="visitedEngagementName && advancedSearch.engagementName.dirty && advancedSearch.engagementName.invalid && !advancedSearch.engagementName.pristine" class="font-caption-alt dm-tile--m-t-4 dm-tile--error-msg">Enter minimum 3 characters</p>
                                    </section>
                                </section>
                                <section class="col-md-3 col-sm-4 pad-b-12-mobile">
                                    <label class="input__label--text font-caption-alt" for="proEngId">
                                        Project or Engagement ID
                                        <dm-uib-popover role="application" class="dm-tooltipinline" dm-back="quicksearch"
                                                        dm-aria="ID formate info window"
                                                        dm-tooltip-aria="ID formate info popup opened"
                                                        dm-template="Search query should be formatted as a proper Project ID (a letter, a period, ten number digits, a period, and six number digits), or Engagement ID (a letter, a period, and ten number digits)."
                                                        dm-placement="bottom"
                                                        dm-trigger="outsideClick click" dm-icon="tile__icon--info icon icon-info">
                                        </dm-uib-popover>
                                    </label>
                                    <section class="dm-tile--no-pad">
                                        <input class="font-caption-alt input__text" type="text" id="proEngId" name="proEngId"
                                               [(ngModel)]="proEngId" minlength="12" (blur)="visitedProjEngID=true" />
                                    </section>
                                    <section class="col-md-12 col-sm-12 dm-tile--no-pad">
                                        <p role="alert" *ngIf="visitedProjEngID && projectEngagementId.invalid" class="font-caption-alt dm-tile--m-t-4 dm-tile--error-msg">Enter minimum 12 characters</p>
                                        <p role="alert" *ngIf="!validateProjEngId()" class="font-caption-alt dm-tile--m-t-4 dm-tile--error-msg">Enter Valid Project/Engagement Id</p>
                                    </section>
                                </section>

                            </section>
                            <section class="row dm-tile--m-b-24 mar-b-24-mobile">

                                <section class="col-md-3 col-sm-4 pad-b-12-mobile">
                                    <label class="input__label--text font-caption-alt dm-tile--m-t-18 dm-tile--m-t-0-mobile" for="opportunityId">
                                        Opportunity ID
                                    </label>
                                    <section class="dm-tile--no-pad">
                                        <input class="font-caption-alt input__text" type="text" id="opportunityId" name="opportunityId"
                                               [(ngModel)]="opportunityId" />
                                    </section>
                                </section>

                                <section class="col-md-3 col-sm-4 pad-b-12-mobile">
                                    <label class="input__label--text font-caption-alt" for="{{typeAheadId}}" *ngIf="!deviceFactory.isMobile()">
                                        Primary Domain Or<br />Domain Project Manager Name
                                    </label>
                                    <label class="input__label--text font-caption-alt" for="{{typeAheadId}}" *ngIf="deviceFactory.isMobile()">
                                        Primary Domain Or Domain Project Manager Name
                                    </label>
                                    <section class="dm-tile--no-pad pjmTypeAhead">
                                        <dm-type-ahead type-ahead-id="typeAheadId"
                                                       btn-search-aria-label-text="searchAriaLabel"
                                                       type-ahead-label-text="typeAheadLabelText"
                                                       btn-cancel-aria-label-text="cancelAriaLabel"
                                                       type-ahead-user-validation-required-message="requiredMsg"
                                                       no-results="noResults"
                                                       disable-pjm-update="false"
                                                       selected-user="selectedUser"
                                                       is-required="false"
                                                       is-multiselect="false"
                                                       fte-only="true"
                                                       on-user-cleared=""
                                                       typeahead-min-length="3"
                                                       ignored-existing-managers="true"
                                                       model-value="selectedUser"
                                                       (selectedUserUpdated)="setSelectedUser($event)>
                                        </dm-type-ahead>
                                    </section>
                                </section>
                                <section class="col-md-3 col-sm-4">
                                    <label class="input__label--text font-caption-alt dm-tile--m-t-18 dm-tile--m-t-0-mobile" for="customerName">
                                        Customer Name
                                    </label>
                                    <section class="dm-tile--no-pad">
                                        <input class="font-caption-alt input__text" type="text" id="customerName" name="customerName"
                                               [(ngModel)]="customerName" minlength="3" maxlength="23" ng-maxlength="23" (blur)="visitedCustomerName=true" />
                                    </section>
                                    <section class="col-md-12 col-sm-12 dm-tile--no-pad">
                                        <p role="alert" *ngIf="visitedCustomerName && advancedSearch.customerName.dirty && advancedSearch.customerName.invalid && !advancedSearch.customerName.pristine" class="font-caption-alt dm-tile--m-t-4 dm-tile--error-msg">Enter minimum 3 characters</p>
                                    </section>
                                </section>

                            </section>

                            <section class="row dm-tile--p-l-7">
                                <div class="dm-tile--m-b-16">
                                    <button type="button" class="fxp-btn fxp-btn--primary"
                                            (click)="advancedSearchList()" [disabled]="advancedSearch.invalid || searchButtonDisabled()">
                                        Search
                                    </button>
                                    <button type="button" class="fxp-btn fxp-btn--secondary" (click)="resetSearch()">Reset</button>
                                </div>
                            </section>
                        </form>
                    </section>
                </section>
            </section>
        </section> -->

        <!-- <section class="search-results search-results--mobile pos-rel" *ngIf="isShowResults && deviceFactory.isMobile()">
            <dm-loading loading-text="'Getting Search Results...'"
                        error-text="errorSearchResultsText"
                        show-loading="showLoadingSearch"
                        *ngIf="isLoadingSearch"></dm-loading>
            <section [hidden]="isLoadingSearch">
                <section class="dm-tile--p-b-12 clearfix" *ngIf="projectdisplay && !fromAdvSer">
                    <a href id="ebs-focus-back" class="dm-link" (click)="projectdisplay=!projectdisplay" (keydown)="focusBack($event,'back-to-engagement',null,'one')">
                        <span class="icon icon-chevron-left dm-tile--m-t-5 dm-tile--view-chevron"></span>
                        Search Results
                    </a>
                </section>
                <section class="dm-tile--p-b-12 clearfix" *ngIf="!advancedSearchResult">
                    <a href id="ebs-focus-back" class="dm-link" (click)="backSearchAdvanceResult(); projectdisplay = false" (keydown)="focusBack($event,'back-to-engagement',null,'one')">
                        <span class="icon icon-chevron-left dm-tile--m-t-5 dm-tile--view-chevron"></span>
                        Search Again
                    </a>
                </section>
                <section class="text-left col-md-12 col-sm-12 col-xs-12 dm-tile--no-pad">
                    <h3 class="dm-project-data__title dm-tile__header dm-tile--m-b-12">
                        {{searchText}}:<span *ngIf="!searchResultsViewModel.engagementDetails.length"> NONE</span>
                        <span *ngIf="searchResultsViewModel.engagementDetails.length > 0">{{searchResultsViewModel.engagementDetails.length}} ENGAGEMENT(S) AND {{projectCount}} PROJECT(S) <span *ngIf="searchResultsViewModel.confidentialEngagementsCount && searchText === 'SEARCH RESULTS' "> ({{searchResultsViewModel.confidentialEngagementsCount}} CONFIDENTIAL RESULT(S) HIDDEN)</span> </span>
                    </h3>
                </section>
                <section class="dm-filters--w-76 pull-left p-b-12" *ngIf="isShowSearchResults">
                    <div><button class="edit-filter font-label" id="dmebs_filter" (click)="openFilterModal()"><span class="icon icon-filter"></span> Edit Filters</button></div>

                </section>
                <section class="search-results--filter-no-results clearfix" *ngIf="!isShowSearchResults">
                    <p class="font-subheader">There are no results to display</p>
                </section>

                <section *ngIf="isShowSearchResults">
                    <section class="search-results-list">
                        <section *ngIf="isShowFilterResults">
                            <section class="dm-tile--p-b-12 clearfix" *ngIf="isAdvancedSearch">
                                <a href id="ebs-focus-back" class="dm-link" (click)="projectdisplay=!projectdisplay" (keydown)="focusBack($event,'back-to-engagement',null,'one')">
                                    <span class="icon icon-chevron-left dm-tile--m-t-5 dm-tile--view-chevron"></span>
                                    Back to Quick Search
                                </a>
                            </section>


                            <section class="engagement-view-mobile animate-left" *ngFor="let engagement of searchResultsViewModel.engagementDetails | startFrom:(currentPage - 1)*portfolioItemsDisp | limitTo:portfolioItemsDisp">

                                <section class="dm-tile" *ngIf="!projectdisplay">
                                    <section class="col-xs-12 dm-tile--p-b-16 dm-tile--no-pad">
                                        <section class="col-xs-9 pull-left">
                                            <b>
                                                {{engagement.isInternalEngagment ? 'Internal:' : 'Engagement:'}}
                                                <span *ngIf="engagement.isExternalDomain" class="dm-tile--m-r-8" [innerHTML]="highlightText(engagement.name, (isAdvancedSearch) ? engagementName:quickSearch)"></span>
                                                <a routerLink="/dm/engagement/{{engagement.engagementId}}/summary" class="dm-tile--m-r-8" *ngIf="!engagement.isExternalDomain"><span [innerHTML]="highlightText(engagement.name, (isAdvancedSearch) ? engagementName:quickSearch)"></span></a>
                                                <dm-uib-popover title="This engagement is marked as confidential." *ngIf="engagement.isConfidentialDeal" role="application" class="dm-tooltipinline dm-tile--m-r-4 confidentialicon" dm-back="confidentialinfoicontip" dm-aria="confidential info window" dm-tooltip-aria="confidential popup opened" dm-template="This engagement is marked as confidential." dm-placement="bottom" dm-trigger="outsideClick" dm-icon="tile__icon--info confidentialkey"></dm-uib-popover>
                                                <button class="inward" *ngIf="engagement.delegationAllocationType === 'delegatedInward'"
                                                        (click)="openDelegationModal(engagement.delegationDetails, 'delegatedInward', 'engagement')">
                                                    <span class="delegated" aria-hidden="true">D</span>
                                                    <span>
                                                        <i title="Delegated to me" class="icon icon-ResizeTouchSmaller icon-delegated"></i>
                                                        <span class="sr-only">{{engagement.name}} delegated to me</span>
                                                    </span>
                                                </button>
                                                <button class="outward" *ngIf="engagement.delegationAllocationType === 'delegatedOutward'"
                                                        (click)="openDelegationModal(engagement.delegationDetails, 'delegatedOutward', 'engagement')">
                                                    <span class="delegated" aria-hidden="true">D</span>
                                                    <span>
                                                        <i title="Delegated by me" class="icon icon-ResizeTouchLarger icon-delegated"></i>
                                                        <span class="sr-only">{{engagement.name}} delegated to others</span>
                                                    </span>
                                                </button>
                                                <dm-uib-popover title="Pub Sec" *ngIf="isPubSecEnabled && engagement.isPublicSector" role="tooltip" class="dm-tooltipinline pubsec" dm-back="pubseciconsearchmob_{{$index}}" dm-aria="pubsec info window" dm-tooltip-aria="pubsec popup opened" dm-template="Pub Sec" dm-placement="bottom" dm-trigger="outsideClick" dm-icon="pubsec_icon"></dm-uib-popover>
                                            </b>
                                            <br />
                                            <span class="font-label">
                                                ID: <span [innerHTML]="highlightText(engagement.engagementId, (isAdvancedSearch) ? proEngOppId:quickSearch)"></span>
                                            </span>
                                        </section>
                                        <section class="col-xs-3">
                                            <span *ngIf="!engagement.isAssigned && engagement.isPinned && engagement.isInternalEngagment" class="pull-right">
                                                <button type="button" (click)="unpinEntity(engagement,'engagement')" class="button-icon">
                                                    <i class="icon icon-pin"></i>
                                                    <span class="sr-only">
                                                        Engagement {{engagement.name}} is pinned click to unpin.
                                                    </span>
                                                </button>
                                            </span>
                                            <span *ngIf="!engagement.isAssigned && engagement.isPinned && engagement.isInternalEngagment" class="pull-right">
                                                <button type="button" (click)="pinEntity(engagement,'engagement')" class="button-icon">
                                                    <i class="icon icon-Unpin"></i>
                                                    <span class="sr-only">
                                                        Engagement {{engagement.name}} is unpinned click to pin.
                                                    </span>
                                                </button>
                                            </span>
                                        </section>
                                    </section>
                                    <section class="col-xs-12 dm-tile--p-b-24 dm-tile--p-l-r-0">
                                        <section class="col-xs-6 dm-tile--no-pad">
                                            <div class="dm-tile__title disp-flex p-b-0">
                                                <h5 class="dm-tile__title m-t-3">Duration</h5>
                                            </div>
                                            <p class="font-caption-alt dm-tile__content">
                                                <span [innerHtml]="engagement.startDate | date:'dd-MMM-yyyy'"></span> -<br /><span [innerHtml]="engagement.endDate | date:'dd-MMM-yyyy'"></span>
                                            </p>
                                        </section>
                                        <section class="col-xs-6 dm-tile--no-pad">
                                            <div class="dm-tile__title disp-flex p-b-0">
                                                <h5 class="dm-tile__title p-r-4">EBS State</h5>
                                                <dm-uib-popover role="application" class="dm-tooltipinline" dm-back="engagement-ebs-state-search-mobile" dm-aria="EBS State info window" dm-tooltip-aria="EBS State info popup opened" dm-template="EBS State of EBS elements (states include Created, Released, Locked, Technically Complete, Closed)" dm-placement="bottom" dm-trigger="outsideClick click" dm-icon="tile__icon--info icon icon-info"></dm-uib-popover>
                                            </div>
                                            <p class="font-caption-alt dm-tile__content" [innerHtml]="engagement.stateDescription"></p>
                                        </section>
                                    </section>

                                    <section class="col-xs-12 dm-tile--p-b-20 dm-tile--p-l-r-0">
                                        <section class="col-xs-6 dm-tile--no-pad">
                                            <div class="dm-tile__title disp-flex p-b-0">
                                                <h5 class="dm-tile__title m-t-3">Domain Project Manager</h5>
                                            </div>
                                            <p class="font-caption-alt dm-tile__content" [innerHTML]="highlightText(engagement.pPjMName, selectedUser.userName)"></p>
                                        </section>
                                        <section class="col-xs-6 dm-tile--no-pad">
                                            <div class="dm-tile__title text-left disp-flex p-b-0">
                                                <h5 class="dm-tile__title p-b-0 p-r-4">Type</h5>
                                                <dm-uib-popover role="application" class="dm-tooltipinline" dm-back="engagement-contractType-search-mobile" dm-aria="Contract Type info window" dm-tooltip-aria="Contract Type info popup opened" dm-template="#profile-descContactPopup-search{{$index}}-mob" dm-placement="bottom" dm-trigger="outsideClick click" dm-icon="tile__icon--info icon icon-info"></dm-uib-popover>
                                            </div>
                                            <div id="profile-descContactPopup-search{{$index}}-mob" class="hidden">
                                                <div class="type-dialogBox">
                                                    <h5 class="type-dialogBox--header text-left">Contract Types</h5>
                                                    <ul class="type-dialogBox__ul col-md-12 no-pad">
                                                        <li *ngFor="let data of typeOfResources" class="type-dialogBox__ul--li col-md-6">
                                                            <div class="col-md-4">
                                                                <span class="shortformLbl" [ngStyle]="data.typeColorCode" [innerHtml]="data.typeShortForm"></span>
                                                            </div>
                                                            <div class="col-md-8 text-left v-a-m fullformLbl">
                                                                <span [innerHtml]="data.typeFullForm"></span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <p class="text-left text-capitilize">
                                                <span *ngIf="!engagement.isInternalEngagment" [ngStyle]="engagement.typeColorCode" class="font-caption-alt search-results--contract-type" [innerHtml]="engagement.contractType"></span>
                                                <span *ngIf="engagement.isInternalEngagment" [innerHtml]="engagement.internalEngagmentType"></span>
                                            </p>
                                        </section>
                                    </section>

                                    <section class="col-xs-12 dm-tile--p-b-16">
                                        <div class="dm-tile__title disp-flex p-b-0">
                                            <h5 class="dm-tile__title m-t-3">Customer Name</h5>
                                        </div>
                                        <p [innerHTML]="highlightText(engagement.customerName, customerName)"></p>
                                    </section>

                                    <section class="col-xs-12" *ngIf="engagement.projectDetails.length>0">
                                        <a href id="ebs-focus-next{{$index}}" (click)="projectdisplay=true" (keydown)="focusBack($event,'ebs-focus-back',$index,'one')" class="dm-link dm-link--small pull-right">
                                            View Projects
                                            <span>({{filteredServiceId ? 1 : engagement.projectDetails.length}})</span>
                                            <span class="icon icon-chevron-right dm-tile--view-chevron"></span>
                                        </a>
                                    </section>
                                </section>

                                <section class="dm-tile engagement-view-mobile animate-left" *ngFor="let project of engagement.projectDetails" *ngIf="projectdisplay">

                                    <section class="col-xs-12 dm-tile--p-b-16 dm-tile--no-pad">
                                        <section class="col-xs-9 pull-left">
                                            Project: <span [hidden]="!project.isExternalDomain" class="dm-tile--m-r-8" [innerHTML]="highlightText(project.name, (isAdvancedSearch) ? projectName:quickSearch)"></span>
                                            <a routerLink="/dm/project/{{project.projectId}}/summary" class="dm-tile--m-r-8" [hidden]="project.isExternalDomain"><span [innerHTML]="highlightText(project.name, (isAdvancedSearch) ? projectName:quickSearch)"></span></a>
                                            <button class="inward" *ngIf="project.delegationAllocationType === 'delegatedInward'"
                                                    (click)="openDelegationModal(project.delegationDetails, 'delegatedInward', 'project')">
                                                <span class="delegated" aria-hidden="true">D</span>
                                                <span>
                                                    <i title="Delegated to me" class="icon icon-ResizeTouchSmaller icon-delegated"></i>
                                                    <span class="sr-only">{{project.name}} Project delegated to me</span>
                                                </span>
                                            </button>
                                            <button class="outward" *ngIf="project.delegationAllocationType === 'delegatedOutward'"
                                                    (click)="openDelegationModal(project.delegationDetails, 'delegatedOutward', 'project')">

                                                <span class="delegated" aria-hidden="true">D</span>
                                                <span>
                                                    <i title="Delegated by me" class="icon icon-ResizeTouchLarger icon-delegated"></i>
                                                    <span class="sr-only">{{project.projectName}} Project delegated to others</span>
                                                </span>
                                            </button>
                                            <dm-uib-popover title="Pub Sec" *ngIf="isPubSecEnabled && project.isPublicSector" role="tooltip" class="dm-tooltipinline pubsec" dm-back="pubseciconsearchprojmob_{{$index}}" dm-aria="pubsec info window" dm-tooltip-aria="pubsec popup opened" dm-template="Pub Sec" dm-placement="bottom" dm-trigger="outsideClick" dm-icon="pubsec_icon"></dm-uib-popover>
                                            <br />
                                            <span class="font-label">
                                                ID: <span [innerHTML]="highlightText(project.projectId, (isAdvancedSearch) ? proEngOppId:quickSearch)"></span>
                                            </span>
                                        </section>
                                        <section class="col-xs-3">
                                            <span *ngIf="!project.isAssigned && project.isPinned" class="pull-right">
                                                <button type="button" (click)="unpinEntity(project, 'project')" class="button-icon">
                                                    <i class="icon icon-pin"></i>
                                                    <span class="sr-only">
                                                        Project {{project.projectName}} is pinned click to unpin.
                                                    </span>
                                                </button>
                                            </span>
                                            <span *ngIf="!project.isAssigned && !project.isPinned" class="pull-right">
                                                <button type="button" (click)="pinEntity(project, 'project')" class="button-icon">
                                                    <i class="icon icon-Unpin"></i>
                                                    <span class="sr-only">
                                                        Project {{project.projectName}} is unpinned click to pin.
                                                    </span>
                                                </button>
                                            </span>
                                        </section>
                                    </section>
                                    <section class="col-xs-12 dm-tile--p-b-24 dm-tile--p-l-r-0">
                                        <section class="col-xs-6 dm-tile--no-pad">
                                            <div class="dm-tile__title disp-flex p-b-0">
                                                <h5 class="dm-tile__title m-t-3">Duration</h5>
                                            </div>
                                            <p class="font-caption-alt dm-tile__content">
                                                <span [innerHtml]="project.startDate | date: 'dd-MMM-yyyy'"></span> - <span [innerHtml]="project.endDate | date: 'dd-MMM-yyyy'"></span>
                                            </p>
                                        </section>
                                        <section class="col-xs-6 dm-tile--no-pad">
                                            <div class="dm-tile__title disp-flex p-b-0">
                                                <h5 class="dm-tile__title p-b-0 p-r-4">EBS State</h5>
                                                <dm-uib-popover role="application" class="dm-tooltipinline" dm-back="project-ebs-state-search-mobile" dm-aria="EBS State info window" dm-tooltip-aria="EBS State info popup opened" dm-template="EBS State of EBS elements (states include Created, Released, Locked, Technically Complete, Closed)" dm-placement="bottom" dm-trigger="outsideClick click" dm-icon="tile__icon--info icon icon-info"></dm-uib-popover>
                                            </div>
                                            <p class="font-caption-alt dm-tile__content" [innerHtml]="project.stateDescription"></p>
                                        </section>
                                    </section>

                                    <section class="col-xs-12 dm-tile--p-b-20 dm-tile--p-l-r-0">
                                        <section class="col-xs-6 dm-tile--no-pad">
                                            <div class="dm-tile__title disp-flex p-b-0">
                                                <h5 class="dm-tile__title m-t-3">Domain Project Manager</h5>
                                            </div>
                                            <p class="font-caption-alt dm-tile__content" [innerHTML]="highlightText(project.pjMName, selectedUser.userName)"></p>
                                        </section>
                                        <section class="col-xs-6 dm-tile--no-pad">
                                            <div class="dm-tile__title text-left disp-flex p-b-0">
                                                <h5 class="dm-tile__title p-b-0 p-r-4">Type</h5>
                                                <dm-uib-popover role="application" class="dm-tooltipinline" dm-back="project-contractType-search-mobile" dm-aria="Contract Type info window" dm-tooltip-aria="Contract Type info popup opened" dm-template="#profile-descContactPopup-search{{$index}}-mob" dm-placement="bottom" dm-trigger="outsideClick click" dm-icon="tile__icon--info icon icon-info"></dm-uib-popover>
                                            </div>
                                            <div id="profile-descContactPopup-search{{$index}}-mob" class="hidden">
                                                <div class="type-dialogBox">
                                                    <h5 class="type-dialogBox--header text-left">Contract Types</h5>
                                                    <ul class="type-dialogBox__ul col-md-12 no-pad">
                                                        <li *ngFor="let data of typeOfResources" class="type-dialogBox__ul--li col-md-6">
                                                            <div class="col-md-4">
                                                                <span class="shortformLbl" [ngStyle]="data.typeColorCode" [innerHtml]="data.typeShortForm"></span>
                                                            </div>
                                                            <div class="col-md-8 text-left v-a-m fullformLbl">
                                                                <span [innerHtml]="data.typeFullForm"></span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <p class="text-left text-capitilize">
                                                <span [ngStyle]="project.typeColorCode" class="search-results--contract-type" [innerHtml]="project.contractType"></span>
                                            </p>
                                        </section>
                                    </section>
                                </section>

                            </section>

                            <section class="dm-tile--p-b-12" *ngIf="deviceFactory.isMobile() && isAdvancedSearch">
                                <a href id="ebs-focus-back" class="dm-link" (click)="showHideAdvancedSearch();isShowResults=true" (keydown)="focusBack($event,'ebs-focus-next',null,'one')">
                                    <span class="icon icon-chevron-left dm-tile--m-t-5 dm-tile--view-chevron"></span>
                                    Back to Quick Search
                                </a>
                            </section>

                        </section>

                        <section class="search-results--filter-no-results" *ngIf="!isShowFilterResults">
                            <p class="font-subheader">There are no results to display</p>
                            <p><button type="button" (click)="resetFilter()" class="quick-search__advanced-btn">Want to reset your filter?</button></p>
                        </section>

                    </section>
                </section>
            </section>
        </section> -->
    </section>
</section>

<!-- <section class="col-sm-12 col-xs-12 dm-filters" *ngIf="isShowSearchResults && deviceFactory.isMobile()" filters-scroll fab-filter="#dmebs_filter" fab-button="#wbs-fabButton">
    <button id="wbs-fabButton" class="fabButton" aria-label="edit filters" (click)="openFilterModal()">
        <div class="innerdiv">
            <span class="icon icon-filter"></span>
        </div>
    </button>
</section> -->