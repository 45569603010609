<div class="col-md-12 col-sm-12 col-xs-12 dm-p-x-0-y-imp-12">
    <div role="tabpanel" class="tab-pane active">
        <dm-loading loader [loadingText]="loadingText" [showLoading]="showLoading" *ngIf="isComponentLoading">
        </dm-loading>
        <section class="col-md-12 col-sm-12 col-xs-12 entity-info dm-p-imp-0">
            <ng-container *ngIf="!isComponentLoading">
                <section>
                    <caption id="internalEngagementTable" class="sr-only">{{tableCaption}}</caption>
                    <div role="grid" aria-labelledby="internalEngagementTable">
                        <div role="rowgroup" class="dm-grid-view-table">
                            <div role="row" class="dm-grid-view-table__row">
                                <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                    <button id="internalEngagementNameColumnHeader" class="text-left dm-transparent-btn"
                                        (click)="sortData('Name')" type="button" role="button"
                                        [attr.aria-label]="setTitleOrAriaLabelForColumnHeader('Name')">
                                        <span class="header-title">
                                            <span>Name</span>
                                        </span>
                                        <i class="icon l-h-25 color-black"
                                            [ngClass]="{'icon-down-arrow' : !isSortASC, 'icon-up-arrow' : isSortASC}"
                                            *ngIf="sortState ==='Name'"></i>
                                    </button>
                                </div>
                                <div role="columnheader"
                                    class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8 ebs-state-pad">
                                    <button class="text-left dm-transparent-btn" (click)="sortData('EBS State')"
                                        type="button" role="button"
                                        [attr.aria-label]="setTitleOrAriaLabelForColumnHeader('EBS State')">
                                        <span class="header-title">
                                            <span>EBS State</span>
                                        </span>
                                        <i class="icon l-h-25 color-black"
                                            [ngClass]="{'icon-down-arrow' : !isSortASC, 'icon-up-arrow' : isSortASC}"
                                            *ngIf="sortState ==='EBS State'"></i>
                                    </button>

                                    <dm-tooltip role="tooltip" class="dm-tooltipinline" [dmPlacement]="'right'"
                                        [ngbTooltipContent]="ebsStateTooltipText"
                                        [dmIcon]="'tile__icon--info icon icon-info'"
                                        ariaLabelToolTip="EBS State Information"></dm-tooltip>
                                    <span class="sr-only">press tab to navigate to tooltip button</span>
                                </div>
                                <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                    <button class="text-left dm-transparent-btn" (click)="sortData('Customer')"
                                        type="button" role="button"
                                        [attr.aria-label]="setTitleOrAriaLabelForColumnHeader('Customer')">
                                        <span class="header-title">
                                            <span>Customer</span>
                                        </span>
                                        <i class="icon l-h-25 color-black"
                                            [ngClass]="{'icon-down-arrow' : !isSortASC, 'icon-up-arrow' : isSortASC}"
                                            *ngIf="sortState ==='Customer'"></i>
                                    </button>
                                </div>
                                <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                    <button class="text-left dm-transparent-btn" (click)="sortData('Start Date')"
                                        type="button" role="button"
                                        [attr.aria-label]="setTitleOrAriaLabelForColumnHeader('Start Date')">
                                        <span class="header-title">
                                            <span>Start Date</span>
                                        </span>
                                        <i class="icon l-h-25 color-black"
                                            [ngClass]="{'icon-down-arrow' : !isSortASC, 'icon-up-arrow' : isSortASC}"
                                            *ngIf="sortState ==='Start Date'"></i>
                                    </button>
                                </div>
                                <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                    <button class="text-left dm-transparent-btn" (click)="sortData('End Date')"
                                        type="button" role="button"
                                        [attr.aria-label]="setTitleOrAriaLabelForColumnHeader('End Date')">
                                        <span class="header-title">
                                            <span>End Date</span>
                                        </span>
                                        <i class="icon l-h-25 color-black"
                                            [ngClass]="{'icon-down-arrow' : !isSortASC, 'icon-up-arrow' : isSortASC}"
                                            *ngIf="sortState ==='End Date'"></i>
                                    </button>
                                </div>
                                <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                    <button class="text-left dm-transparent-btn" (click)="sortData('Type')"
                                        type="button" role="button"
                                        [attr.aria-label]="setTitleOrAriaLabelForColumnHeader('Type')">
                                        <span class="header-title">
                                            <span>Type</span>
                                        </span>
                                    </button>
                                    <i class="icon l-h-25 color-black"
                                        [ngClass]="{'icon-down-arrow' : !isSortASC, 'icon-up-arrow' : isSortASC}"
                                        *ngIf="sortState ==='Type'"></i>
                                </div>
                                <div role="columnheader" class="dm-grid-view-table__headerCell text-left dm-p-x-12-y-8">
                                    <button class="text-left dm-transparent-btn"
                                        (click)="sortData('Domain Project Manager')" type="button" role="button"
                                        [attr.aria-label]="setTitleOrAriaLabelForColumnHeader('Domain Project Manager')">
                                        <span class="header-title">
                                            <span>Project Manager / T&E Approver</span>
                                        </span>
                                    </button>
                                    <i class="icon l-h-25 color-black"
                                        [ngClass]="{'icon-down-arrow' : !isSortASC, 'icon-up-arrow' : isSortASC}"
                                        *ngIf="sortState ==='Domain Project Manager'"></i>
                                </div>
                                <div role="columnheader" *ngIf="!hidePinnedColumn"
                                    class="dm-grid-view-table__headerCell text-center dm-p-x-12-y-8">
                                    Pinned
                                </div>
                            </div>
                            <div class="dm-grid-view-table__body full-width" role="row"
                                *ngFor="let engagement of engagementList | slice:slicedItemsNumber:gridItemsDisplay; let index = index"
                                [ngClass]="{'grey_background':engagement.overallStatus==='Closed'}">
                                <div class="dm-grid-view-table__row">
                                    <ng-container scope="row" role="rowheader">
                                        <div role="gridcell"
                                            class="dm-grid-view-table__bodyCell text-left text-capitilize dm-p-x-12-y-8">
                                            <span class="sr-only">Name</span>
                                            <p>
                                                <b>
                                                    Internal:
                                                    <a *ngIf="!engagement.isExternalLink" class="dm-link"
                                                        [uiSref]="RouteName.InternalEngagementSummary"
                                                        (click)="logIntEngagementItemClick(RouteName.InternalEngagementSummary)"
                                                        [uiParams]="{engagementId:engagement.engagementId, eventName: LogEventName.PortfolioNavigationFromInternalEngagementsTile }"
                                                        id="{{ 'dm-grid-engagement-' + index}}">
                                                        {{engagement.engagementName}}
                                                    </a>
                                                    <span *ngIf="engagement.isExternalLink">
                                                        {{engagement.engagementName}}
                                                    </span>
                                                    <span class="sr-only">press tab to navigate to link</span>
                                                    <dm-tooltip role="tooltip" class="dm-tooltipinline dm-m-r-4"
                                                        *ngIf="engagement.hasAssociatedEngagements"
                                                        [dmPlacement]="'bottom'"
                                                        [ngbTooltipContent]="'This Engagement has associated customer delivery engagements.'"
                                                        [dmIcon]="'tile__icon--info icon icon-link link-posig'"
                                                        ariaLabelToolTip="more Information">
                                                    </dm-tooltip>
                                                    <dm-tooltip role="tooltip"
                                                        class="dm-tooltipinline dm-m-r-4 confidentialicon"
                                                        *ngIf="engagement.isConfidential" [dmPlacement]="'bottom'"
                                                        [ngbTooltipContent]="'This engagement is marked as confidential.'"
                                                        [dmIcon]="'tile__icon--info dm-m-l-8 confidentialkey'"
                                                        ariaLabelToolTip="confidentiality information">
                                                    </dm-tooltip>
                                                    <button class="inward" [title]="engagement.delegationAllocationType"
                                                        *ngIf="engagement.delegationAllocationType === 'delegatedInward'"
                                                        (click)="openDelegationModal(engagement.delegationDetails, 'delegatedInward')">
                                                        <span class="delegated" aria-hidden="true">D</span>
                                                        <span>
                                                            <i title="Delegated to me"
                                                                class="icon icon-ResizeTouchSmaller icon-delegated"></i>
                                                            <span class="sr-only">
                                                                {{engagement.engagementName}} Project delegated to me
                                                            </span>
                                                        </span>
                                                    </button>
                                                    <button class="outward"
                                                        [title]="engagement.delegationAllocationType"
                                                        *ngIf="engagement.delegationAllocationType === 'delegatedOutward'"
                                                        (click)="openDelegationModal(engagement.delegationDetails, 'delegatedOutward')">
                                                        <span class="delegated" aria-hidden="true">D</span>
                                                        <span>
                                                            <i title="Delegated by me"
                                                                class="icon icon-ResizeTouchLarger icon-delegated"></i>
                                                            <span class="sr-only">
                                                                {{engagement.engagementName}} Project delegated to
                                                                others
                                                            </span>
                                                        </span>
                                                    </button>
                                                    <section class="dropdown pull-right" id="{{'dropdown-' + index}}">
                                                        <button type="button" class="set-focus"
                                                            [title]="setTitleOrAriaLabelForDropDown('Internal Engagement', engagement.engagementId)"
                                                            [attr.aria-label]="setTitleOrAriaLabelForDropDown('Internal Engagement', engagement.engagementId)"
                                                            (keydown.ArrowDown)="moveFocus(index , ' accessibilityConstants.Financials')"
                                                            (keydown.shift.tab)="closeDropdownOnTab('dropdown-' + index)"
                                                            (click)="logMoreOptionsClick()" data-toggle="dropdown">
                                                            <span class="icon-full icon-more-vertical"></span>
                                                        </button>
                                                        <span class="sr-only">press tab to navigate to dropdown
                                                            button</span>
                                                        <div
                                                            class="dropdown-menu dropdown-menu-right font-caption-alt dm-p-imp-0">
                                                            <a class="dropdown-item dm-p-imp-12 dm-link"
                                                                [uiSref]="RouteName.InternalEngagementFinancials"
                                                                [uiParams]="{engagementId:engagement.engagementId, eventName: LogEventName.PortfolioNavigationFromInternalEngagementsTile}"
                                                                (keydown.ArrowDown)="moveFocus(index, ' accessibilityConstants.Staffing')"
                                                                id="{{ 'dm-grid-engagement-options' + index + ' accessibilityConstants.Financials'}}">
                                                                Financials
                                                            </a>
                                                            <a class="dropdown-item dm-p-imp-12 dm-link"
                                                                [uiSref]="RouteName.InternalEngagementStaffing"
                                                                [uiParams]="{engagementId:engagement.engagementId, eventName: LogEventName.PortfolioNavigationFromInternalEngagementsTile}"
                                                                (keydown.ArrowUp)="moveFocus(index, ' accessibilityConstants.Financials')"
                                                                (keydown.Tab)="closeDropdownOnTab('dropdown-' + index)"
                                                                id="{{ 'dm-grid-engagement-options' + index + ' accessibilityConstants.Staffing'}}">
                                                                Staffing
                                                            </a>
                                                        </div>
                                                    </section>
                                                </b>
                                            </p>
                                            <p>
                                                EBS:
                                                <span>{{engagement.engagementId | dmDisplayDashIfEmptyOrNull}}</span>
                                            </p>
                                            <p *ngIf="engagement.isExternalLink">
                                                External Domain:
                                                <span>{{engagement.primaryDomain | dmDisplayDashIfEmptyOrNull}}</span>
                                            </p>
                                            <button class="dm-link dm-p-y-0" title="Financial Overview"
                                                [title]="setTitleOrAriaLabelForFinancialOverview(engagement.engagementId)"
                                                (click)="loadFinancialOverview(engagement.engagementId)">
                                                Financials Overview
                                            </button>
                                            <span class="sr-only">press tab to navigate to tooltip button</span>
                                        </div>
                                        <div role="gridcell"
                                            class="dm-grid-view-table__bodyCell text-left text-capitilize dm-p-x-12-y-8 ebs-state-pad">
                                            <span class="sr-only">EBS State</span>
                                            {{engagement.ebsState | dmDisplayDashIfEmptyOrNull}}
                                            <span *ngIf="!engagement.ebsState" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                        </div>
                                        <div role="gridcell"
                                            class="dm-grid-view-table__bodyCell text-left text-capitilize dm-p-x-12-y-8">
                                            <span class="sr-only">Customer</span>
                                            {{engagement.customerName | dmDisplayDashIfEmptyOrNull}}
                                            <span *ngIf="!engagement.customerName" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                        </div>
                                        <div role="gridcell"
                                            class="dm-grid-view-table__bodyCell text-left text-capitilize dm-p-x-12-y-8">
                                            <span class="sr-only">Start Date</span>
                                            {{engagement.startDate | dmDisplayDateOrDashPipe}}
                                            <span *ngIf="!engagement.startDate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                        </div>
                                        <div role="gridcell"
                                            class="dm-grid-view-table__bodyCell text-left text-capitilize dm-p-x-12-y-8">
                                            <span class="sr-only">End Date</span>
                                            {{engagement.endDate | dmDisplayDateOrDashPipe}}
                                            <span *ngIf="!engagement.endDate" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                        </div>
                                        <div role="gridcell"
                                            class="dm-grid-view-table__bodyCell text-left text-capitilize dm-p-x-12-y-8">
                                            <span class="sr-only">Type</span>
                                            <span>{{engagement.projectTypeCode | dmDisplayDashIfEmptyOrNull }}
                                                <span *ngIf="!engagement.projectTypeCode" class="sr-only">{{accessibilityConstants.ScreenReaderTextForEmptyElement}}</span>
                                            </span>
                                        </div>
                                        <div role="gridcell"
                                            class="dm-grid-view-table__bodyCell text-left text-capitilize dm-p-x-12-y-8">
                                            <span class="sr-only">Project Manager / T&E Approver</span>
                                            <dm-user-name [name]="engagement.pPjMName" [alias]="engagement.pPjMAlias">
                                            </dm-user-name>
                                        </div>
                                        <div role="gridcell" *ngIf="!hidePinnedColumn"
                                            class="dm-grid-view-table__bodyCell text-center text-capitilize dm-p-x-12-y-8">
                                            <span *ngIf="engagement.isPinned">
                                                <i class="icon icon-pin"></i>
                                                <span class="sr-only">
                                                    Engagement {{engagement.engagementName}} is pinned.
                                                </span>
                                            </span>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section class="col-md-12 dm-pagination">
                        <dm-pagination-v2 *ngIf="isPaginationRequired" [(pageSize)]="portfolioItemsDisp"
                            [totalItemCount]="engagementList.length" [currentPageNumber]="currentPage"
                            (pageChanged)="currentPageChangedHandler($event)"
                            (pageSizeChanged)="itemPerPageChangedHandler($event)" [itemsPerPage]="itemsPerPage">
                        </dm-pagination-v2>
                    </section>
                </section>
            </ng-container>
        </section>
    </div>
</div>